import { datadogRum } from '@datadog/browser-rum-slim';
import { CoreApplicationContext } from '@eventbrite/context-gen';
import { configureStore } from '@eventbrite/redux-configure-store';
import {
    addDimensions,
    middleware as analyticsMiddleware,
} from '@eventbrite/site-analytics';
import { getExperiment, logEvent } from '@eventbrite/statsig';
import React from 'react';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { isProd } from '../../utils/http';
import { HEAP_HOMEPAGE_VIEWED } from './constants/analytics';
import { HomeBaseContainer } from './containers/HomeBaseContainer';
import HomePageProviders from './HomePageProviders';
import { minimalInitialState } from './redux/index';
import { rootReducer } from './redux/root';

interface HomePageProps {
    app_version?: string;
    app_name: string;
    env: CoreApplicationContext['env'];
    user: { isAuthenticated: boolean };
}

// Temp add horizontal scrolling dimension to GA
// REMOVE after A/B testing
enum CAROUSEL_TYPE {
    'Control',
    'ScrollTopNav',
    'ScrollSideNav',
}

const horizontalScrollingVariant = getExperiment<number, number>(
    'horizontal_scrolling_buckets',
    'use_horizontal_scrolling',
    0,
);

addDimensions({
    experimentId: CAROUSEL_TYPE[horizontalScrollingVariant],
});
class HomePage extends React.Component<HomePageProps> {
    private _store: any;
    constructor(props: Readonly<HomePageProps>) {
        super(props);

        // creates a store w/ Redux logging defaulted in development environment
        // also adds `redux-thunk` middleware by default for async actions
        this._store = configureStore({
            reducer: rootReducer,
            initialState: minimalInitialState(props),
            middleware: [thunk, analyticsMiddleware(props)],
        });
    }

    componentDidMount() {
        if (isProd(this.props)) {
            datadogRum.onReady(() =>
                datadogRum.setGlobalContextProperty('view.sub_app', 'home'),
            );
        }

        logEvent(HEAP_HOMEPAGE_VIEWED);
    }

    render() {
        return (
            <Provider store={this._store}>
                <HomePageProviders {...this.props}>
                    <HomeBaseContainer {...this.props} />
                </HomePageProviders>
            </Provider>
        );
    }
}

export default HomePage;
