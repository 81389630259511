import { Icon } from '@eventbrite/eds-icon';
import {
    TicketChunky as TicketChunkySvg,
    UserChunky as UserChunkySvg,
} from '@eventbrite/eds-iconography';
import React from 'react';
import { FOR_YOU } from '../../../constants/tabConfig';

export const getIcon = (tab: string, type: string) => {
    if (tab === FOR_YOU && type === 'event') {
        return <Icon size="medium" type={<TicketChunkySvg />} />;
    }

    if (tab === FOR_YOU && type === 'profile') {
        return <Icon size="medium" type={<UserChunkySvg />} />;
    }
};
