import { gettext } from '@eventbrite/i18n';
import {
    fullbleedHeaderImageSet,
    // LIVE_EVENTS_ARE_BACK_US,
    // ONLINE_BANNER_URL,
    // RECONVENE,
    TLD_ARGENTINA,
    TLD_AUSTRALIA,
    TLD_BELGIQUE,
    TLD_BRASIL,
    TLD_CANADA,
    TLD_CHILE,
    TLD_COLOMBIA,
    TLD_DEUTSCHLAND,
    TLD_ESPANA,
    TLD_FRANCE,
    TLD_GLOBALLY,
    TLD_IRELAND,
    TLD_ITALIA,
    TLD_MEXICO,
    TLD_NEDERLAND,
    TLD_NEW_ZEALAND,
    TLD_OSTERREICH,
    TLD_PERU,
    TLD_PORTUGAL,
    TLD_SCHWEIZ,
    TLD_SVERIGE,
    TLD_UNITED_KINGDOM,
    TLD_UNITED_STATES,
} from './constants';

// const globalReconveneHeader = {
//     startDate: {
//         day: 3,
//         month: 5,
//         year: 2021,
//     },
//     endDate: {
//         day: 22,
//         month: 5,
//         year: 2021,
//     },
//     collectionLink: '/reconvene/?aff=homepage2',
//     headerImages: {
//         mobile: RECONVENE,
//         tablet: RECONVENE,
//         desktop: RECONVENE,
//         twok: RECONVENE,
//     },
//     linkLabel: gettext('Join the conversation'),
//     // no gettext since shown in English-speaking TLDs only
//     combinedHeaderTitleAndCta:
//         '<div class="feed-header__title-top">Help shape the</div><div class="feed-header__title-bottom feed-header__title-bottom--seasonal">future of events</div>',
// };

const LIVE_EVENTS_ARE_BACK_HEADER = {
    startDate: {
        day: 3,
        month: 5,
        year: 2021,
    },
    endDate: {
        day: 22,
        month: 12,
        year: 2021,
    },
    collectionLink: '/d',
    // Uncomment below and delete duplicate
    // for https://eventbrite.atlassian.net/browse/EB-206099
    headerImages: fullbleedHeaderImageSet,
    // headerImages: {
    //     mobile: LIVE_EVENTS_ARE_BACK_US,
    //     tablet: LIVE_EVENTS_ARE_BACK_US,
    //     desktop: LIVE_EVENTS_ARE_BACK_US,
    //     twok: LIVE_EVENTS_ARE_BACK_US,
    // },
    linkLabel: gettext('Browse events'),
    // no gettext since shown in English-speaking TLDs only
    combinedHeaderTitleAndCta:
        '<div class="feed-header__title-top">Live events are back</div><div class="feed-header__title-bottom feed-header__title-bottom--seasonal">Make plans</div>',
};

// old teext Find your next event
export const fullbleedHeaderLinkLabel = gettext('Find your next event');

const FULLBLEED_HEADER = {
    forceSpecialStyles: true,
    startDate: {
        day: 3,
        month: 5,
        year: 2021,
    },
    endDate: {
        day: 22,
        month: 12,
        year: 2030,
    },
    collectionLink: '/d',
    headerImages: fullbleedHeaderImageSet,
    headerImagePlaceholder: {
        color: '#89A2BE',
        svg: '', // Leaving this here for future implementation
    },
    linkLabel: fullbleedHeaderLinkLabel,
    // no gettext since shown in English-speaking TLDs only
    combinedHeaderTitleAndCta:
        '<div class="feed-fullbleed-header__title-top">NOW</div><div class="feed-fullbleed-header__title-bottom feed-fullbleed-header__title-bottom--seasonal">IS YOUR TIME.</div>',
};

export const FULLBLEED_HEADER_FALLBACK = {
    shouldShowCustomContent: true,
    forceSpecialStyles: true,
    startDate: {
        day: 3,
        month: 5,
        year: 2021,
    },
    endDate: {
        day: 22,
        month: 12,
        year: 2030,
    },
    customContentCtaLink: '/d/online/events',
    images: fullbleedHeaderImageSet,
    headerImagePlaceholder: {
        color: '#89A2BE',
        svg: '', // Leaving this here for future implementation
    },
    customLinkLabel: fullbleedHeaderLinkLabel,
    // no gettext since shown in English-speaking TLDs only
    combinedHeaderTitleAndCta:
        '<div class="feed-fullbleed-header__title-top">NOW</div><div class="feed-fullbleed-header__title-bottom feed-fullbleed-header__title-bottom--seasonal">IS YOUR TIME.</div>',
};

const DEFAULT_ONLINE_TAB = {
    tldsExcluded: [],
    combinedHeaderTitleAndCta: gettext(
        '<div class="feed-header__title-top">Connect through</div><div class="feed-header__title-bottom feed-header__title-bottom--seasonal">online events</div>',
    ),
    linkLabel: gettext('Browse events'),
    // Uncomment below and delete duplicate
    // for https://eventbrite.atlassian.net/browse/EB-206099
    // headerImages: fullbleedHeaderImageSet,
    // headerImages: {
    //     mobile: ONLINE_BANNER_URL,
    //     tablet: ONLINE_BANNER_URL,
    //     desktop: ONLINE_BANNER_URL,
    //     twok: ONLINE_BANNER_URL,
    // },
    collectionLink: '/d/online/events',
    headerImages: fullbleedHeaderImageSet,
    shouldShowCustomHeader: false,
    alwaysShow: false,
};

export const SEASONAL_CONTENT: any = {
    default: {
        coronavirus: {
            startDate: {
                day: 18,
                month: 3,
                year: 2020,
            },
            endDate: {
                day: 18,
                month: 3,
                year: 2220,
            },
            key: 'coronavirus',
            tlds: [TLD_GLOBALLY],
            tldsExcluded: [
                TLD_FRANCE,
                TLD_ESPANA,
                TLD_ITALIA,
                TLD_PORTUGAL,
                TLD_DEUTSCHLAND,
                TLD_NEDERLAND,
                TLD_BRASIL,
                TLD_ARGENTINA,
                TLD_OSTERREICH,
                TLD_BELGIQUE,
                TLD_COLOMBIA,
                TLD_CHILE,
                TLD_MEXICO,
                TLD_PERU,
                TLD_SCHWEIZ,
                TLD_SVERIGE,
            ],
            combinedHeaderTitleAndCta: gettext(
                '<div class="feed-header__title-top">Connect through</div><div class="feed-header__title-bottom feed-header__title-bottom--seasonal">online events</div>',
            ),
            linkLabel: gettext('Browse events'),
            // Uncomment below and delete duplicate
            // for https://eventbrite.atlassian.net/browse/EB-206099
            // headerImages: {
            //     mobile: ONLINE_BANNER_URL,
            //     tablet: ONLINE_BANNER_URL,
            //     desktop: ONLINE_BANNER_URL,
            //     twok: ONLINE_BANNER_URL,
            // },
            collectionLink: '/d/online/events',
            headerImages: fullbleedHeaderImageSet,
            shouldShowCustomHeader: true,
            alwaysShow: true,
            customHeaderPriority: 0,
            // NB: Location-specific overrides take precedent over tldOverrides
            tldOverrides: {
                // Uncomment below and delete duplicate
                // for https://eventbrite.atlassian.net/browse/EB-206099
                // [TLD_CANADA]: globalReconveneHeader,
                // [TLD_NEW_ZEALAND]: globalReconveneHeader,
                // [TLD_AUSTRALIA]: globalReconveneHeader,
                // [TLD_UNITED_KINGDOM]: FULLBLEED_HEADER,
                // [TLD_IRELAND]: globalReconveneHeader,
                // [TLD_UNITED_STATES]: FULLBLEED_HEADER,
                [TLD_CANADA]: FULLBLEED_HEADER,
                [TLD_NEW_ZEALAND]: FULLBLEED_HEADER,
                [TLD_AUSTRALIA]: FULLBLEED_HEADER,
                [TLD_UNITED_KINGDOM]: FULLBLEED_HEADER,
                [TLD_IRELAND]: FULLBLEED_HEADER,
                [TLD_UNITED_STATES]: FULLBLEED_HEADER,
            },
        },
        // TODO: Document this part better
        boxingDay: {
            key: 'boxingDay',
            tabName: 'Boxing Day',
            eventSearchQ: gettext('Boxing Day').toString(),
            startDate: {
                day: 1,
                month: 12,
            },
            endDate: {
                day: 28,
                month: 12,
            },
            tlds: [TLD_CANADA],
            ...DEFAULT_ONLINE_TAB,
        },
        canadaDay: {
            key: 'canadaDay',
            tabName: 'Canada Day',
            eventSearchQ: gettext('Canada Day').toString(),
            startDate: {
                day: 1,
                month: 6,
            },
            endDate: {
                day: 2,
                month: 7,
            },
            tlds: [TLD_CANADA],
            ...DEFAULT_ONLINE_TAB,
        },
        fourthOfJuly: {
            key: 'fourthOfJuly',
            tabName: '4th of July',
            eventSearchQ: gettext(
                '4 OR 4th of July OR Independence Day OR fireworks OR fourth',
            ).toString(),
            startDate: {
                day: 21,
                month: 6,
            },
            endDate: {
                day: 5,
                month: 7,
            },
            tlds: [TLD_UNITED_STATES],
            ...DEFAULT_ONLINE_TAB,
        },
        juneteenth: {
            key: 'juneteenth',
            tabName: 'Juneteenth',
            eventSearchQ: gettext(
                'Juneteenth OR black lives matter',
            ).toString(),
            startDate: {
                day: 1,
                month: 6,
            },
            endDate: {
                day: 20,
                month: 6,
            },
            tlds: [TLD_UNITED_STATES],
            ...DEFAULT_ONLINE_TAB,
        },
        pride: {
            key: 'pride',
            tabName: 'Pride',
            eventSearchQ: gettext(
                'Pride OR queer OR LGBTQIA parade LGBT OR gay',
            ).toString(),
            startDate: {
                day: 1,
                month: 6,
            },
            endDate: {
                day: 1,
                month: 7,
            },
            tlds: [TLD_UNITED_STATES],
            ...DEFAULT_ONLINE_TAB,
        },
        fathersDay: {
            key: 'fathersDay',
            tabName: "Father's Day",
            eventSearchQ: gettext(
                "Father's Day OR fathers OR Dad OR dad's OR father",
            ).toString(),
            startDate: {
                day: 1,
                month: 6,
                year: 2021,
            },
            endDate: {
                day: 21,
                month: 6,
                year: 2021,
            },
            tlds: [
                TLD_UNITED_KINGDOM,
                TLD_IRELAND,
                TLD_UNITED_STATES,
                TLD_CANADA,
            ],
            ...DEFAULT_ONLINE_TAB,
        },
        mindfulMay: {
            key: 'mindfulMay',
            tabName: 'Mindful in May',
            eventSearchQ: gettext(
                'mindfulness OR meditation OR mindful in may wellness',
            ).toString(),
            startDate: {
                day: 1,
                month: 5,
            },
            endDate: {
                day: 1,
                month: 6,
            },
            tlds: [TLD_NEW_ZEALAND, TLD_AUSTRALIA],
            ...DEFAULT_ONLINE_TAB,
        },
        memorialDay: {
            key: 'memorialDay',
            tabName: 'Memorial Day',
            eventSearchQ: gettext('memorial day').toString(),
            startDate: {
                day: 10,
                month: 5,
            },
            endDate: {
                day: 1,
                month: 6,
            },
            tlds: [TLD_UNITED_STATES],
            ...DEFAULT_ONLINE_TAB,
        },
        mentalHealthAwarness: {
            key: 'mentalHealthAwarness',
            tabName: 'Mental Health Awareness Month',
            eventSearchQ: gettext(
                'mental health awareness month OR mindfulness OR meditation OR wellness',
            ).toString(),
            startDate: {
                day: 1,
                month: 5,
            },
            endDate: {
                day: 1,
                month: 6,
            },
            tlds: [TLD_UNITED_STATES],
            ...DEFAULT_ONLINE_TAB,
        },
        earthDay: {
            key: 'earthDay',
            tabName: 'Earth Day',
            eventSearchQ: gettext('Earth Day').toString(),
            startDate: {
                day: 1,
                month: 4,
            },
            endDate: {
                day: 23,
                month: 4,
            },
            tlds: [
                TLD_NEW_ZEALAND,
                TLD_AUSTRALIA,
                TLD_UNITED_KINGDOM,
                TLD_IRELAND,
                TLD_UNITED_STATES,
                TLD_CANADA,
            ],
            ...DEFAULT_ONLINE_TAB,
        },
        anzacDay: {
            key: 'anzacDay ',
            tabName: 'Anzac Day',
            eventSearchQ: gettext('Anzac Day').toString(),
            startDate: {
                day: 12,
                month: 4,
            },
            endDate: {
                day: 16,
                month: 4,
            },
            tlds: [TLD_NEW_ZEALAND, TLD_AUSTRALIA, TLD_UNITED_KINGDOM],
            ...DEFAULT_ONLINE_TAB,
        },
        waitangiDay: {
            key: 'waitangiDay ',
            tabName: 'Waitangi Day',
            eventSearchQ: gettext('Waitangi Day').toString(),
            startDate: {
                day: 18,
                month: 1,
            },
            endDate: {
                day: 7,
                month: 2,
            },
            tlds: [TLD_NEW_ZEALAND],
            ...DEFAULT_ONLINE_TAB,
        },
        stPatricksDay: {
            key: 'stPatricksDay ',
            tabName: "St Patrick's Day",
            eventSearchQ: gettext(
                "St Patrick's Day OR Patrick OR Paddy OR Patty",
            ).toString(),
            startDate: {
                day: 1,
                month: 3,
            },
            endDate: {
                day: 18,
                month: 3,
            },
            tlds: [
                TLD_UNITED_STATES,
                TLD_CANADA,
                TLD_UNITED_KINGDOM,
                TLD_IRELAND,
            ],
            ...DEFAULT_ONLINE_TAB,
        },
        australiaDay: {
            key: 'australiaDay ',
            tabName: 'Australia Day',
            eventSearchQ: gettext('Australia day').toString(),
            startDate: {
                day: 11,
                month: 1,
            },
            endDate: {
                day: 27,
                month: 1,
            },
            tlds: [TLD_AUSTRALIA],
            ...DEFAULT_ONLINE_TAB,
        },
        easterUK: {
            key: 'easterUK',
            tabName: 'Easter',
            eventSearchQ: gettext(
                'Easter OR good friday OR Pan Celtic Festival',
            ).toString(),
            startDate: {
                day: 29,
                month: 3,
                year: 2021,
            },
            endDate: {
                day: 6,
                month: 4,
                year: 2021,
            },
            tlds: [
                TLD_AUSTRALIA,
                TLD_NEW_ZEALAND,
                TLD_UNITED_KINGDOM,
                TLD_IRELAND,
            ],
            ...DEFAULT_ONLINE_TAB,
        },
        chineseNewYearAU: {
            key: 'chineseNewYear ',
            tabName: 'Chinese new year',
            eventSearchQ: gettext(
                'Chinese new year OR lunar new year',
            ).toString(),
            startDate: {
                day: 1,
                month: 2,
            },
            endDate: {
                day: 13,
                month: 2,
            },
            tlds: [TLD_AUSTRALIA, TLD_NEW_ZEALAND],
            ...DEFAULT_ONLINE_TAB,
        },
        chineseNewYearUS: {
            key: 'chineseNewYear ',
            tabName: 'Chinese new year',
            eventSearchQ: gettext(
                'Chinese new year OR lunar new year',
            ).toString(),
            startDate: {
                day: 20,
                month: 1,
            },
            endDate: {
                day: 13,
                month: 2,
            },
            tlds: [
                TLD_UNITED_STATES,
                TLD_CANADA,
                TLD_UNITED_KINGDOM,
                TLD_IRELAND,
            ],
            ...DEFAULT_ONLINE_TAB,
        },
        valentinesDay: {
            key: 'valentinesDay ',
            tabName: "Valentine's Day",
            eventSearchQ: gettext("valentine's day OR valentine").toString(),
            startDate: {
                day: 23,
                month: 1,
            },
            endDate: {
                day: 15,
                month: 2,
            },
            tlds: [
                TLD_UNITED_STATES,
                TLD_CANADA,
                TLD_AUSTRALIA,
                TLD_NEW_ZEALAND,
                TLD_UNITED_KINGDOM,
                TLD_IRELAND,
            ],
            ...DEFAULT_ONLINE_TAB,
        },
        sportUK: {
            key: 'SportUK',
            tabName: 'Sports',
            eventSearchQ: gettext(
                'Sport OR GBP OR sport league OR run OR walk OR swim OR cycle OR tennis OR rugby OR football OR netball',
            ).toString(),
            startDate: {
                day: 1,
                month: 1,
            },
            endDate: {
                day: 1,
                month: 4,
            },
            tlds: [
                TLD_AUSTRALIA,
                TLD_NEW_ZEALAND,
                TLD_UNITED_KINGDOM,
                TLD_IRELAND,
            ],
            ...DEFAULT_ONLINE_TAB,
            price: 'paid',
            currencies: ['GBP'],
        },
        womensDayUK: {
            key: 'womensDayUK',
            tabName: "Women's Day",
            eventSearchQ: gettext(
                "Women's day OR International Women's day OR women's month",
            ).toString(),
            startDate: {
                day: 25,
                month: 2,
            },
            endDate: {
                day: 9,
                month: 3,
            },
            tlds: [
                TLD_AUSTRALIA,
                TLD_NEW_ZEALAND,
                TLD_UNITED_KINGDOM,
                TLD_IRELAND,
            ],
            ...DEFAULT_ONLINE_TAB,
        },
        womensDayUS: {
            key: 'womensDayUS',
            tabName: "International Women's Day",
            eventSearchQ: gettext(
                "Women's day OR International Women's day OR women's month",
            ).toString(),
            startDate: {
                day: 1,
                month: 3,
            },
            endDate: {
                day: 9,
                month: 3,
            },
            tlds: [TLD_UNITED_STATES, TLD_CANADA],
            ...DEFAULT_ONLINE_TAB,
        },
        womensHistoryMonth: {
            key: 'womensHistoryMonth',
            tabName: "Women's History Month",
            eventSearchQ: gettext(
                "women's month OR women's history OR women's day",
            ).toString(),
            startDate: {
                day: 1,
                month: 3,
            },
            endDate: {
                day: 1,
                month: 4,
            },
            tlds: [TLD_UNITED_STATES],
            ...DEFAULT_ONLINE_TAB,
        },
        womensMarch: {
            key: 'womensMarch',
            tabName: "Women's march",
            eventSearchQ: gettext("Women's march").toString(),
            startDate: {
                day: 1,
                month: 1,
                year: 2021,
            },
            endDate: {
                day: 1,
                month: 2,
                year: 2021,
            },
            tlds: [TLD_UNITED_STATES],
            ...DEFAULT_ONLINE_TAB,
        },
        halloween: {
            key: 'halloween',
            tabName: 'Halloween',
            eventSearchQ: gettext(
                "Halloween OR day of the dead OR All hollow's eve OR trick or treat",
            ).toString(),
            startDate: {
                day: 1,
                month: 10,
            },
            endDate: {
                day: 1,
                month: 11,
            },
            tlds: [
                TLD_UNITED_STATES,
                TLD_AUSTRALIA,
                TLD_UNITED_KINGDOM,
                TLD_IRELAND,
                TLD_CANADA,
                TLD_NEW_ZEALAND,
            ],
            ...DEFAULT_ONLINE_TAB,
        },
        breastCancer: {
            key: 'breastCancer',
            tabName: 'Breast Cancer Awareness Month',
            eventSearchQ: gettext('Breast Cancer awareness').toString(),
            startDate: {
                day: 1,
                month: 10,
            },
            endDate: {
                day: 1,
                month: 11,
            },
            tlds: [TLD_UNITED_STATES],
            ...DEFAULT_ONLINE_TAB,
        },
        holidayEvents: {
            key: 'holidayEvents',
            tabName: 'Holiday events',
            eventSearchQ: gettext(
                'holiday OR christmas OR hannukah OR channukah OR light show OR kwanza OR three kings day OR Epiphany OR festivus OR bodhi day OR gift guide gifting OR xmas OR winter holiday OR holiday season OR holidays OR holiday events',
            ).toString(),
            startDate: {
                day: 29,
                month: 11,
                year: 2021,
            },
            endDate: {
                day: 7,
                month: 1,
                year: 2022,
            },
            tlds: [
                TLD_UNITED_STATES,
                TLD_AUSTRALIA,
                TLD_NEW_ZEALAND,
                TLD_CANADA,
                TLD_IRELAND,
                TLD_UNITED_KINGDOM,
            ],
            ...DEFAULT_ONLINE_TAB,
        },
        holidayEventsUK: {
            key: 'holidayEventsUK',
            tabName: 'Christmas & Festivities',
            eventSearchQ: gettext(
                'wreath making OR christmas market OR santa grotto OR light festival OR night market',
            ).toString(),
            startDate: {
                day: 1,
                month: 12,
                year: 2020,
            },
            endDate: {
                day: 5,
                month: 1,
                year: 2021,
            },
            tlds: [TLD_UNITED_KINGDOM],
            ...DEFAULT_ONLINE_TAB,
        },
        holidayEventsIE: {
            key: 'holidayEventsIE',
            tabName: 'Christmas & Festivities',
            eventSearchQ: gettext(
                'christmas market OR santa grotto OR wreath making',
            ).toString(),
            startDate: {
                day: 1,
                month: 12,
                year: 2020,
            },
            endDate: {
                day: 5,
                month: 1,
                year: 2021,
            },
            tlds: [TLD_IRELAND],
            ...DEFAULT_ONLINE_TAB,
        },
        veteransDayUS: {
            key: 'veteransDayUS',
            tabName: "Veteran's Day",
            eventSearchQ: gettext("Veteran's Day"),
            startDate: {
                day: 20,
                month: 10,
            },
            endDate: {
                day: 12,
                month: 11,
            },
            tlds: [TLD_UNITED_STATES],
            ...DEFAULT_ONLINE_TAB,
        },
        melbourneCup: {
            key: 'melbourneCup',
            tabName: 'Melbourne Cup',
            eventSearchQ: gettext('Melbourne cup'),
            startDate: {
                day: 23,
                month: 10,
            },
            endDate: {
                day: 5,
                month: 11,
            },
            tlds: [TLD_AUSTRALIA],
            ...DEFAULT_ONLINE_TAB,
        },
        blackHistoryUSCA: {
            key: 'blackHistoryUSCA',
            tabName: 'Black History Month',
            eventSearchQ: gettext('black history month'),
            startDate: {
                day: 1,
                month: 2,
            },
            endDate: {
                day: 1,
                month: 3,
            },
            tlds: [TLD_UNITED_STATES, TLD_CANADA],
            ...DEFAULT_ONLINE_TAB,
        },
        blackHistory: {
            key: 'blackHistoryUK',
            tabName: 'Black History Month',
            eventSearchQ: gettext('black history culture month'),
            startDate: {
                day: 1,
                month: 10,
            },
            endDate: {
                day: 1,
                month: 11,
            },
            tlds: [TLD_UNITED_KINGDOM, TLD_IRELAND],
            ...DEFAULT_ONLINE_TAB,
        },
        diwaliUK: {
            key: 'diwaliUK',
            tabName: 'Diwali',
            eventSearchQ: gettext('Diwali'),
            startDate: {
                day: 5,
                month: 10,
                year: 2021,
            },
            endDate: {
                day: 6,
                month: 11,
                year: 2021,
            },
            tlds: [TLD_UNITED_KINGDOM, TLD_IRELAND],
            ...DEFAULT_ONLINE_TAB,
        },
        thanksgiving: {
            key: 'thanksgiving',
            tabName: 'Thanksgiving',
            eventSearchQ: gettext(
                'Thankgiving OR turkey OR volunteer OR turkey day OR turkey trot',
            ).toString(),
            startDate: {
                day: 8,
                month: 11,
                year: 2021,
            },
            endDate: {
                day: 29,
                month: 11,
                year: 2021,
            },
            tlds: [TLD_UNITED_STATES],
            ...DEFAULT_ONLINE_TAB,
        },
        NYE: {
            key: 'NYE',
            tabName: gettext("New Year's"),
            eventSearchQ: gettext(
                "NYE OR New Year's Eve OR New Year OR New Year's OR New Year's Day",
            ).toString(),
            startDate: {
                day: 1,
                month: 12,
                year: 2021,
            },
            endDate: {
                day: 3,
                month: 1,
                year: 2022,
            },
            tlds: [
                TLD_UNITED_STATES,
                TLD_UNITED_KINGDOM,
                TLD_CANADA,
                TLD_IRELAND,
                TLD_AUSTRALIA,
                TLD_NEW_ZEALAND,
            ],
            tldsExcluded: [],
            combinedHeaderTitleAndCta: gettext(
                '<div class="feed-header__title-top">Connect through</div><div class="feed-header__title-bottom feed-header__title-bottom--seasonal">online events</div>',
            ),
            linkLabel: gettext('Browse events'),
            // Uncomment below and delete duplicate
            // for https://eventbrite.atlassian.net/browse/EB-206099
            headerImages: fullbleedHeaderImageSet,
            // headerImages: {
            //     mobile: ONLINE_BANNER_URL,
            //     tablet: ONLINE_BANNER_URL,
            //     desktop: ONLINE_BANNER_URL,
            //     twok: ONLINE_BANNER_URL,
            // },
            collectionLink: '/d/online/events/',
            shouldShowCustomHeader: true,
            alwaysShow: false,
        },
        mothersDayUK: {
            key: 'mothersDayUK',
            tabName: gettext("Mother's Day"),
            eventSearchQ: gettext("Mother's Day").toString(),
            onlineOnly: false,
            startDate: {
                day: 1,
                month: 3,
            },
            endDate: {
                day: 15,
                month: 3,
            },
            tlds: [TLD_UNITED_KINGDOM, TLD_IRELAND],
            ...DEFAULT_ONLINE_TAB,
        },
        mothersDayUS: {
            key: 'mothersDayUS',
            tabName: "Mother's Day",
            eventSearchQ: gettext(
                "Mother's Day OR mothers day OR mom OR mother OR mum",
            ).toString(),
            onlineOnly: false,
            startDate: {
                day: 2,
                month: 5,
                year: 2022,
            },
            endDate: {
                day: 8,
                month: 5,
                year: 2022,
            },
            tlds: [
                TLD_AUSTRALIA,
                TLD_NEW_ZEALAND,
                TLD_UNITED_STATES,
                TLD_CANADA,
            ],
            ...DEFAULT_ONLINE_TAB,
        },
        laborDay: {
            key: 'laborDay',
            tabName: gettext('Labor day'),
            eventSearchQ: gettext('labor day').toString(),
            onlineOnly: false,
            startDate: {
                day: 10,
                month: 8,
                year: 2021,
            },
            endDate: {
                day: 7,
                month: 9,
                year: 2021,
            },
            tlds: [TLD_UNITED_STATES],
            ...DEFAULT_ONLINE_TAB,
        },
        naidocWeek: {
            key: 'naidocWeek',
            tabName: gettext('Naidoc Week'),
            eventSearchQ: gettext(
                'Naidoc Week OR indigenous OR aboriginal',
            ).toString(),
            onlineOnly: false,
            startDate: {
                day: 1,
                month: 7,
                year: 2021,
            },
            endDate: {
                day: 12,
                month: 7,
                year: 2021,
            },
            tlds: [TLD_AUSTRALIA],
            ...DEFAULT_ONLINE_TAB,
        },
        eidAlAdha: {
            key: 'eidAlAdha',
            tabName: gettext('Eid Al Adha'),
            eventSearchQ: gettext('Eid Al Adha eid muslim').toString(),
            onlineOnly: false,
            startDate: {
                day: 19,
                month: 7,
                year: 2021,
            },
            endDate: {
                day: 24,
                month: 7,
                year: 2021,
            },
            tlds: [TLD_UNITED_KINGDOM],
            ...DEFAULT_ONLINE_TAB,
        },
    },
    holidaySeason: {
        tldOverrides: {
            [TLD_AUSTRALIA]: fullbleedHeaderImageSet,
            [TLD_UNITED_KINGDOM]: fullbleedHeaderImageSet,
            [TLD_UNITED_STATES]: fullbleedHeaderImageSet,
        },
        collectionLink:
            'b/local/holiday/halloweenhaunt/?utm_source=halloween_banner_click',
    },
    // NB: Location-specific settings here override tldOverrides
    // New York
    85977539: {
        mothersDay: LIVE_EVENTS_ARE_BACK_HEADER,
        pride: LIVE_EVENTS_ARE_BACK_HEADER,
        fathersDay: LIVE_EVENTS_ARE_BACK_HEADER,
        julyFourth: LIVE_EVENTS_ARE_BACK_HEADER,
        laborDay: LIVE_EVENTS_ARE_BACK_HEADER,
        halloween: LIVE_EVENTS_ARE_BACK_HEADER,
    },
    // San Francisco
    85922583: {
        mothersDay: LIVE_EVENTS_ARE_BACK_HEADER,
        pride: LIVE_EVENTS_ARE_BACK_HEADER,
        fathersDay: LIVE_EVENTS_ARE_BACK_HEADER,
        julyFourth: LIVE_EVENTS_ARE_BACK_HEADER,
        laborDay: LIVE_EVENTS_ARE_BACK_HEADER,
        halloween: LIVE_EVENTS_ARE_BACK_HEADER,
    },
    // Los Angeles
    85923517: {
        mothersDay: LIVE_EVENTS_ARE_BACK_HEADER,
        pride: LIVE_EVENTS_ARE_BACK_HEADER,
        fathersDay: LIVE_EVENTS_ARE_BACK_HEADER,
        julyFourth: LIVE_EVENTS_ARE_BACK_HEADER,
        laborDay: LIVE_EVENTS_ARE_BACK_HEADER,
        halloween: LIVE_EVENTS_ARE_BACK_HEADER,
    },
    // Washington DC
    85931779: {
        mothersDay: LIVE_EVENTS_ARE_BACK_HEADER,
        pride: LIVE_EVENTS_ARE_BACK_HEADER,
        fathersDay: LIVE_EVENTS_ARE_BACK_HEADER,
        julyFourth: LIVE_EVENTS_ARE_BACK_HEADER,
        laborDay: LIVE_EVENTS_ARE_BACK_HEADER,
        halloween: LIVE_EVENTS_ARE_BACK_HEADER,
    },
    // Chicago
    85940195: {
        mothersDay: LIVE_EVENTS_ARE_BACK_HEADER,
        pride: LIVE_EVENTS_ARE_BACK_HEADER,
        fathersDay: LIVE_EVENTS_ARE_BACK_HEADER,
        julyFourth: LIVE_EVENTS_ARE_BACK_HEADER,
        laborDay: LIVE_EVENTS_ARE_BACK_HEADER,
        halloween: LIVE_EVENTS_ARE_BACK_HEADER,
    },
    // Philadelphia
    101718083: {
        mothersDay: LIVE_EVENTS_ARE_BACK_HEADER,
        pride: LIVE_EVENTS_ARE_BACK_HEADER,
        fathersDay: LIVE_EVENTS_ARE_BACK_HEADER,
        julyFourth: LIVE_EVENTS_ARE_BACK_HEADER,
        laborDay: LIVE_EVENTS_ARE_BACK_HEADER,
        halloween: LIVE_EVENTS_ARE_BACK_HEADER,
    },
    // Atlanta
    85936429: {
        mothersDay: LIVE_EVENTS_ARE_BACK_HEADER,
        pride: LIVE_EVENTS_ARE_BACK_HEADER,
        fathersDay: LIVE_EVENTS_ARE_BACK_HEADER,
        julyFourth: LIVE_EVENTS_ARE_BACK_HEADER,
        laborDay: LIVE_EVENTS_ARE_BACK_HEADER,
        halloween: LIVE_EVENTS_ARE_BACK_HEADER,
    },
};
