import { Order } from '../types';
import { getLocalizedTime, getTimeUntilStart } from './dates';

/**
 * Given an array of attendee orders, determine if the next upcoming order
 * is within a provided range.
 *
 * An order is considered within the range if the event's start time is within
 * the provided time range, and the event is currently live.
 *
 * @param {array} orders An array of the current logged in user's orders
 * @param {number} timeRange A number representing number of hours ahead of an order we want to show a banner to their upcoming event
 *
 * @return {array} Array of orders that match the passed in timeRange sorted by event start time
 */
export const getOrdersInRange = (orders?: Order[], timeRangeInHours = 24) => {
    if (!orders || orders.length === 0) {
        return [];
    }

    const ordersInRange = orders.filter((order: any) => {
        const { utc } = order?.event?.start ?? {};

        if (utc) {
            const timeRangeInMilliseconds = timeRangeInHours * 60 * 60 * 1000;
            const localizedStartDate = getLocalizedTime(utc);

            //Check if event has been going for longer than 3 days
            //If it has been greater than 72 hours since event start
            //don't show notification.
            const timeUntilStart = getTimeUntilStart(
                localizedStartDate,
                'hours',
            );

            if (timeUntilStart < -72) {
                return false;
            }

            //Check if event is within time range to display
            const timeRangeFromNow = new Date(
                Date.now() + timeRangeInMilliseconds,
            );

            return localizedStartDate.valueOf() <= timeRangeFromNow.valueOf();
        }

        return false;
    });

    const sortedOrdersInRange = ordersInRange.sort(
        (orderA: any, orderB: any) => {
            const { utc: dateA } = orderA.event.start;
            const { utc: dateB } = orderB.event.start;

            const localizedDateA = getLocalizedTime(dateA);
            const locasedDateB = getLocalizedTime(dateB);

            return (
                Date.parse(localizedDateA).valueOf() -
                Date.parse(locasedDateB).valueOf()
            );
        },
    );

    return sortedOrdersInRange.length > 0 ? sortedOrdersInRange : [];
};

export const getLinkUrl = (
    orderId: string,
    event: {
        has_digital_content: {
            has_digital_content: boolean;
            digital_content_relative_url: string;
        };
    },
) => {
    const hasDigitalContent = event?.has_digital_content?.has_digital_content;
    const digitalContentRelativeUrl =
        event?.has_digital_content?.digital_content_relative_url;

    if (hasDigitalContent && digitalContentRelativeUrl) {
        return digitalContentRelativeUrl;
    }

    return `/mytickets/${orderId}`;
};

export const getEventInfoFromOrder = (order: any = {}) => {
    const event = order.event;

    if (!event) {
        return {};
    }

    const {
        id,
        start: { utc: start },
    } = event;

    const localizedStartTime = getLocalizedTime(start);
    const formattedLocalStart = localizedStartTime
        ? localizedStartTime.format()
        : '';

    return {
        hasDigitalContent: event?.has_digital_content?.has_digital_content,
        ctaLinkUrl: getLinkUrl(order.id, event),
        start: formattedLocalStart,
        eventName: event.name.text,
        eventId: id,
    };
};
