import { GenericLazyString } from '@eventbrite/i18n';
import { Typography } from '@eventbrite/marmalade';
import React, { useEffect, useState } from 'react';
import styles from './SegmentedCarousel.module.scss';

export const calculateTopOffset = () => {
    let isMobile = false;
    if (typeof window !== 'undefined') {
        isMobile = window?.innerWidth <= 480;
    }
    return isMobile;
};

export interface ToggleChipProps {
    id: string;
    label: GenericLazyString;
    isSelected?: boolean;
    trackingLabel?: string;
}

export interface SegmentedCarouselChipsProps {
    header: string | JSX.Element;
    toggleChips: ToggleChipProps[];
    onSelected: Function;
}

export const SegmentedCarouselChips: React.FC<SegmentedCarouselChipsProps> = ({
    header,
    toggleChips,
    onSelected,
}: SegmentedCarouselChipsProps): JSX.Element => {
    const isMobile = calculateTopOffset();
    const [chips, setChips] = useState<ToggleChipProps[]>([]);

    useEffect(() => {
        if (toggleChips.length && !chips.length) {
            setChips(toggleChips);
        }
    }, [toggleChips, chips, setChips]);

    const handleSelected = (selectedChip: ToggleChipProps) => {
        setChips(
            chips.map((chip) => ({
                ...chip,
                isSelected: selectedChip.id === chip.id,
            })),
        );
        onSelected(selectedChip);
    };
    return (
        <div className={styles.toggleChipsRootWrapper}>
            <div className={styles.toggleChipsHeader}>{header}</div>

            <div className={styles.toggleChipsWrapper}>
                {chips.map((chip, index) => (
                    <button
                        role="button"
                        aria-label={`View ${chip.label}`}
                        key={chip.id}
                        className={
                            chip.isSelected
                                ? styles.toggleChipSelected
                                : styles.toggleChip
                        }
                        onClick={() => handleSelected(chip)}
                        style={
                            isMobile
                                ? {
                                      flexShrink: 0,
                                      marginRight:
                                          index === chips.length
                                              ? 40
                                              : undefined,
                                  }
                                : undefined
                        }
                    >
                        <Typography variant="body-md-bold">
                            {chip.label}
                        </Typography>
                    </button>
                ))}
            </div>
        </div>
    );
};
