import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { ConfigureStore } from './types';

const configureStore: ConfigureStore = ({
    reducer,
    initialState,
    middleware = [thunk],
}) => {
    const composedMiddleware = applyMiddleware(...middleware);

    return initialState
        ? createStore(reducer, initialState, composedMiddleware)
        : createStore(reducer, composedMiddleware);
};

export default configureStore;
