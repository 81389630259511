// TODO: Remove this component https://eventbrite.atlassian.net/browse/EB-228488
import { HAS_WINDOW } from '@eventbrite/feature-detection';
import classNames from 'classnames';
import React, { Component } from 'react';
import { DateFilter } from '../../../constants/dates';

interface WithStickyBehaviorWrapperProps {
    children?: React.ReactNode;
    initialSelectedTab?: string;
    items?: {
        value: number;
        displayName?: React.ReactNode;
        content?: JSX.Element;
        isSelected?: boolean;
    }[];
    onTabChange?: Function;
    onTabLoad?: Function;
    onLocationChange?: Function;
    location?: object;
    dateFilter?: DateFilter;
    showFilters?: boolean;
}
interface WithStickyBehaviorWrapperState {
    scrolledPastComponent: boolean;
    contentHeight?: number;
}

const withStickyBehavior = (
    StickyComponent: React.ComponentType<any>,
    {
        onScroll,
        topAdjust = 0,
    }: { onScroll?: Function; topAdjust?: number } = {},
) =>
    class WithStickyBehaviorWrapper extends Component<
        WithStickyBehaviorWrapperProps,
        WithStickyBehaviorWrapperState
    > {
        private _containerRef: any;
        private _contentRef: any;
        constructor(props: Readonly<WithStickyBehaviorWrapperProps>) {
            super(props);

            this.state = {
                scrolledPastComponent: false,
            };
        }

        componentDidMount() {
            const { scrolledPastComponent } = this.state;

            if (this._containerRef && this._contentRef) {
                const contentHeight =
                    topAdjust > 0
                        ? document.querySelector('.eds-tabs__navigation')
                              ?.clientHeight
                        : this._contentRef.clientHeight;

                this.setState({
                    contentHeight,
                });

                let containerPaddingTop = '0px';

                if (scrolledPastComponent) {
                    containerPaddingTop = `${this._contentRef.clientHeight}px`;
                }

                this._containerRef.style.paddingTop = containerPaddingTop;
            }
        }

        _checkScrollPosition = () => {
            if (HAS_WINDOW && this._containerRef && this._contentRef) {
                const { contentHeight } = this.state;
                const tabsContent = document.querySelector(
                    '.eds-tabs__content',
                ) as HTMLElement;

                if (tabsContent) {
                    const topPosition =
                        tabsContent.offsetTop +
                        (document.querySelector('.eds-tabs__navigation')
                            ?.clientHeight || 0);
                    const scrolledPastComponent = window.scrollY >= topPosition;

                    let containerPaddingTop = '0px';

                    if (scrolledPastComponent) {
                        containerPaddingTop = `${contentHeight}px`;
                    }

                    this._containerRef.style.paddingTop = containerPaddingTop;

                    this.setState({
                        scrolledPastComponent,
                    });

                    if (onScroll) {
                        onScroll({
                            navBarHeight: this._containerRef.clientHeight,
                        });
                    }
                }
            }
        };

        render() {
            const { scrolledPastComponent } = this.state;

            const containerRef = (el: any) => {
                this._containerRef = el;
            };

            const contentRef = (el: any) => {
                this._contentRef = el;
            };

            const contentClassNames = classNames({
                'with-sticky-behavior': scrolledPastComponent,
                'without-sticky-behavior ': !scrolledPastComponent,
            });

            return (
                <div ref={containerRef} className={contentClassNames}>
                    <div ref={contentRef}>
                        <StickyComponent
                            {...this.props}
                            isSticky={scrolledPastComponent}
                        >
                            {this.props.children}
                        </StickyComponent>
                    </div>
                </div>
            );
        }
    };

export default withStickyBehavior;
