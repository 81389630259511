import React, { useEffect, useState } from 'react';
import AttendeeTicketNotificationContent from '../components/AttendeeTicketNotificationContent';
import Notification from '../components/Notification';
import NotificationRenderer from '../components/NotificationRenderer';
import { LiveEventContext } from '../context/Event';
import { useAttendeeData, useIsLiveEvent } from '../hooks';
import { getTimeUntilStart } from '../lib/dates';
import { getEventInfoFromOrder } from '../lib/orders';
import './AttendeeTicketNotification.scss';

interface AttendeeTicketNotificationProps {
    /*
     * Classes that will be applied to
     * the notification directly.
     */
    containerClasses?: string;
    /*
     * Inline styles that will be applied
     * directly to the notification component.
     *
     * Useful for changing right or top offset
     * as your application needs
     */
    containerStyles?: object;
    /*
     * Number of hours ahead an event
     * start that the notification should
     * be displayed. Defaults to 6
     */
    hoursAheadPreview?: number;
    /*
     * If passed in, will render the notification
     * direclty as a child of the passed in dom
     * node.
     *
     * Value determined by domNodeId will take
     * precedence if both are defined.
     */
    domNode: string;
    /*
     * If passed in, will query the dom for
     * the ID and render the notification as
     * a direct child.
     *
     * domNodeId takes precedence if defined
     */
    domNodeId?: string;
    /*
     * Makes it impossible to close the
     * modal and dismiss.
     */
    isNotDismissable?: boolean;
    /*
     * Optional boolean the consumer
     * can hydrate to prevent a
     * potentially unecessary api
     * call.
     */
    isAuthenticated?: boolean;
    /*
     * Callback triggered when
     * a user clicks on the CTA link
     *
     * Returns the EID of the active event
     */
    onLinkClick?: Function;
    /*
     * Callback triggered when the user
     * dismisses the notification
     *
     * Returns a diff of the current time
     * and event start time (ie time until start)
     */
    onDismiss?: Function;
    /*
     * Boolean which dictates if
     * the package should animate upon
     * mounting on the dom
     */
    shouldAnimateEntry?: boolean;
    /*
     * The direction that the notification should animate
     * upon mount.
     *
     * "left": animate moving from right to left
     * "right": animate moving left to right
     * "upwards": animate moving up
     * "downwards": animate moving down
     *
     * Defaults to "left"
     */
    animateEntryDirection: 'left' | 'right' | 'upwards' | 'downwards';
}

export const AttendeeTicketNotification = (
    props: AttendeeTicketNotificationProps,
) => {
    const [shouldRender, setRender] = useState(true);
    const userData = useAttendeeData({
        hoursAheadPreview: props.hoursAheadPreview || 6,
        isAuthenticated: props.isAuthenticated,
    });

    const [upcomingOrder = {}] = userData ? userData.userOrders : [];
    const {
        eventId,
        ctaLinkUrl,
        eventName,
        hasDigitalContent,
        start: localizedEventStart,
    } = getEventInfoFromOrder(upcomingOrder);

    const isEventLive = useIsLiveEvent(localizedEventStart);

    /* If the event goes live. Set render state to true. This handles the
    case where the user dismisses the component but continues to stay on
    the page. */
    useEffect(() => {
        if (isEventLive) {
            setRender(true);
        }
    }, [isEventLive]);

    if (!shouldRender || !localizedEventStart) {
        return null;
    }

    const handleClose = () => {
        setRender(false);
        props.onDismiss &&
            props.onDismiss(
                getTimeUntilStart(localizedEventStart),
                hasDigitalContent,
            );
    };

    const handleLinkClick = () => {
        props.onLinkClick && props.onLinkClick(eventId, hasDigitalContent);
    };

    return (
        <LiveEventContext.Provider value={isEventLive}>
            <NotificationRenderer
                domNode={props.domNode}
                domNodeId={props.domNodeId || ''}
            >
                <Notification
                    shouldAnimateEntry={props.shouldAnimateEntry}
                    animateEntryDirection={props.animateEntryDirection}
                    containerClasses={
                        !hasDigitalContent
                            ? `notification__physical-event ${props.containerClasses}`
                            : props.containerClasses
                    }
                    containerStyles={props.containerStyles}
                    onClose={handleClose}
                    isNotDismissable={props.isNotDismissable}
                >
                    <AttendeeTicketNotificationContent
                        eventStart={localizedEventStart}
                        ctaLinkUrl={ctaLinkUrl || ''}
                        hasDigitalContent={hasDigitalContent}
                        eventName={eventName}
                        onLinkClick={handleLinkClick}
                    />
                </Notification>
            </NotificationRenderer>
        </LiveEventContext.Provider>
    );
};

export default AttendeeTicketNotification;
