import { HorizontalDotsChunky } from '@eventbrite/eds-iconography';
import { MoreActions } from '@eventbrite/eds-more-actions';
import { FormattedEvent } from '@eventbrite/event-renderer';
import { gettext } from '@eventbrite/i18n';
import React, { useRef } from 'react';

export type ConsumerCardsActionsProps = {
    event: FormattedEvent;
    actionStyle?: string;
    onRemoveEvent(eventId: string): void;
};

export function ConsumerCardsActions({
    event,
    actionStyle = 'dark',
    onRemoveEvent,
}: ConsumerCardsActionsProps) {
    const containerRef = useRef<HTMLSpanElement>(null);

    const actionHandler = (action: ConsumerCardsAction) => {
        switch (action) {
            case ConsumerCardsAction.hideEvent:
                onRemoveEvent(event.id);
                break;
            default:
                break;
        }
    };

    return (
        <span ref={containerRef}>
            <MoreActions
                items={[
                    {
                        content: gettext('Hide this event'),
                        value: ConsumerCardsAction.hideEvent,
                    },
                ]}
                style={actionStyle}
                iconType={<HorizontalDotsChunky />}
                title={gettext('Event actions: %(eventName)s', {
                    eventName: event.name,
                })}
                onAction={actionHandler}
            />
        </span>
    );
}

export enum ConsumerCardsAction {
    hideEvent = 'hide-event',
}
