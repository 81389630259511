import ReactDOM from 'react-dom';

const NotificationRenderer = (props: {
    domNode?: string;
    domNodeId?: string;
    children: JSX.Element;
}) => {
    const renderViaPortal = !!props.domNode && !!props.domNodeId;

    if (!renderViaPortal) {
        return props.children;
    }

    let renderNode: any;

    if (props.domNode) {
        renderNode = props.domNode;
    }

    if (props.domNodeId) {
        renderNode = document.getElementById(props.domNode || '');
    }

    return ReactDOM.createPortal(props.children, renderNode);
};

export default NotificationRenderer;
