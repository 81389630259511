import {
    Calendar as CalendarSVG,
    GhostGraphic as GhostSVG,
    Heart as HeartSVG,
    TicketsIllustration as EmptyStateSVG,
} from '@eventbrite/eds-iconography';
import { GenericLazyString, gettext } from '@eventbrite/i18n';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import React, { PureComponent } from 'react';
import {
    EmptyStateConfig,
    EmptyStates,
    EmptyStateShape,
} from '../../../../types/index';
import { StateMessage } from '../StateMessage';

const USE_DEFAULT_EMPTY_STATE = {
    title: gettext('No events in your area'),
    iconSize: 'medium',
    description: gettext('Try a different location'),
    primaryLink: '',
    primaryText: '',
    buttonStyle: '',
};

const EmptyStateIconMap: Record<string, any> = {
    EmptyState: <EmptyStateSVG />,
    Heart: <HeartSVG />,
    Ghost: <GhostSVG />,
};

interface EmptyStateProps {
    emptyState?: EmptyStates;
    bucketKey?: string;
    shouldShowEmptyState?: boolean;
    isAuthenticated?: boolean;
}

export default class EmptyState extends PureComponent<EmptyStateProps> {
    _getEmptyStateComponent = ({
        bucketKey,
        title,
        iconSize,
        description,
        primaryLink,
        primaryText,
        buttonStyle,
        iconType = 'default',
    }: {
        bucketKey?: string;
        title?: GenericLazyString;
        iconType?: string;
        iconSize?: string;
        description?: GenericLazyString;
        primaryLink?: string;
        primaryText?: string;
        buttonStyle?: string;
    }) => (
        <>
            <div
                data-testid="feed-events-empty"
                className="feed-events__empty-state"
                key={bucketKey}
                id={`feed-events__empty-state-${bucketKey}`}
            >
                <StateMessage
                    key={`empty-state-${bucketKey}-isAuth`}
                    title={title}
                    iconType={EmptyStateIconMap?.[iconType] || <CalendarSVG />}
                    iconSize={iconSize}
                    description={description}
                    primaryLink={primaryLink}
                    primaryText={primaryText}
                    buttonStyle={buttonStyle}
                />
            </div>
        </>
    );

    render() {
        const { emptyState, bucketKey, isAuthenticated } = this.props;

        // Default behavior is don't show any bucket
        let emptyStateComponent = null;

        // Get the first valid condition for empty state
        const validEmptyState = find(
            emptyState,
            (es: EmptyStateShape) =>
                es.validation.shouldShowEmptyState &&
                (es.validation.isAuthenticated === isAuthenticated ||
                    es.validation.useDefault === true),
        );

        // If we don't have a valid condition is because we don't have to render any bucket
        if (!isEmpty(validEmptyState)) {
            let emptyStateConfig: EmptyStateConfig = USE_DEFAULT_EMPTY_STATE;

            // If we have a config, we overwrite default empty state
            if (!isEmpty((validEmptyState as EmptyStateShape)?.config)) {
                emptyStateConfig = (validEmptyState as EmptyStateShape)?.config;
            }
            emptyStateComponent = this._getEmptyStateComponent({
                bucketKey,
                ...emptyStateConfig,
            });
        }

        return emptyStateComponent;
    }
}
