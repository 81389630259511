import { PRIMARY_BUCKET } from '../constants/constants';
import { TABS_CONFIG } from '../constants/tabConfig';
import { BucketProps } from '../pages/home/containers/BucketContainer/BucketContainer';
import { ONLINE_EVENTS } from '../pages/home/containers/BucketContainer/BucketContainer.constants';

const isPrimaryBucket = (bucketName: string): boolean =>
    bucketName === PRIMARY_BUCKET;
export function formatBucketLabelFromKey({
    bucketKey,
    tabKey,
    name,
    currentPlace,
}: Pick<BucketProps, 'bucketKey' | 'tabKey' | 'name' | 'currentPlace'>) {
    let bucketLabel = name?.toString();
    if (name?.lazyIncludeLocation) {
        bucketLabel = name.lazyIncludeLocation?.(currentPlace || '').toString();
    }
    if (bucketKey && !isPrimaryBucket(bucketKey)) {
        bucketLabel = `${bucketLabel} in ${currentPlace}`;
    }
    if (currentPlace === ONLINE_EVENTS && bucketKey === PRIMARY_BUCKET) {
        bucketLabel = ONLINE_EVENTS;
    }
    if (!bucketLabel && tabKey) {
        bucketLabel = TABS_CONFIG[tabKey].name.toString();
    }
    return bucketLabel?.length ? bucketLabel : null;
}
