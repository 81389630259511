import { EventInteraction, useTracking } from '@eventbrite/ads';
import { track } from '@eventbrite/datalayer-library';
import { FormattedEvent } from '@eventbrite/event-renderer';
import { GenericLazyString, gettext } from '@eventbrite/i18n';
import { logEvent } from '@eventbrite/statsig';
import { $FixMe } from '@eventbrite/ts-utils';
import loadable from '@loadable/component';
import classNames from 'classnames';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    BEST_OF_CITY_EVENTS,
    BUCKET_TYPE_PROFILE,
    POPULAR_EVENTS,
    PRIMARY_BUCKET,
} from '../../../../constants/constants';
import { FOR_YOU, FREE_TAB } from '../../../../constants/tabConfig';
import { useEnvContext } from '../../../../context';
import { useHasEnteredViewport } from '../../../../hooks';
import { TrackingContext } from '../../../../trackingContext';
import {
    BucketConfig,
    ConnectedProps,
    DiscoverState,
    OrganizerProfiles,
} from '../../../../types';
import { formatBucketLabelFromKey } from '../../../../utils/buckets';
import { ConsumerCards } from '../../components/ConsumerCards';
import {
    HEAP_BUCKET_VIEW,
    HEAP_LOCATION_STRING,
    HEAP_SEE_MORE_BTN_CLICK,
} from '../../constants/analytics';
import { clickOnSeeMore } from '../../redux/events';
import { getBucketConfig, getEventsLayout } from '../../utils';
import {
    DESKTOP_DEFAULT_LAYOUT_CONFIG,
    MOBILE_DEFAULT_LAYOUT_CONFIG,
    ONLINE_EVENTS,
} from './BucketContainer.constants';
import { useWindowWidth } from './BucketContainer.hooks';
import './BucketPremium.scss';
import { aggregateContext } from './tracking';

const BUCKET_KEYS = {
    PRIMARY_BUCKET: 'primary_bucket',
    USER_INTERESTS: 'user_interests_inperson_events',
};
const SeeMoreOfBucket = loadable(() => import('./SeeMoreOfBucket'));
const EmptyState = loadable(() => import('../../components/EmptyState'), {
    resolveComponent: (components: { EmptyState: React.ReactElement }) =>
        components.EmptyState,
});
const OrganizerCards = loadable(
    () => import('../../components/OrganizerCards'),
    {
        resolveComponent: (components: {
            OrganizerCards: React.ReactElement;
        }) => components.OrganizerCards,
    },
);

interface BucketHeaderProps {
    currentPlace?: string;
    isPrimaryBucket?: boolean;
    isSpecialBucket?: boolean;
    name?:
        | {
              lazyIncludeLocation?: (
                  locationName: string,
              ) => string | GenericLazyString;
          }
        | GenericLazyString;
    subtitle?: {
        lazyIncludeLocation?: (
            locatonName: string,
        ) => string | GenericLazyString;
    };
    icon?: React.ReactNode;
}

const BucketHeader = (props: BucketHeaderProps) => {
    if (!props.name && !props.subtitle) {
        return null;
    }

    let titleComponent = null;
    let subtitleComponent = null;
    let parsedName: any = null;
    if (props.name) {
        parsedName = props.name;

        const titleClassNames = classNames(
            'eds-text-color--grey-900',
            'eds-text-hs',
        );

        if (
            Object.prototype.hasOwnProperty.call(
                props.name,
                'lazyIncludeLocation',
            ) &&
            'lazyIncludeLocation' in props.name
        ) {
            parsedName = props.name.lazyIncludeLocation?.(
                props.currentPlace || '',
            );
        }

        if (props.currentPlace === ONLINE_EVENTS && props.isPrimaryBucket) {
            parsedName = gettext('Online Events');
        }

        titleComponent = <h3 className={titleClassNames}>{parsedName}</h3>;
    }

    if (props.subtitle && props.subtitle.lazyIncludeLocation) {
        const parsedSubtitle = props.subtitle.lazyIncludeLocation(
            props.currentPlace || '',
        );

        subtitleComponent = (
            <span className="eds-text-bm eds-text-color--grey-600">
                {parsedSubtitle}
            </span>
        );
    }

    const bucketHeaderClassNames = classNames(
        'feed__bucket-header',
        {
            'pad-bot-sm-up--premium': props.isSpecialBucket,
            'has-icon': props.icon,
        },
        'eds-l-mn-pad-hor-3',
        'eds-l-md-pad-hor-3',
        'eds-l-mw-pad-hor-3',
        'eds-l-ln-pad-hor-3',
        'eds-l-lg-pad-hor-3',
        'eds-l-lw-pad-hor-3',
    );

    let bucketHeader = null;

    if (subtitleComponent || titleComponent) {
        bucketHeader = (
            <div
                className={bucketHeaderClassNames}
                data-testid="feed-bucket-header"
            >
                {props.icon || null}
                {titleComponent}
                <div>{subtitleComponent}</div>
            </div>
        );
    }

    return bucketHeader;
};

type OwnProps = {
    events?: FormattedEvent[];
    profiles?: OrganizerProfiles;
    bucketConfig?: BucketConfig;
    bucketKey?: string;
    bucketIndex: number;
    name: $FixMe;
    seeMore?: { url?: string };
    shouldShowSeeMore?: boolean;
    subtitle?: {
        lazyIncludeLocation?: (
            locationName: string,
        ) => string | GenericLazyString;
    };
    type?: string;
    icon?: React.ReactNode;
    isGDPRCountry?: boolean;
};
export const Bucket = ({
    events = [],
    profiles = [],
    searchCriteria = {},
    bucketConfig,
    bucketKey,
    bucketIndex,
    name,
    seeMore,
    shouldShowSeeMore,
    subtitle,
    sessionId,
    tabKey,
    currentPlace,
    isGDPRCountry,
    type,
    onSeeMore,
    locationSlug,
    seasonalContent,
    icon,
    location,
}: BucketProps) => {
    const { user } = useEnvContext();
    const isAuthenticated = user?.isAuthenticated;

    const getTrackingContext = ({ id, event }: EventInteraction) =>
        aggregateContext({
            id,
            event,
            events,
            eventSearch: searchCriteria,
            userId: user?.publicId,
            guestId: user?.guestId,
            sessionId: sessionId,
            bucketName: bucketKey,
            bucketIndex,
            locale,
            tabKey,
            location,
        });
    const { ref } = useTracking<HTMLDivElement>(getTrackingContext);

    const { hasEnteredViewport: isInViewport } = useHasEnteredViewport(ref, {
        visibleInitial: false,
        rootMargin: '0px 0px 10px 0px',
    });

    const { locale = 'en-US' } = useContext(TrackingContext);

    const windowWidth = useWindowWidth();
    const isMobile = (windowWidth || 0) <= 660;

    useEffect(() => {
        const shouldTrackBucket =
            tabKey === FOR_YOU && isAuthenticated && isInViewport;

        if (shouldTrackBucket) {
            let bucketLabel = name?.toString();

            if (name?.lazyIncludeLocation) {
                bucketLabel = name
                    .lazyIncludeLocation?.(currentPlace || '')
                    .toString();
            }

            if (bucketLabel) {
                track({
                    eventName: HEAP_BUCKET_VIEW,
                    eventData: {
                        personalizationBucketName: bucketLabel,
                        personalizationBucketKey: bucketKey,
                    },
                });
            }
        }
    }, [isInViewport, name, tabKey, currentPlace, bucketKey, isAuthenticated]);

    const bucketClassNames = React.useMemo(() => {
        const bucketClassNamesDesktop = classNames(
            'feed-events-bucket',
            `feed-events--${bucketKey}`,
        );
        const bucketClassNamesMobile = classNames(
            bucketClassNamesDesktop,
            'feed-events-bucket--mobile',
        );

        return isMobile ? bucketClassNamesMobile : bucketClassNamesDesktop;
    }, [isMobile, bucketKey]);

    const layoutConfig = React.useMemo(() => {
        const overwrite = isMobile
            ? 'overwrite.props.layout.mobile.events'
            : 'overwrite.props.layout.desktop.events';
        const defaultConfig = isMobile
            ? MOBILE_DEFAULT_LAYOUT_CONFIG
            : DESKTOP_DEFAULT_LAYOUT_CONFIG;

        return getBucketConfig({
            bucketConfig: get(bucketConfig, overwrite, {}),
            defaultConfig,
        });
    }, [isMobile, bucketConfig]);

    const eventGroups = React.useMemo(
        () =>
            getEventsLayout({
                events,
                bucketConfig: layoutConfig,
            }),
        [layoutConfig, events],
    );

    const enableInterestsSelector =
        (!isAuthenticated && bucketKey === BUCKET_KEYS.PRIMARY_BUCKET) ||
        bucketKey === BUCKET_KEYS.USER_INTERESTS;

    if (isEmpty(events) && tabKey !== FOR_YOU) {
        return (
            <EmptyState
                bucketKey={bucketKey}
                emptyState={bucketConfig?.emptyState}
                isAuthenticated={isAuthenticated}
            />
        );
    }

    const headerTitle = (
        <BucketHeader
            name={name}
            subtitle={subtitle}
            currentPlace={currentPlace}
            isPrimaryBucket={bucketKey === PRIMARY_BUCKET}
            isSpecialBucket={[BEST_OF_CITY_EVENTS, POPULAR_EVENTS].includes(
                bucketKey || '',
            )}
            icon={icon}
        />
    );
    const formattedBucketLabel = formatBucketLabelFromKey({
        tabKey,
        name,
        bucketKey,
        currentPlace,
    });

    if (!isInViewport) {
        return (
            <section ref={ref}>
                <div className={bucketClassNames} style={{ height: '100px' }} />
            </section>
        );
    }

    return (
        <section ref={ref}>
            <div style={{ minHeight: '100px' }} className={bucketClassNames}>
                <ConsumerCards
                    headerTitle={headerTitle}
                    bucketLabel={formattedBucketLabel}
                    isAuthenticated={isAuthenticated}
                    isFreeTab={searchCriteria.price === FREE_TAB}
                    bucketLayoutConfig={layoutConfig}
                    eventGroups={eventGroups}
                    enableInterestsSelector={enableInterestsSelector}
                    statsigLocationString={HEAP_LOCATION_STRING}
                    locale={locale}
                    affCode={
                        tabKey === FOR_YOU ? 'ebdssbehomepeforyou' : 'ehometext'
                    }
                    isForYouTabSelected={tabKey === FOR_YOU}
                    getTrackingContext={getTrackingContext}
                />
                {type === BUCKET_TYPE_PROFILE && (
                    <OrganizerCards
                        bucketKey={bucketKey}
                        profiles={profiles}
                        isAuthenticated={isAuthenticated}
                        userId={user?.publicId}
                        isGDPRCountry={isGDPRCountry}
                    />
                )}
                {shouldShowSeeMore && (
                    <SeeMoreOfBucket
                        bucketKey={bucketKey}
                        seeMore={seeMore}
                        onSeeMore={(e: React.MouseEvent<HTMLElement>) => {
                            logEvent(HEAP_SEE_MORE_BTN_CLICK);
                            onSeeMore?.(e);
                        }}
                        searchCriteria={searchCriteria}
                        locationSlug={locationSlug}
                        tabKey={tabKey}
                        userId={user?.publicId}
                        seasonalContent={seasonalContent}
                        shouldShowSeeMore
                    />
                )}
            </div>
        </section>
    );
};

const _mapStateToProps = (state: DiscoverState) => ({
    searchCriteria: state.content?.flatBucket
        ? state.content.flatBucket.event_search
            ? state.content.flatBucket.event_search
            : state.content.flatBucket.eventSearch
        : { dates: ['current_future'] },
    locationSlug: state.location?.slug,
    currentPlace: state.location?.currentPlace,
    tabKey: state.content?.browseState?.tabKey,
    sessionId: state.app.sessionId,
    seasonalContent: state.content?.seasonal,
    isGDPRCountry: state.app.isGDPRCountry,
    location: state.location,
});

const _mapDispatchToProps = {
    onSeeMore: clickOnSeeMore,
};

type ReduxProps = ConnectedProps<
    typeof _mapStateToProps,
    typeof _mapDispatchToProps
>;

export type BucketProps = ReduxProps & OwnProps;

export default connect(_mapStateToProps, _mapDispatchToProps)(Bucket);
