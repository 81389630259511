import { CoreApplicationContext } from '@eventbrite/context-gen';
import { FormattedEvent } from '@eventbrite/event-renderer';
import { parseLocationResponse } from '@eventbrite/redux-destination';
import { FacebookOptions, TopDestinations } from '../../../types/App';
import {
    BucketsContent,
    FeatureFlags,
    PopularCities,
} from '../../../types/index';
import {
    getSeasonalContent,
    retrieveHomeFeedState,
    transformBucketsContent,
} from '../utils';
import { getHeaderContent } from './header';
import { transformHeaderContent } from './header/selectors';

interface minimalInitialStateProps {
    experiments?: any;
    featureFlags?: FeatureFlags;
    isOnline?: boolean;
    isBotRequest?: boolean;
    env: CoreApplicationContext['env'];
    facebookOptions?: FacebookOptions;
    user: {
        isAuthenticated?: boolean;
    };
}

export const minimalInitialState = (props: minimalInitialStateProps) => {
    const {
        experiments,
        featureFlags,
        isOnline,
        isBotRequest,
        env,
        facebookOptions,
        user: { isAuthenticated },
    } = props;

    const {
        currencyFormat,
        ebDomain: domain,
        isMobile,
        localeInfo: {
            // every locale can use a different twitter account and
            // facebook account, so we need to use them in order to build
            // share messages
            twitter_handle: twitterHandle,
            facebook_locale: facebookLocale,
            facebook_page: facebookPage,
            tld,
            locale,
            uses_language_subdirectory,
        },
        serverUrl,
    } = env;
    const seasonalContent = getSeasonalContent({ tld });
    const browseState = retrieveHomeFeedState({
        seasonalContent,
        isAuthenticated,
    });

    let _header;

    /**
     * We only get the header content when we have the full context loaded
     * to prevent double rendering and getting two images without reason.
     */
    if (tld) {
        /*
         * Get a default override in case we don't have any user info but we
         * do have an override for the tld for that seasonal.
         */
        _header = seasonalContent[0]?.byDate?.tldOverrides?.[tld];
    }
    return {
        currencyFormat,
        app: {
            domain,
            tld,
            locale,
            experiments,
            featureFlags: {
                ...featureFlags,
            },
            isMobile,
            loadingState: {
                isFeLoading: true,
                isSearchLoading: false,
                isCityBrowseLoading: false,
                isProfilesLoading: false,
                isOrganizerArticlesLoading: false,
            },
            isBotRequest,
            uses_language_subdirectory,
        },
        env,
        content: {
            entities: {},
            entityContext: [],
            flatBucket: [],
            browseState: browseState,
            seasonal: seasonalContent,
        },
        organizers: {
            organizersUserFollow: [],
        },
        location: {
            slug: '',
            placeId: '',
            latitude: 0,
            longitude: 0,
            currentPlace: '',
            currentPlaceParent: '',
            placeType: '',
            topSuggestions: [],
            isOnline,
        },
        search: {
            slug: '',
            placeId: '',
            latitude: '',
            longitude: '',
            currentPlace: '',
            currentPlaceParent: '',
            placeType: '',
            topSuggestions: [],
        },
        user: {
            isAuthenticated: false,
            publicId: '',
            canCreateEvents: true,
        },
        shareOptions: {
            isMobile,
            twitterHandle,
            serverUrl,
            facebookOptions: {
                ...facebookOptions,
                locale: facebookLocale,
                page: facebookPage,
            },
        },
        header: {
            ..._header,
        },
    };
};

interface InitialState extends minimalInitialStateProps {
    buckets: BucketsContent;
    locationSlug?: string;
    latitude?: number;
    longitude?: number;
    placeId?: string;
    currentPlace?: string;
    currentPlaceParent?: string;
    country?: string;
    placeType?: string;
    locations?: string[];
    flatBucket?: {
        result?: FormattedEvent[];
    };
    user: {
        isAuthenticated?: boolean;
        publicId?: string;
        canCreateEvents?: string;
        firstName?: string;
    };
    guestId: string;
    country_popular_cities?: PopularCities;
    top_destinations_for_tld?: TopDestinations;
}

export const getInitialState = (props: InitialState) => {
    const {
        buckets,
        experiments,
        featureFlags,
        guestId,
        locationSlug,
        placeId,
        latitude,
        longitude,
        currentPlace,
        currentPlaceParent,
        country,
        placeType,
        locations,
        flatBucket,
        isOnline,
        isBotRequest,
        env,
        facebookOptions,
        country_popular_cities,
        top_destinations_for_tld,
        user: { isAuthenticated, publicId, canCreateEvents, firstName },
    } = props;

    const {
        currencyFormat,
        ebDomain: domain,
        isMobile,
        localeInfo: {
            // every locale can use a different twitter account and
            // facebook account, so we need to use them in order to build
            // share messages
            twitter_handle: twitterHandle,
            facebook_locale: facebookLocale,
            facebook_page: facebookPage,
            tld,
            locale,
        },
        serverUrl,
    } = env;
    const seasonalContent = getSeasonalContent({ placeId, tld });
    const browseState = retrieveHomeFeedState({
        seasonalContent,
        isAuthenticated,
    });
    const { entities, entityContext } = transformBucketsContent(buckets);
    const organizersUserFollow: string[] = [];

    let _header;

    /**
     * We only get the header content when we have the full context loaded
     * to prevent double rendering and getting two images without reason.
     */
    if (currentPlace || placeId || latitude) {
        _header = getHeaderContent({
            seasonalContent,
            tld,
            locationSlug,
            featureFlags,
        });
    } else if (tld) {
        /*
         * Get a default override in case we don't have any user info but we
         * do have an override for the tld for that seasonal.
         */
        _header = transformHeaderContent(
            seasonalContent[0]?.byDate?.tldOverrides?.[tld],
        );
    }

    return {
        currencyFormat,
        app: {
            domain,
            tld,
            locale,
            experiments,
            featureFlags,
            isMobile,
            loadingState: {
                isFeLoading: true,
                isSearchLoading: false,
                isCityBrowseLoading: false,
                isProfilesLoading: false,
                isOrganizerArticlesLoading: false,
            },
            isBotRequest,
            country_popular_cities,
            top_destinations_for_tld,
        },
        env,
        content: {
            entities,
            entityContext,
            flatBucket,
            browseState,
            seasonal: seasonalContent,
        },
        organizers: {
            organizersUserFollow,
        },
        location: {
            slug: locationSlug,
            placeId,
            latitude,
            longitude,
            currentPlace,
            currentPlaceParent,
            country,
            placeType,
            topSuggestions: locations ? parseLocationResponse(locations) : [],
            isOnline,
        },
        search: {
            slug: locationSlug,
            placeId,
            latitude,
            longitude,
            currentPlace,
            currentPlaceParent,
            placeType,
            topSuggestions: locations ? parseLocationResponse(locations) : [],
        },
        user: {
            isAuthenticated,
            publicId,
            canCreateEvents,
            firstName,
            guestId,
        },
        shareOptions: {
            isMobile,
            twitterHandle,
            serverUrl,
            facebookOptions: {
                ...facebookOptions,
                locale: facebookLocale,
                page: facebookPage,
            },
        },
        header: _header,
    };
};
