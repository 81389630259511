import { DEFAULT_CURRENCY_FORMAT } from '@eventbrite/intl';
import { $FixMe } from '@eventbrite/ts-utils';
import { AnyAction, combineReducers } from 'redux';
import thingsToDoShelf from '../../../../redux/reducers/thingsToDoShelf';
import { appReducer } from '../app';
import { contentReducer } from '../content';
import { headerReducer } from '../header';
import { locationReducer } from '../location';
import { organizersReducer } from '../organizers';
import { searchReducer, suggestionsReducer } from '../search';
import { userReducer } from '../user';
import { UPDATE_ROOT_CONTEXT } from './rootActionTypes';

const rootReducer = combineReducers<$FixMe>({
    app: appReducer,
    content: contentReducer,
    location: locationReducer,
    suggestions: suggestionsReducer,
    user: userReducer,
    thingsToDoShelf: thingsToDoShelf,
    organizers: organizersReducer,
    search: searchReducer,
    env: (state = {}) => state,
    currencyFormat: (state = DEFAULT_CURRENCY_FORMAT) => state,
    shareOptions: (state = {}) => state,
    header: headerReducer,
    headerImageIndex: (state = '') => state,
});

export default function updateableRootReducer(state = {}, action: AnyAction) {
    let newState = state;

    if (action && action.type === UPDATE_ROOT_CONTEXT) {
        newState = action.payload;
    }

    return rootReducer(newState, action);
}
