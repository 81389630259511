import { UPDATE_HEADER_CONTENT } from './actions';

const header = (
    state = {},
    { type, payload }: { type?: string; payload?: object },
) => {
    let nextState = state;

    if (type === UPDATE_HEADER_CONTENT) {
        nextState = { ...state, ...payload };
    }

    return nextState;
};

export default header;
