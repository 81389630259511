import { useCallback, useEffect, useState } from 'react';
import { getWindowObject } from './get-window-object';

const MOBILE_BREAKPOINT = 660;
const TABLET_BREAKPOINT = 1080;

export const useIsMobileDevice = (maxMobileWidth = MOBILE_BREAKPOINT) => {
    const [windowWidth, setWindowWidth] = useState<number | null>(null);

    const updateDimensions = useCallback(() => {
        setWindowWidth(getWindowObject('innerWidth'));
    }, []);

    useEffect(() => {
        updateDimensions();

        window.addEventListener('resize', updateDimensions);

        return () => window.removeEventListener('resize', updateDimensions);
    }, [updateDimensions]);

    const isMobile = (windowWidth || 0) <= maxMobileWidth;
    return isMobile;
};

export const useIsTabletDevice = (maxTabletWidth = TABLET_BREAKPOINT) => {
    const [windowWidth, setWindowWidth] = useState<number | null>(null);

    const updateDimensions = useCallback(() => {
        setWindowWidth(getWindowObject('innerWidth'));
    }, []);

    useEffect(() => {
        updateDimensions();

        window.addEventListener('resize', updateDimensions);

        return () => window.removeEventListener('resize', updateDimensions);
    }, [updateDimensions]);

    const isTablet =
        (windowWidth || 0) <= maxTabletWidth &&
        (windowWidth || 0) > MOBILE_BREAKPOINT;
    return isTablet;
};
