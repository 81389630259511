import logger from '@eventbrite/client-logger';
import { useEffect, useRef, useState } from 'react';
import { getUser, getUserOrders } from '../api';
import { checkIfEventLive } from '../lib/dates';
import { getOrdersInRange } from '../lib/orders';
import { Order } from '../types';

export const useAttendeeData = ({
    hoursAheadPreview,
    isAuthenticated,
}: {
    hoursAheadPreview: number;
    isAuthenticated?: boolean;
}) => {
    const [userData, setUserData] = useState<any>(null);

    useEffect(() => {
        const getUserData = async () => {
            let isUserAuthenticated = isAuthenticated;

            if (userData === null) {
                if (typeof isUserAuthenticated !== 'boolean') {
                    try {
                        const user = await getUser();

                        if (user) {
                            isUserAuthenticated = true;
                        }
                    } catch {
                        //Expected failure here if the user isn't
                        //logged in. No need to log issue.
                        setUserData(false);
                    }
                }

                let userOrders;
                let userOrdersInRange: Order[] = [];

                if (isUserAuthenticated) {
                    try {
                        userOrders = await getUserOrders();
                    } catch (error: any) {
                        logger.error(error, {
                            numberOfOrders: userOrders?.orders?.length || 0,
                            message: 'Failed to fetch orders',
                        });
                        setUserData(false);
                        return;
                    }

                    try {
                        userOrdersInRange = getOrdersInRange(
                            userOrders.orders,
                            hoursAheadPreview,
                        );

                        setUserData({ userOrders: userOrdersInRange });
                    } catch (error: any) {
                        logger.error(error, {
                            userOrdersInRange,
                            userOrders: userOrders?.orders,
                            message: 'Failed to parse orders',
                        });
                        setUserData(false);
                        return;
                    }
                }
            }
        };

        window.requestIdleCallback
            ? window.requestIdleCallback((_deadline) => {
                  getUserData();
              })
            : getUserData();
    }, [hoursAheadPreview, userData, isAuthenticated]);

    return userData;
};

export const useIsLiveEvent = (localizedEventStart: string) => {
    const [isLive, setLive] = useState(checkIfEventLive(localizedEventStart));
    const intervalId = useRef<number | null>(null);

    const clearInterval = () => {
        if (intervalId.current) {
            const invtervalId = intervalId.current;
            intervalId.current = null;

            window.clearInterval(invtervalId);
        }
    };

    const intervalCallBack = () => {
        const isEventLive = checkIfEventLive(localizedEventStart);

        if (isEventLive) {
            setLive(true);
            clearInterval();
        }

        return;
    };

    if (!isLive && !!localizedEventStart && !intervalId.current) {
        intervalId.current = window.setInterval(intervalCallBack, 10000);
    }

    useEffect(() => {
        setLive(checkIfEventLive(localizedEventStart));
    }, [localizedEventStart]);

    return isLive;
};
