import classNames from 'classnames';
import times from 'lodash/times';
import React from 'react';
import './loadingCards.scss';

export const EVENT_TYPE = 'event';
export const ORGANIZER_TYPE = 'organizer';
export const ORGANIZER_ARTICLE_TYPE = 'organizerArticle';

type CardType =
    | typeof EVENT_TYPE
    | typeof ORGANIZER_TYPE
    | typeof ORGANIZER_ARTICLE_TYPE;

interface LoadingCardsProps {
    /*
     * Determines loading card style to display
     * list, grid, list-square
     */
    style: 'list' | 'grid' | 'list-square' | 'list-responsive';
    /*
     * The number of cards to be rendered
     */
    cardCount: number;
    /*
     * Determines loading card type to render
     * event, organizer
     */
    type: CardType;
    /**
     * Flipped changes the side the image
     * is rendered on
     */
    isFlipped?: boolean;
}

const EventLoadingCard = ({ isFlipped = false }) => (
    <div
        data-testid="consumer-event-loading-card"
        className="consumer-event-loading-card"
    >
        {isFlipped ? null : (
            <div className="consumer-event-loading-card__item consumer-event-loading-card__main" />
        )}
        <div className="consumer-event-loading-card__aside">
            <div className="consumer-event-loading-card__item" />
            <div className="consumer-event-loading-card__item" />
            <div className="consumer-event-loading-card__item" />
            <div className="consumer-event-loading-card__item" />
        </div>
        {isFlipped ? (
            <div className="consumer-event-loading-card__item consumer-event-loading-card__main" />
        ) : null}
    </div>
);

const OrganizerLoadingCard = () => (
    <div className="consumer-org-loading-card">
        <div className="consumer-org-loading-card__item consumer-org-loading-card__main" />
        <div className="consumer-org-loading-card__aside">
            <div className="consumer-org-loading-card__item" />
            <div className="consumer-org-loading-card__item" />
            <div className="consumer-org-loading-card__item" />
            <div className="consumer-org-loading-card__item" />
        </div>
    </div>
);

const OrganizerArticleLoadingCard = () => (
    <div className="consumer-org-article-loading-card">
        <div className="consumer-org-article-loading-card__header">
            <div className="consumer-org-article-loading-card__header-title" />
            <div className="consumer-org-article-loading-card__header-description--large" />
            <div className="consumer-org-article-loading-card__header-description--small" />
        </div>
        <div className="consumer-org-article-loading-card__background">
            <div className="consumer-org-article-loading-card__summary">
                <div className="consumer-org-article-loading-card__summary-org-info">
                    <div className="consumer-org-article-loading-card__summary-org-image" />
                    <div className="consumer-org-article-loading-card__summary-org-text-container">
                        <div className="consumer-org-article-loading-card__summary-org-title" />
                        <div className="consumer-org-article-loading-card__summary-org-subtitle" />
                    </div>
                </div>
                <div className="consumer-org-article-loading-card__summary-title--large" />
                <div className="consumer-org-article-loading-card__summary-title--small" />
                <div className="consumer-org-article-loading-card__summary-description--large" />
                <div className="consumer-org-article-loading-card__summary-description--small" />
                <div className="consumer-org-article-loading-card__summary-cta" />
            </div>
        </div>
    </div>
);

const _getDeclaredCards = (
    cardCount: number,
    type: CardType,
    isFlipped?: boolean,
) =>
    times(cardCount, (index) => {
        let loadingCard = (
            <EventLoadingCard
                key={`consumer-event-loading-card-${index}`}
                isFlipped={isFlipped}
            />
        );

        if (type === ORGANIZER_TYPE) {
            loadingCard = (
                <OrganizerLoadingCard
                    key={`consumer-organizer-loading-card-${index}`}
                />
            );
        }

        if (type === ORGANIZER_ARTICLE_TYPE) {
            loadingCard = (
                <OrganizerArticleLoadingCard
                    key={`consumer-org-article-loading-card-${index}`}
                />
            );
        }

        return loadingCard;
    });

/*
 * Rough implementation of a configurable loading state component for
 * event style cards on search experiences. Currently structured to support
 * a single column of cards of variable counts and styles.
 */
export class LoadingCards extends React.PureComponent<LoadingCardsProps> {
    static defaultProps = {
        style: 'list',
        type: EVENT_TYPE,
        cardCount: 5,
        isFlipped: false,
    };

    render() {
        const { style, cardCount, type, isFlipped } = this.props;

        const cards = _getDeclaredCards(cardCount, type, isFlipped);

        const classes = classNames(
            'consumer-loading-cards__container',
            `consumer-loading-cards--${style}`,
            'eds-l-pad-top-6 eds-l-pad-hor-6',
            {
                [`consumer-loading-cards--${style}--flipped`]: isFlipped,
            },
        );

        return <section className={classes}>{cards}</section>;
    }
}

export default LoadingCards;
