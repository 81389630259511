import { fetchJSON } from '@eventbrite/http';
import { IRecommendedInterests } from '../../../../types/index';

export const recommendedInterests = async () => {
    const resp = await (
        await fetch(
            '/api/v3/destination/users/me/recommendations/my_suggested_interests_recommendation/',
        )
    ).json();

    return resp;
};

export const toggleSaveInterests = async (interest: IRecommendedInterests) => {
    const toggleType = interest.isSelected ? 'unsave' : 'save';
    await fetchJSON(`/api/v3/destination/users/me/tags/${toggleType}/`, {
        method: 'POST',
        body: JSON.stringify({
            tags: [interest.id],
        }),
    });

    return { interest };
};
