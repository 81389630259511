import { getHeaderContent } from './selectors';

export const UPDATE_HEADER_CONTENT = 'UPDATE_HEADER_CONTENT';

const updateHeaderImageAction = (payload: object) => ({
    type: UPDATE_HEADER_CONTENT,
    payload,
});

export const updateHeaderImage =
    () => (dispatch: Function, getState: Function) => {
        const {
            content: { seasonal },
            headerImageIndex,
            app: { tld, featureFlags },
            location: { slug },
        } = getState();

        const payload = getHeaderContent({
            seasonalContent: seasonal,
            fixedImageIndex: headerImageIndex,
            tld,
            locationSlug: slug,
            featureFlags,
        });
        dispatch(updateHeaderImageAction(payload));
    };
