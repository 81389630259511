import { combineReducers } from 'redux';
import { UPDATE_SEARCH_SUGGESTIONS } from '../../../../constants/constants';
import { UPDATE_EVENT_SAVED_BY_YOU } from '../events/actions';

const query = (
    state: string | string[] = [],
    { type, payload }: { type: string; payload?: { query?: string } },
) => {
    let nextState = state;

    if (type === UPDATE_SEARCH_SUGGESTIONS && payload?.query) {
        nextState = payload.query;
    }

    return nextState;
};

const event = (
    state: string | string[] = [],
    { type, payload }: { type: string; payload?: { event?: string[] } },
) => {
    let nextState = state;

    if (type === UPDATE_SEARCH_SUGGESTIONS && payload?.event) {
        nextState = payload.event;
    }

    return nextState;
};

const categories = (
    state: string | string[] = [],
    { type, payload }: { type: string; payload?: { categories?: string[] } },
) => {
    let nextState = state;

    if (type === UPDATE_SEARCH_SUGGESTIONS && payload?.categories) {
        nextState = payload.categories;
    }

    return nextState;
};

const events = (
    state: string | string[] = [],
    { type, payload }: { type: string; payload?: { events?: string[] } },
) => {
    let nextState = state;

    if (type === UPDATE_SEARCH_SUGGESTIONS && payload?.events) {
        nextState = payload.events;
    }

    return nextState;
};

const rawEvents = (
    state: any = [],
    {
        type,
        payload,
    }: {
        type: string;
        payload?: any;
    },
) => {
    let nextState = state;

    if (type === UPDATE_SEARCH_SUGGESTIONS && payload?.rawEvents) {
        nextState = payload.rawEvents;
    }

    if (type === UPDATE_EVENT_SAVED_BY_YOU) {
        const { eventId, savedByYou } = payload;

        nextState = {
            ...nextState,
            [eventId]: {
                ...nextState[eventId],
                saves: {
                    savedByYou,
                },
            },
        };
    }

    return nextState;
};

const history = (
    state: string | string[] = [],
    { type, payload }: { type: string; payload?: { history?: string } },
) => {
    let nextState = state;

    if (type === UPDATE_SEARCH_SUGGESTIONS && payload?.history) {
        nextState = payload.history;
    }

    return nextState;
};

export default combineReducers({
    query,
    event,
    categories,
    events,
    rawEvents,
    history,
});
