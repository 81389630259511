import { SimpleCarousel } from '@eventbrite/collection-carousel';
import { LoadingCards } from '@eventbrite/eds-event-card';
import { gettext } from '@eventbrite/i18n';
import React from 'react';
import './SeasonalCarousel.scss';

interface EventLoadingStateProps {
    numberOfEvents?: number;
}

export const EventLoadingState: React.FunctionComponent<
    EventLoadingStateProps
> = (props) => {
    const numberOfEvents =
        typeof props.numberOfEvents === 'number' ? props.numberOfEvents : 4;
    return (
        <div className="seasonal-carousel">
            <section className="seasonal-carousel-container">
                <SimpleCarousel
                    headerTitle={
                        <div className="title-subtitle-container">
                            <h2>{gettext('Mardi Gras Events')}</h2>
                        </div>
                    }
                    slidesVisible={{ desktop: 4, tablet: 2 }}
                    lazilyRenderOffScreenElements={true}
                >
                    {[...Array(numberOfEvents).keys()].map((item: number) => (
                        <div
                            key={`loading_card_${item}`}
                            style={{
                                width: '97%',
                                height: '400px',
                                marginTop: '8px',
                                marginBottom: '8px',
                            }}
                        >
                            <LoadingCards
                                style="grid"
                                dynamicWidth={true}
                                cardCount={1}
                                dynamicHeight={true}
                            />
                        </div>
                    ))}
                </SimpleCarousel>
            </section>
        </div>
    );
};
