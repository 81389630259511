import logger from '@eventbrite/client-logger';
import { fetchJSON } from '@eventbrite/http';

export const removeEvent = async (eventId: string) => {
    try {
        await fetchJSON(`/api/v3/destination/users/me/dislike_events/`, {
            method: 'POST',
            body: JSON.stringify({
                event_ids: [parseInt(eventId)],
            }),
        });
    } catch (e) {
        logger.error(new Error(`Unable to remove event with id: ${eventId})`));
    }
};
