import CONSTANTS from '../common/default.constants';
import { TagObject } from '../Tags';

/**
 * Split given tags into rows i.e. a list of tag lists
 * @param tags - List of objects with tag properties
 * @param columnsLimit - Column Limit for a tags row. This changes with the viewport/window-width
 * @returns A list of tag list
 */
const splitTagsIntoRows = (tags: Array<TagObject>, columnsLimit: number) => {
    const tagRows: Array<TagObject[]> = [];
    for (let i = 0; i < tags.length; i += columnsLimit) {
        tagRows.push(tags.slice(i, i + columnsLimit));
    }
    return tagRows;
};

/**
 * This util function generates tag Rows based on the window width.
 * @param tags  - List of objects with tag properties
 * @param windowWidth - Window Width or Viewport size
 * @returns A list of tag list
 */
export const getTagRows = (tags: Array<TagObject>, windowWidth: number) => {
    if (windowWidth < CONSTANTS.DESKTOP_VIEWPORT) {
        return splitTagsIntoRows(tags, CONSTANTS.TABLET_COLUMNS_LIMIT);
    }
    return splitTagsIntoRows(tags, CONSTANTS.DESKTOP_COLUMNS_LIMIT);
};
