// Thanks to: https://pawelgrzybek.com/page-scroll-in-vanilla-javascript/
export const scrollIt = (
    destination: number | { offsetTop: number },
    duration = 200,
    easing = 'linear',
    callback?: Function,
) => {
    const easings: { [key: string]: any } = {
        linear(t: any) {
            return t;
        },
    };

    const start = window.pageYOffset;
    const startTime =
        'now' in window.performance ? performance.now() : new Date().getTime();

    const documentHeight = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight,
    );
    const windowHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.getElementsByTagName('body')[0].clientHeight;
    const destinationOffset =
        typeof destination === 'number' ? destination : destination.offsetTop;
    const destinationOffsetToScroll = Math.round(
        documentHeight - destinationOffset < windowHeight
            ? documentHeight - windowHeight
            : destinationOffset,
    );

    if ('requestAnimationFrame' in window === false) {
        window.scroll(0, destinationOffsetToScroll);

        if (callback) {
            callback();
        }

        return;
    }

    /**
     * We create a variable to store the last pageYOffset to prevent inifinite runs
     * if the page suddenly becomes smaller and it is impossible to scroll to that position.
     */
    let previousValue = 0;

    const scroll = () => {
        const now =
            'now' in window.performance
                ? performance.now()
                : new Date().getTime();
        const time = Math.min(1, (now - startTime) / duration);
        const timeFunction = easings[easing](time);

        window.scroll(
            0,
            Math.ceil(
                timeFunction * (destinationOffsetToScroll - start) + start,
            ),
        );

        if (
            window.pageYOffset === destinationOffsetToScroll ||
            window.pageYOffset === previousValue
        ) {
            if (callback) {
                callback();
            }

            return;
        }

        // If we didn't reach the final destination, we will store the offset in the previous value.
        previousValue = window.pageYOffset;

        requestAnimationFrame(scroll);
    };

    scroll();
};
