/* eslint-disable react-hooks/exhaustive-deps */
import {
    SegmentedCarousel,
    useSegmentedCarouselController,
} from '@eventbrite/collection-carousel';
import { DESTINATION_CITY_BROWSE_SLA_MILLISECONDS } from '@eventbrite/discover-utils';
import { FormattedEvent } from '@eventbrite/event-renderer';
import { useIsMobileDevice } from '@eventbrite/feature-detection';
import { gettext } from '@eventbrite/i18n';
import { LoadingSkeleton } from '@eventbrite/loading-skeleton';
import { Typography } from '@eventbrite/marmalade';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import RankedCardContainer from '../../../../containers/RankedCardContainer/RankedCardContainer';
import { ConnectedProps, DiscoverState } from '../../../../types/index';
import { getPopularCityEvents } from '../../api/popularCityEvents';
import styles from './PopularEventsCarousel.module.scss';

const CarouselTrack = ({
    carouselLoading,
    locale,
    popularEvents,
}: {
    carouselLoading: boolean;
    locale: string;
    popularEvents: FormattedEvent[];
}) => {
    return (
        <SegmentedCarousel.Track>
            {carouselLoading
                ? Array.from({ length: 8 }, (_, i) => (
                      <LoadingSkeleton height={320} key={i} />
                  ))
                : popularEvents.map((event, i) => (
                      <RankedCardContainer
                          event={event}
                          key={i}
                          locale={locale}
                          rank={i + 1}
                          affCode="ehometext"
                      />
                  ))}
        </SegmentedCarousel.Track>
    );
};

export const DisconnectedPopularEventsCarousel = ({
    currentPlace,
    isFeLoading,
    locale,
    placeId,
}: PopularEventsCarouselProps) => {
    const [carouselLoading, setCarouselLoading] = useState(true);
    const [popularEvents, setPopularEvents] = useState<FormattedEvent[]>([]);

    const controllerProps = useSegmentedCarouselController();
    const { setShouldUpdateCarousel } = controllerProps;

    const headerText = gettext('Top trending in %(currentPlace)s', {
        currentPlace,
    });

    useEffect(() => {
        const controller = new AbortController();
        const { signal } = controller;
        const timeout = setTimeout(() => {
            if (controller) {
                controller.abort();
            }
        }, DESTINATION_CITY_BROWSE_SLA_MILLISECONDS);

        const fetchPopularEvents = async () => {
            if (placeId) {
                try {
                    const popularEvents = await getPopularCityEvents(
                        placeId,
                        signal,
                    );
                    if (!controller.signal.aborted) {
                        setPopularEvents(popularEvents);
                    }
                } catch (e) {
                    setPopularEvents([]);
                    console.error(e);
                } finally {
                    clearTimeout(timeout);
                }
                setCarouselLoading(false);
                setShouldUpdateCarousel(true);
            }
        };
        if (placeId) {
            fetchPopularEvents();
        }
        return () => controller.abort();
    }, [placeId, setShouldUpdateCarousel]);

    const showCarousel =
        !isFeLoading && (carouselLoading ? true : !!popularEvents.length);

    const isMobile = useIsMobileDevice(1219);

    return (
        <div className={styles.root}>
            {showCarousel && (
                <div className={styles.carouselContainer}>
                    <SegmentedCarousel
                        {...controllerProps}
                        hideOverflow={!isMobile}
                        rootPaddingLeft={0}
                        rootPaddingRight={isMobile ? undefined : 20}
                    >
                        <SegmentedCarousel.Header>
                            <Typography
                                className={styles.carouselHeader}
                                variant="heading-sm"
                                color="black"
                            >
                                {headerText}
                            </Typography>
                        </SegmentedCarousel.Header>
                        <CarouselTrack
                            carouselLoading={carouselLoading}
                            locale={locale}
                            popularEvents={popularEvents}
                        />
                        <SegmentedCarousel.Footer />
                    </SegmentedCarousel>
                </div>
            )}
        </div>
    );
};

const _mapStateToProps = ({ app, location }: DiscoverState) => {
    return {
        currentPlace: location?.currentPlace,
        isFeLoading: !!app.loadingState?.isFeLoading,
        locale: app.locale,
        placeId: location.placeId,
    };
};

type ReduxProps = ConnectedProps<typeof _mapStateToProps>;
type PopularEventsCarouselProps = ReduxProps;

export default connect(_mapStateToProps)(DisconnectedPopularEventsCarousel);
