import { useState } from 'react';

/**
 * Gives developers access to carousel controls
 * Useful when creating custom, external controls that need to interact with the carousel
 * Intended to be used with the SegmentedCarousel component
 */
export const useSegmentedCarouselController = () => {
    const [slideTo, setSlideTo] = useState<'next' | 'previous' | ''>('');
    const [shouldUpdateCarousel, setShouldUpdateCarousel] =
        useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [isFirstPage, setIsFirstPage] = useState<boolean>(true);
    const [isLastPage, setIsLastPage] = useState<boolean>(false);

    return {
        slideTo,
        setSlideTo,
        shouldUpdateCarousel,
        setShouldUpdateCarousel,
        currentPage,
        setCurrentPage,
        isFirstPage,
        setIsFirstPage,
        isLastPage,
        setIsLastPage,
    };
};
