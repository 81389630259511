import { useCallback, useLayoutEffect, useState } from 'react';

export const useWindowWidth = () => {
    const [windowWidth, setWindowWidth] = useState<number | null>(null);

    const updateDimensions = useCallback(() => {
        setWindowWidth(window.innerWidth);
    }, []);

    useLayoutEffect(() => {
        updateDimensions();

        window.addEventListener('resize', updateDimensions);

        return () => window.removeEventListener('resize', updateDimensions);
    }, [updateDimensions]);

    return windowWidth;
};
