import { Icon } from '@eventbrite/eds-icon';
import { CrossChunky } from '@eventbrite/eds-iconography';
import { Tag } from '@eventbrite/eds-tag';
import { gettext } from '@eventbrite/i18n';
import { trackAnalyticsEvent } from '@eventbrite/site-analytics';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { useLocalStorage, useSessionStorage } from 'react-use';
import { IRecommendedInterests } from '../../../../types/index';
import { toggleSaveInterests } from './apis';
import './InterestsSelector.scss';
import { useRecommendedInterestsQuery } from './queries';

interface InterestsSelectorProps {
    isAuthenticated?: boolean;
}
const InterestsSelector: React.FunctionComponent<InterestsSelectorProps> = (
    props,
) => {
    const { isAuthenticated } = props;

    const [interestsSelectorClosed, setInterestsSelectorClosed] =
        useLocalStorage('search:interestsSelector:closed', false);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [EBHomeFeedState, setEBHomeFeedState] = useSessionStorage(
        'EBHomeFeedState',
        { tabKey: 'all', tabKeyLabel: 'all' },
    );

    const queryClient = useQueryClient();
    const { data: interests } = useRecommendedInterestsQuery();

    const mutation = useMutation(toggleSaveInterests, {
        onMutate: async (savedInterest) => {
            await queryClient.cancelQueries([
                'recommendedInterests',
                savedInterest.id,
            ]);
            const newData = interests?.map?.((data: IRecommendedInterests) => ({
                ...data,
                isSelected:
                    data?.id === savedInterest?.id
                        ? !savedInterest?.isSelected
                        : data?.isSelected,
            }));

            queryClient.setQueryData(['recommendedInterests'], newData);
        },
    });

    const handleTagClick = (interest: IRecommendedInterests) => {
        if (isAuthenticated) {
            mutation.mutate(interest);
            trackAnalyticsEvent({
                action: interest.isSelected
                    ? 'RemoveInterest'
                    : 'SelectInterest',
                category: 'home-related-interests',
                label: interest.displayName,
            });
        } else {
            trackAnalyticsEvent({
                action: 'SelectInterestAttempt',
                category: 'home-related-interests',
                label: interest.displayName,
            });
            window.location.href = '/signin/';
        }
    };

    const handleViewAllInterests = () => {
        setEBHomeFeedState({ tabKey: 'for_you', tabKeyLabel: 'for_you' });
        trackAnalyticsEvent({
            action: 'ViewAllInterests',
            category: 'home-related-interests',
        });
    };

    return (
        <>
            {(!interestsSelectorClosed || !interests?.length) && (
                <div className="home__interests-selector__container">
                    <div
                        className="home__interests-selector__close-btn"
                        onClick={() => setInterestsSelectorClosed(true)}
                    >
                        <Icon
                            type={<CrossChunky />}
                            style="fill"
                            size="small"
                        />
                    </div>

                    <div className="home__interests-selector__header">
                        <h3 className="eds-text-hs">
                            {isAuthenticated
                                ? gettext('Add new interests')
                                : gettext("Let's make it personal")}
                        </h3>
                        <p className="home__interests-selector__subtitle">
                            {isAuthenticated
                                ? gettext(
                                      'Select new interests to get event suggestions based on what you love',
                                  )
                                : gettext(
                                      'Select your interests to get event suggestions based on what you love',
                                  )}
                        </p>
                    </div>

                    <div className="home__interests-selector__tag-container">
                        <div>
                            {interests?.map?.(
                                (interest: IRecommendedInterests) => (
                                    <Tag
                                        key={interest.id}
                                        style="outlined"
                                        onActiveToggle={() =>
                                            handleTagClick(interest)
                                        }
                                    >
                                        {gettext('%(displayName)s', {
                                            displayName: interest.displayName,
                                        })}
                                    </Tag>
                                ),
                            )}
                        </div>
                    </div>

                    <div className="home__interests-selector__view-all">
                        <a href="/u/interests" onClick={handleViewAllInterests}>
                            {gettext('View all interests')}
                        </a>
                    </div>
                </div>
            )}
        </>
    );
};

export default InterestsSelector;
