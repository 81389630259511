import { logEvent } from '@eventbrite/statsig';
import { useState } from 'react';
import { DATE_FILTER_VALUES } from '../../../../constants/dates';
import { IBrowseFilters } from '../../../../constants/filters';
import { useEnvContext } from '../../../../context';
import {
    HEAP_DATE_CUSTOM_FILTER_SELECTED,
    HEAP_DATE_FILTER_RESET,
    HEAP_DATE_TEXT_FILTER_SELECTED,
} from '../../constants/analytics';

const useBrowseFilters = (fetchEventsForTab: Function) => {
    let filters: IBrowseFilters[] = [];
    const [date, setDate] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const envContext = useEnvContext();
    const locale = envContext?.env?.localeInfo?.locale || 'en_US';

    const resetDateParams = (focusedTab: string) => {
        _updateDates({ focusedTab });
        logEvent(HEAP_DATE_FILTER_RESET);
    };

    const _updateDates = ({
        date = '',
        startDate = '',
        endDate = '',
        focusedTab = '',
    }: {
        date?: string;
        startDate?: string;
        endDate?: string;
        focusedTab?: string;
    }) => {
        setDate(date);
        setStartDate(startDate);
        setEndDate(endDate);

        if (date) {
            filters = [
                ...filters,
                { name: 'dates', value: date, payload: 'param' },
            ];
        } else if (startDate && endDate) {
            filters = [
                ...filters,
                {
                    name: 'date_range',
                    value: {
                        to: endDate,
                        from: startDate,
                    },
                    payload: 'data',
                },
            ];
        }

        fetchEventsForTab({
            locationData: {},
            tabKey: focusedTab,
            filters,
        });
    };

    const handleCustomDateSelect = (
        {
            startDate,
            endDate,
            focusedTab,
        }: {
            startDate: string;
            endDate: string;
            focusedTab: string;
        },
        callback: () => void,
    ) => {
        if (startDate && endDate) {
            _updateDates({ startDate, endDate, focusedTab });
            logEvent(HEAP_DATE_CUSTOM_FILTER_SELECTED);
            callback?.();
        }
    };

    const handleTextItemSelect = (
        { value, focusedTab }: { value: string; focusedTab: string },
        callback?: () => void,
    ) => {
        if (value === DATE_FILTER_VALUES.CUSTOM_DATE) {
            return;
        }

        logEvent(HEAP_DATE_TEXT_FILTER_SELECTED);
        _updateDates({ date: value, focusedTab });
        callback?.();
    };

    return {
        resetDateParams,
        handleCustomDateSelect,
        handleTextItemSelect,
        date,
        startDate,
        endDate,
        locale,
    };
};

export default useBrowseFilters;
