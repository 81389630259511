import React, { useContext } from 'react';

import classNames from 'classnames';

import { BREAKPOINT_SIZES, CONTROL_POSITIONS } from './constants';
import { SegmentedCarouselContext } from './context';
import styles from './SegmentedCarousel.module.scss';
import { SegmentedCarouselControls } from './SegmentedCarouselControls';

export interface SegmentedCarouselFooterProps {
    children?: React.ReactNode;
}

export const SegmentedCarouselFooter: React.FC<
    SegmentedCarouselFooterProps
> = ({ children }: SegmentedCarouselFooterProps): JSX.Element => {
    const {
        defaults,
        syncedPadding,
        controlPosition,
        footerGap,
        rootPaddingLeft,
        rootPaddingRight,
    } = useContext(SegmentedCarouselContext);
    const footerClasses = classNames(
        styles.footer,
        'segmented-carousel__footer',
    );
    const showControls = controlPosition === CONTROL_POSITIONS.BOTTOM;

    const justifyFooterContent = () => {
        if (children) {
            return 'space-between';
        }

        if (
            defaults.size === BREAKPOINT_SIZES.SM ||
            defaults.size === BREAKPOINT_SIZES.MD
        ) {
            return 'center';
        }

        return 'flex-end';
    };

    return (
        <>
            {children || showControls ? (
                <div
                    data-testid="segmented-carousel-footer"
                    className={footerClasses}
                    style={{
                        padding: `${syncedPadding}px`,
                        marginTop:
                            typeof footerGap === 'number'
                                ? `${footerGap}px`
                                : `${defaults.footerGap}px`,
                        marginLeft: rootPaddingLeft,
                        marginRight: rootPaddingRight,
                        justifyContent: justifyFooterContent(),
                    }}
                >
                    {children}
                    {showControls ? <SegmentedCarouselControls /> : null}
                </div>
            ) : null}
        </>
    );
};
