import { CURRENT_FUTURE } from '@eventbrite/discover-utils';
import { gettext } from '@eventbrite/i18n';
import difference from 'lodash/difference';
import filter from 'lodash/filter';
import find from 'lodash/find';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import maxBy from 'lodash/maxBy';
import {
    ALL_TLDs,
    PRIMARY_BUCKET,
    SECONDARY_BUCKET,
    TLD_GLOBALLY,
} from '../../../constants/constants';
import { SEASONAL_CONTENT } from '../../../constants/seasonal';
import { SEASONAL_TAB, TABS_CONFIG } from '../../../constants/tabConfig';
import { SeasonalContents } from '../../../types/index';

/**
 * An object containing seasonal content like images, texts and links to curated pages.
 * @typedef {Object} SeasonalContent
 * @property {Object} byDate - if there is any seasonal content to show by date, all the info will be here.
 * @property {Object} byPlace - if there is any seasonal content to show by place, well, you know.
 *
 * An object containing seasonal content like images, texts and links to curated pages.
 * @typedef {Object} HeaderContent
 * @property {Boolean} shouldShowCustomContent - boolean that will be used to show or not the custom content.
 * @property {Object} images - an object of images with the different breakpoints by key.
 * @property {String} attribution - the image attribution to show near the image.
 * @property {String} customContentCtaLabel - if existent, shows a custom message to be shown in the header.
 * @property {String} customCombinedHeaderTitleAndCta - if existent, combines the title and CTA into one.
 * @property {String} customContentCtaLink - if existent, the link to a custom collection of events or other similar page.
 * @property {String} customContentCtaPrefix - if existent, a prefix for the content to be added in analytics.
 * @property {String} customLinkLabel - if existent, the label of the link.
 */

/**
 * Returns a string that represents the parameter date in the format yyyy-mm-dd
 * @param  {{Date}} date
 * @return {string}
 */
const getSQLFormattedDate = (date: Date) => {
    let formattedDate = '';

    if (date instanceof Date && !isNaN(date.valueOf())) {
        const yyyy = date.getFullYear().toString();
        let mm: string | number = date.getMonth() + 1;
        let dd: string | number = date.getDate();

        /*
         * If the date is lower than 10, add a 0 to get the proper format
         * i.e. 9 returns 09
         */
        if (dd < 10) {
            dd = `0${dd}`;
        }

        /*
         * If the month is lower than 10, add a 0 to get the proper format
         * i.e. 7 returns 07
         */
        if (mm < 10) {
            mm = `0${mm}`;
        }

        /*
         * i.e. 2019-07-09
         */
        formattedDate = `${yyyy}-${mm}-${dd}`;
    }
    return formattedDate;
};

/**
 * Returns the current seasonal content available according to the start and end dates.
 * Each seasonal content has a way to disable that campaing by force
 * @param  {{integer}} placeId
 *         A number representing the place you are currently browsing.
 * @param {string} tld
 *         A string that represents tld from where you are currently browsing.
 *
 * @return {SeasonalContent}
 */
export const getSeasonalContent = ({
    placeId,
    tld,
}: { placeId?: string | number; tld?: string } = {}) => {
    const today = new Date();
    const seasonalContents: any[] = [];

    Object.keys(SEASONAL_CONTENT.default).forEach((season) => {
        const _seasonalContent = SEASONAL_CONTENT.default[season];

        const startMonth = _seasonalContent.startDate.month;
        const endMonth = _seasonalContent.endDate.month;

        const todayYear = today.getFullYear();

        //If startMonth month < end month > year changed, use today.getFullYear + 1
        const _startDateYear = _seasonalContent.startDate.year || todayYear;
        const _endDateYear = _seasonalContent.endDate.year || todayYear;

        const _startDate = new Date(
            _startDateYear,
            startMonth - 1,
            _seasonalContent.startDate.day,
        );
        const _endDate = new Date(
            _endDateYear,
            endMonth - 1,
            _seasonalContent.endDate.day,
        );

        const isAfterContentDate = today >= _startDate;
        const isBeforeContentDate = today <= _endDate;

        const _includedTLDs = _seasonalContent.tlds[0] || [];
        const _excludedTLDs = _seasonalContent.tldsExcluded || [];

        // creates a list of every TLD included on config
        const includedTLDs =
            _includedTLDs === TLD_GLOBALLY || _includedTLDs.length === 0
                ? ALL_TLDs
                : _seasonalContent.tlds;

        // Removes the ones indicated for exclude
        const validTLDs = difference(includedTLDs, _excludedTLDs);

        // check for included tld
        const isValidTLD = find(
            validTLDs,
            (validTLD) => validTLD === tld || tld === undefined,
        );

        if (
            isAfterContentDate &&
            isBeforeContentDate &&
            !_seasonalContent.forceDisable &&
            isValidTLD
        ) {
            _seasonalContent.currentStartDate = today;
            _seasonalContent.currentEndDate = _endDate;

            const seasonalContent = {
                byDate: {},
                byPlace: {},
            };
            seasonalContent.byDate = _seasonalContent;

            const seasonalContentKey = get(seasonalContent, 'byDate.key');
            const locationSpecificContent = get(
                SEASONAL_CONTENT,
                `${placeId}.${seasonalContentKey}`,
            );

            if (locationSpecificContent) {
                seasonalContent.byPlace = locationSpecificContent;
            } else {
                const defaultSeasonalContent = get(
                    SEASONAL_CONTENT,
                    `default.${seasonalContentKey}`,
                );

                if (
                    defaultSeasonalContent &&
                    defaultSeasonalContent.alwaysShow
                ) {
                    seasonalContent.byPlace = defaultSeasonalContent;
                }
            }
            seasonalContents.push(seasonalContent);
        }
    });

    return seasonalContents;
};

/**
 * Returns the tabs config for the page but inserting the seasonal content if it exists.
 * @param  {Array(SeasonalContent)} seasonalContents
 * @return {Object} tabsConfig
 *         Tabs config for the page.
 */
export const injectSeasonalTab = (
    seasonalContents: SeasonalContents = [{ byDate: {}, byPlace: {} }],
) => {
    const tabsConfig = TABS_CONFIG;

    if (!isEmpty(seasonalContents)) {
        for (
            let i = 0, len = seasonalContents.length;
            i < len && !isEmpty(seasonalContents[i].byDate);
            i++
        ) {
            const {
                byDate: {
                    key,
                    tabName,
                    eventSearchQ,
                    onlineOnly = false,
                    currentStartDate,
                    currentEndDate,
                    price,
                    currencies,
                },
            } = seasonalContents[i];

            let dateRange: string | object = '';
            const dateRangeStart = getSQLFormattedDate(
                currentStartDate || new Date(),
            );
            const dateRangeEnd = getSQLFormattedDate(
                currentEndDate || new Date(),
            );
            const eventSearch: {
                q?: string;
                dates?: string[];
                date_range?: string;
                online_events_only?: boolean;
                currencies?: string;
                price?: string;
                dateRange?: {
                    from?: string;
                    to?: string;
                };
            } = {
                q: eventSearchQ ? eventSearchQ : tabName,
                dates: [CURRENT_FUTURE],
            };

            if (!isEmpty(dateRange)) {
                eventSearch.date_range = dateRange;
            }

            if (onlineOnly) {
                eventSearch.online_events_only = onlineOnly;
            }
            if (price) {
                eventSearch.price = price;
            }
            if (currencies && isArray(currencies)) {
                eventSearch.currencies = currencies;
            }

            if (!isEmpty(dateRangeStart) && !isEmpty(dateRangeEnd)) {
                dateRange = {
                    from: dateRangeStart,
                    to: dateRangeEnd,
                };
            }

            if (key) {
                const seasonalTab = {
                    tabKeyLabel: key,
                    name: tabName,
                    eventSearch: eventSearch,
                    follow: [],
                    feedConfig: {
                        [PRIMARY_BUCKET]: {
                            order: 1,
                            propsOverwrite: {
                                name: '',
                            },
                            emptyState: [
                                {
                                    validation: {
                                        shouldShowEmptyState: true,
                                        isAuthenticated: true,
                                        useDefault: true,
                                    },
                                },
                                {
                                    validation: {
                                        shouldShowEmptyState: true,
                                        isAuthenticated: false,
                                        useDefault: true,
                                    },
                                },
                            ],
                        },
                        [SECONDARY_BUCKET]: {
                            order: 2,
                            propsOverwrite: {
                                name: gettext('More events for %(season)s', {
                                    season: tabName,
                                }),
                            },
                            emptyState: [
                                {
                                    validation: {
                                        shouldShowEmptyState: false,
                                        isAuthenticated: true,
                                    },
                                },
                                {
                                    validation: {
                                        shouldShowEmptyState: false,
                                        isAuthenticated: false,
                                    },
                                },
                            ],
                        },
                    },
                };

                tabsConfig[`${SEASONAL_TAB}_${i}`] = seasonalTab;
            }
        }
    }

    return tabsConfig;
};

/**
 * Returns the tabs config for the page but inserting the seasonal content if it exists.
 * @param  {Array(SeasonalContent)} seasonalContent
 * @return {HeaderContent} seasonalContent
 */
export const getSeasonalHeaderContent: any = (
    seasonalContents: any[] = [],
    tld = '.com',
    locationSlug = null,
) => {
    let higherPriorityHeaderContent = {};
    let seasonalContent = {};

    const getContentByDate = (
        bySomething: any,
        overridesForTld: {
            startDate: { month: number; year: number; day?: number };
            endDate: { month: number; year: number; day?: number };
        },
    ) => {
        let response = bySomething;
        const today = new Date();

        const startMonth = overridesForTld.startDate.month;
        const endMonth = overridesForTld.endDate.month;

        const todayYear = today.getFullYear();

        const _startDateYear = overridesForTld.startDate.year || todayYear;
        const _endDateYear = overridesForTld.endDate.year || todayYear;

        const _startDate = new Date(
            _startDateYear,
            startMonth - 1,
            overridesForTld.startDate.day,
        );
        const _endDate = new Date(
            _endDateYear,
            endMonth - 1,
            overridesForTld.endDate.day,
        );

        const isAfterContentDate = today >= _startDate;
        const isBeforeContentDate = today <= _endDate;

        if (isAfterContentDate && isBeforeContentDate) {
            response = {
                ...bySomething,
                ...overridesForTld,
            };
        }
        return response;
    };

    if (!isEmpty(seasonalContents)) {
        const validSeasonalContents = filter(
            seasonalContents,
            (seasonalContent: {
                alwaysShow?: boolean;
                byPlace?: { shouldShowCustomHeader?: boolean };
                byDate?: { forceDisable?: boolean };
            }) =>
                seasonalContent.alwaysShow ||
                (seasonalContent.byPlace &&
                    seasonalContent.byPlace.shouldShowCustomHeader &&
                    seasonalContent.byDate &&
                    !seasonalContent.byDate.forceDisable),
        );

        higherPriorityHeaderContent = maxBy(
            validSeasonalContents,
            (seasonalContent) => seasonalContent.byPlace.customHeaderPriority,
        );

        if (!isEmpty(higherPriorityHeaderContent)) {
            let {
                byDate,
                byPlace,
            }: {
                byPlace?: {
                    collectionLink?: string;
                    collectionPrefix?: string;
                    forceSpecialStyles?: boolean;
                    headerImagePlaceholder?: string;
                    shouldShowCustomHeader?: boolean;
                    tldOverrides?: { [key: string]: any };
                };
                byDate?: {
                    attribution?: string;
                    combinedHeaderTitleAndCta?: string;
                    cta?: string;
                    forceDisable?: boolean;
                    forceSpecialStyles?: boolean;
                    headerImages?: string[];
                    headerTitle?: string;
                    linkLabel?: string;
                    tldOverrides?: { [key: string]: any };
                    headerImagePlaceholder?: string;
                };
            } = higherPriorityHeaderContent;

            if (byDate?.tldOverrides) {
                const overridesForTld = byDate?.tldOverrides[tld];
                if (overridesForTld) {
                    if (
                        overridesForTld.isCustomCollectionLink &&
                        !isEmpty(locationSlug)
                    ) {
                        overridesForTld.collectionLink = `/d/${locationSlug}/all-events/`;
                    }
                    byDate = getContentByDate(byDate, overridesForTld);
                }
            }

            if (byPlace?.tldOverrides) {
                const overridesForTld = byPlace?.tldOverrides[tld];
                if (overridesForTld) {
                    if (
                        overridesForTld.isCustomCollectionLink &&
                        !isEmpty(locationSlug)
                    ) {
                        overridesForTld.collectionLink = `/d/${locationSlug}/all-events/`;
                    }
                    byPlace = getContentByDate(byPlace, overridesForTld);
                }
            }

            const headerImages = byDate?.headerImages;
            seasonalContent = {
                shouldShowCustomContent: byPlace?.shouldShowCustomHeader,
                headerImagePlaceholder:
                    byDate?.headerImagePlaceholder ||
                    byPlace?.headerImagePlaceholder,
                images: headerImages,
                attribution: byDate?.attribution,
                customContentCtaLabel: byDate?.cta,
                customContentTitle: byDate?.headerTitle,
                customCombinedHeaderTitleAndCta:
                    byDate?.combinedHeaderTitleAndCta,
                customContentCtaLink: byPlace?.collectionLink,
                customContentCtaPrefix: byPlace?.collectionPrefix,
                customLinkLabel: byDate?.linkLabel,
                forceSpecialStyles:
                    byDate?.forceSpecialStyles || byPlace?.forceSpecialStyles,
            };
        }
    }
    return seasonalContent;
};
