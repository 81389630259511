import { SimpleCarousel } from '@eventbrite/collection-carousel';
import { track } from '@eventbrite/datalayer-library';
import { FormattedEvent, ShareUTMOptions } from '@eventbrite/event-renderer';
import { logEvent } from '@eventbrite/statsig';
import React from 'react';

import { LocationState } from '@eventbrite/discover-utils';
import { Icon } from '@eventbrite/eds-icon';
import { TicketChunky } from '@eventbrite/eds-iconography';
import { gettext } from '@eventbrite/i18n';
import DiscoverVerticalEventCard from '../../../../components/DiscoverEventCard/DiscoverVerticalEventCard';
import {
    HOMEPAGE_EVENTS_NEAR_YOU_CAROUSEL_CTR_CLICK,
    HOMEPAGE_EVENTS_NEAR_YOU_CAROUSEL_EVENT,
} from '../../../../pages/home/constants/analytics';
import { useCategoryEventsQuery } from '../../../../pages/home/hooks/events/events';
import { EventLoadingState } from '../../../categoryBrowse/components/CategoryBrowseEventsLoading';
import styles from './EventsNearYouCarousel.module.scss';

interface EventsNearYouCarouselProps {
    isAuthenticated: boolean;
    locale: string;
    affCode: string;
    location: LocationState;
    tab: string;
}

const utmOptions: ShareUTMOptions = {
    'utm-campaign': 'social',
    'utm-content': 'attendeeshare',
    'utm-medium': 'discovery',
    'utm-term': 'listing',
    'utm-source': 'cp',
};

const renderEventLoadingState = () => {
    return (
        <div className={styles.eventLoader}>
            <EventLoadingState numberOfEvents={5} />
        </div>
    );
};

const ticketIcon = (
    <Icon type={<TicketChunky />} color="ui-700" size="medium" />
);

interface EventsNearYouDynamicCSS extends React.CSSProperties {
    '--carousel-left-margin': string;
}

const EventsQuery = (location: LocationState) =>
    useCategoryEventsQuery({
        queryOverload: {
            point_radius: {
                radius: '5mi',
                latitude: location.latitude,
                longitude: location.longitude,
            },
            page_size: 10,
            aggs: {},
            dates: ['current_future'],
            available: true,
        },
        refetchOnMount: false,
        enabled: true,
        location: location,
    });

export const EventsNearYouCarousel = (props: EventsNearYouCarouselProps) => {
    const { isAuthenticated, locale, affCode, location, tab } = props;
    const { data, isLoading } = EventsQuery(location);
    const handleNavigationClick = () => {
        logEvent(HOMEPAGE_EVENTS_NEAR_YOU_CAROUSEL_CTR_CLICK);
    };
    const isAllTab = tab === 'all';

    const componentStyle: EventsNearYouDynamicCSS = {
        '--carousel-left-margin': isAllTab ? '10px' : '0',
    };

    const Title = () => (
        <div className={styles.title}>
            <h2 className={styles.h2}>
                {!isAllTab && ticketIcon}
                {gettext('Events near you')}
            </h2>
        </div>
    );

    React.useEffect(() => {
        if (location.currentPlace) {
            track({
                eventName: 'EventsNearYouCarouselView',
                eventData: {
                    searchLocation: location.currentPlace,
                    tab: tab,
                },
            });
        }
    }, [location.currentPlace, tab]);

    if (isLoading) {
        return renderEventLoadingState();
    }

    const events: FormattedEvent[] = data?.events || [];

    // Show the carousel only if we have atleast 3 events
    if (events.length < 3) {
        return null;
    }

    return (
        <div
            className={styles.root}
            style={componentStyle}
            data-heap-id={`events-near-you-carousel-${tab}`}
            data-event-bucket-label={`events-near-you-carousel-homepage`}
        >
            <section className={styles.container}>
                <SimpleCarousel
                    headerTitle={<Title />}
                    slidesVisible={{ desktop: 4, tablet: 3 }}
                    onNavigationClick={handleNavigationClick}
                    lazilyRenderOffScreenElements={true}
                >
                    {events.map((event) => (
                        <div
                            className={styles.events}
                            data-heap-id={`homepage-events-near-you-carousel-click-event-${tab}`}
                            key={event.id}
                        >
                            <DiscoverVerticalEventCard
                                locale={locale || 'en_US'}
                                isLazyImage={true}
                                isAuthenticated={isAuthenticated || false}
                                event={event}
                                affCode={affCode}
                                statsigLocationString={
                                    HOMEPAGE_EVENTS_NEAR_YOU_CAROUSEL_EVENT
                                }
                                data-testid={`homepage-event-card-${event.id}`}
                                shareOptions={{
                                    utmOptions,
                                    trackingCategory: 'eventcard',
                                }}
                            />
                        </div>
                    ))}
                </SimpleCarousel>
            </section>
        </div>
    );
};

export default EventsNearYouCarousel;
