import { IconButton } from '@eventbrite/eds-icon-button';
import { CrossChunky } from '@eventbrite/eds-iconography';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import './Notification.scss';

interface NotificationProps {
    children: JSX.Element | string;
    containerClasses?: string;
    containerStyles?: object;
    shouldAnimate?: boolean;
    animateEntryDirection?: string;
    shouldAnimateEntry?: boolean;
    onClose?: Function;
    isNotDismissable?: boolean;
}

const Notification = (props: NotificationProps) => {
    const [isMounted, setMounted] = useState(false);
    const { animateEntryDirection = 'left' } = props;

    const classes = classNames('notification', props.containerClasses, {
        [`notification__animate-entry--${animateEntryDirection}`]:
            !!props.shouldAnimateEntry,
        'notification__animate-entry': !!props.shouldAnimateEntry,
        'notification__animate-entry--mounted':
            !!props.shouldAnimateEntry && isMounted,
    });

    useEffect(() => {
        if (props.shouldAnimateEntry) {
            setMounted(true);
        }
    }, [props.shouldAnimateEntry]);

    return (
        <div className={classes} style={{ ...props.containerStyles }}>
            {!props.isNotDismissable && (
                <div className="notification__close">
                    <IconButton
                        iconType={<CrossChunky />}
                        onClick={props.onClose}
                        iconColor="ui-500"
                        title="Close Event Notification"
                    />
                </div>
            )}
            <section className="notification__content">
                {props.children}
            </section>
        </div>
    );
};

export default Notification;
