import { SimpleCarousel } from '@eventbrite/collection-carousel';
import { FormattedEvent } from '@eventbrite/event-renderer';
import { gettext } from '@eventbrite/i18n';
import React from 'react';
import { connect } from 'react-redux';
import DiscoverVerticalEventCard from '../../../../components/DiscoverEventCard/DiscoverVerticalEventCard';
import { ConnectedProps, DiscoverState } from '../../../../types/index';
import { HOMEPAGE_DATING_CAROUSEL } from '../../constants/analytics';
import { useCategoryEventsQuery } from '../../hooks/events/events';
import './DatingCarousel.scss';
import { EventLoadingState } from './DatingCarouselLoading';
const renderEventLoadingState = () => {
    return (
        <div className="eventLoader">
            <EventLoadingState numberOfEvents={5} />
        </div>
    );
};

export const DatingCarousel = ({
    isAuthenticated,
    shareOptions,
    locale,
    location,
}: DatingCarouselProps) => {
    const DatingSubCategory = 'EventbriteSubCategory/17001';

    const { data, isLoading } = useCategoryEventsQuery({
        queryOverload: {
            page_size: 8,
            tags: [DatingSubCategory],
            dates: ['current_future'],
            online_events_excluded: true,
        },
        refetchOnMount: false,
        location: location,
        enabled: true,
    });

    if (isLoading) {
        return renderEventLoadingState();
    }

    const events: FormattedEvent[] = data?.events || [];

    if (events.length == 0) {
        return null;
    }

    return (
        <div className="dating-carousel">
            <section className="dating-carousel-container">
                <SimpleCarousel
                    headerTitle={
                        <div className="title-subtitle-container">
                            <h2>
                                {gettext('Date night')} {'\u{1FAF6}\u{1F3FD}'}
                            </h2>
                        </div>
                    }
                    slidesVisible={{ desktop: 4, tablet: 3 }}
                    lazilyRenderOffScreenElements={true}
                >
                    {events.map((event) => (
                        <div
                            className="dating-carousel-event-card"
                            key={event.id}
                        >
                            <DiscoverVerticalEventCard
                                locale={locale || 'en_US'}
                                isLazyImage={true}
                                isAuthenticated={isAuthenticated || false}
                                event={event}
                                affCode="ehometext"
                                statsigLocationString={HOMEPAGE_DATING_CAROUSEL}
                                data-testid={`home-event-card-${event.id}`}
                                shareOptions={shareOptions}
                            />
                        </div>
                    ))}
                </SimpleCarousel>
            </section>
        </div>
    );
};

const _mapStateToProps = ({
    app,
    user,
    location,
    shareOptions,
}: DiscoverState) => {
    return {
        isAuthenticated: user.isAuthenticated,
        location: location,
        shareOptions: shareOptions,
        locale: app.locale,
    };
};

type DatingCarouselProps = ConnectedProps<typeof _mapStateToProps>;
export default connect(_mapStateToProps)(DatingCarousel);
