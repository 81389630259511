import { interleaveTwoArrays } from '@eventbrite/ads';
import {
    bboxShape,
    DESTINATION_CITY_BROWSE_SLA_MILLISECONDS,
    DESTINATION_RECOMMENDATIONS_SLA_MILLISECONDS,
    Location,
} from '@eventbrite/discover-utils';
import { sdkRequest } from '@eventbrite/http';
import { formatUrl } from 'url-lib';
import { searchAllEvents } from '../../../../api/searchAllEvents';
import { searchOrganicEvents } from '../../../../api/searchOrganicEvents';
import { DEFAULT_DESTINATION_EVENT_EXPANSIONS } from '../../../../constants';
import { IBrowseFilters } from '../../../../constants/filters';
import { bboxToUrlParam } from '../../../../utils/location';
import { searchPromotedEventsForYou } from './promoted';
import { PersonalizedContent } from './types';

export const getUserSavedEvents = () => {
    const url = formatUrl('/api/v3/destination/saved/', {
        'expand.destination_event': DEFAULT_DESTINATION_EVENT_EXPANSIONS,
        page_size: 4,
    });

    return sdkRequest(url);
};

/**
 * This method returns events from organizers the user is following.
 */
export const getFollowedProfiles = () => {
    const maxEvents = 16;

    return searchOrganicEvents({
        event_search: {
            dates: ['current_future'],
            page: 1,
            image: true,
            special: ['organizers_i_follow'],
            sort: 'date',
            page_size: maxEvents,
        },
        'expand.destination_event': DEFAULT_DESTINATION_EVENT_EXPANSIONS,
        browse_surface: 'homepage',
    });
};

// This endpoint returns organizers the users follows
export const getUserFollowing = (userId: string) => {
    const url = formatUrl(`/api/v3/destination/users/${userId}/following/`);

    return sdkRequest(url);
};

/*
 * Users can optionally apply filters (ie. Date) to personalized content.
 * buildPayload parses the filters into either data (filterData) or
 * payload params (filterParams) for the recommendations API endpoint
 */

const buildPayload = (filters?: IBrowseFilters[]) => {
    const payload = filters?.reduce(
        (prev, curr) => {
            let filterParams = {};
            let filterData = {};

            if (curr.payload === 'param') {
                filterParams = {
                    ...prev.filterParams,
                    [curr.name as string]: curr.value,
                };
            } else if (curr.payload === 'data') {
                filterData = {
                    ...prev.filterData,
                    [curr.name as string]: curr.value,
                };
            }
            return {
                filterParams,
                filterData,
            };
        },
        {
            filterParams: {},
            filterData: {},
        },
    );

    return payload;
};

function buildUrlParams(locale: string | null, filters: object[]): string {
    const EVENT_EXPANSIONS =
        'event_sales_status,image,primary_venue,saves,series,ticket_availability,primary_organizer';
    const PROFILE_EXPANSIONS = 'image';

    const params = {
        'expand.destination_event': EVENT_EXPANSIONS,
        'expand.destination_profile': PROFILE_EXPANSIONS,
        locale,
    };

    const payload = buildPayload(filters);
    return formatUrl(
        '/api/v3/destination/users/me/recommendations/my_feed_recommendations_all/',
        {
            ...params,
            ...payload?.filterParams,
        },
    );
}

function getPostData(filters: object[]): object {
    const payload = buildPayload(filters);
    if (payload?.filterData && JSON.stringify(payload.filterData) !== '{}') {
        return {
            method: 'POST',
            body: JSON.stringify(payload.filterData),
        };
    }
    return {};
}

const BUCKETS_WITH_PROMOTED_EVENTS = [
    'holistic_inperson_events',
    'user_recent_searches_inperson_events',
    'user_interactions_liked_inperson',
    'user_interactions_viewed_inperson',
    'user_interests_inperson_events',
    'user_interactions_orders_inperson',
];

export async function getPromotedEvents(
    personalizedContent: PersonalizedContent,
    location: Location,
) {
    const promotedEventsPromises = BUCKETS_WITH_PROMOTED_EVENTS.map(
        async (currentBucketKey) => {
            const bucket = personalizedContent.buckets.find(
                (bucket) => bucket.key === currentBucketKey,
            );

            if (!bucket) {
                return { events: [], bucketKey: currentBucketKey };
            }

            const numberOfEvents = bucket.events?.length || 0;
            const promotedEventsPayload = {
                location,
                subInterface: currentBucketKey,
                numberOfEvents,
            };
            const events = await searchPromotedEventsForYou(
                promotedEventsPayload,
            );
            return { events, bucketKey: currentBucketKey };
        },
    );

    return await Promise.all(promotedEventsPromises);
}

export function combineEventsWithAds(
    personalizedContent: PersonalizedContent,
    allPromotedEvents: { bucketKey: string; events: any[] }[],
) {
    return personalizedContent.buckets.map((bucket) => {
        const adsBucket = allPromotedEvents.find(
            ({ bucketKey }) => bucketKey === bucket.key,
        );
        const ads = adsBucket?.events || [];
        return {
            ...bucket,
            events: combineBucketEventsWithAds(
                bucket.key,
                bucket.events || [],
                ads,
            ),
        };
    });
}

function combineBucketEventsWithAds(
    bucketKey: string,
    organicEvents: any[],
    promotedEvents: any[],
) {
    return bucketKey === 'holistic_inperson_events'
        ? interleaveTwoArrays(
              organicEvents,
              promotedEvents,
              getTopPicksForYouPositions(promotedEvents),
          )
        : [...promotedEvents, ...organicEvents];
}

const getTopPicksForYouPositions = (promotedEvents: any[]) =>
    Array.from({ length: promotedEvents.length }, (_, i) => 1 + i * 3);

// This endpoint returns events and organizers personalized for the user
export const getUserPersonalizedContent = async (
    locale: string | null,
    filters: object[],
    location: Location,
) => {
    const url = buildUrlParams(locale, filters);
    const postData = getPostData(filters);
    const controller = new AbortController();
    const id = setTimeout(() => {
        controller.abort();
    }, DESTINATION_RECOMMENDATIONS_SLA_MILLISECONDS);

    try {
        const personalizedContent = await retry<any>(
            () => sdkRequest(url, { ...postData, signal: controller.signal }),
            { retriesLeft: 1 },
        );

        const allPromotedEvents = await getPromotedEvents(
            personalizedContent,
            location,
        );
        const bucketsWithAds = combineEventsWithAds(
            personalizedContent,
            allPromotedEvents,
        );

        return {
            ...personalizedContent,
            buckets: bucketsWithAds,
        };
    } catch (e) {
        console.error('"For you" API call was not able to load');
        throw e;
    } finally {
        clearTimeout(id);
    }
};

// This endpoint returns personalized event recommendations
export const getUserEventRecommendations = async () => {
    const recommendedEventIds = await sdkRequest(
        '/api/v3/destination/users/me/recommendations/my_offline_events_recommendation/',
    );
    let recommendedEvents = [];

    if (recommendedEventIds.my_offline_events_recommendation.length) {
        const inflateUrl = formatUrl(`/api/v3/destination/events/`, {
            'expand.destination_event': DEFAULT_DESTINATION_EVENT_EXPANSIONS,
            event_ids:
                recommendedEventIds.my_offline_events_recommendation.join(','),
            browse_surface: 'homepage_for_you',
        });
        recommendedEvents = await sdkRequest(inflateUrl);
    }

    return recommendedEvents;
};

export const fetchCityBrowseEvents = (placeId: string) => {
    const url = formatUrl(`/api/v3/destination/city-browse/${placeId}/`, {
        'expand.destination_event': DEFAULT_DESTINATION_EVENT_EXPANSIONS,
        buckets_list: 'best_of_city_events',
    });
    const controller = new AbortController();
    const id = setTimeout(() => {
        controller.abort();
    }, DESTINATION_CITY_BROWSE_SLA_MILLISECONDS);

    try {
        return sdkRequest(url, { signal: controller.signal });
    } catch (e) {
        console.error(e.response);
        throw e.response;
    } finally {
        clearTimeout(id);
    }
};

export const searchEventsBy = (placeId: string) => {
    return searchOrganicEvents({
        event_search: {
            places: [placeId],
            dates: ['current_future'],
            page_size: 30,
            image: true,
        },
        'expand.destination_event': DEFAULT_DESTINATION_EVENT_EXPANSIONS,
        browse_surface: 'homepage',
    });
};

export const searchEventsWithParameters = ({
    placeId,
    bbox,
    eventSearch,
    isOnline,
    slots = 1,
}: {
    placeId: string;
    bbox: bboxShape;
    eventSearch: {
        dates: string[];
        tags: string;
    };
    isOnline: boolean;
    slots?: number;
}) => {
    let searchOptions: any = {
        places: [placeId],
    };

    // sometimes google maps autocomplete returns with a lat lng that we can't
    // translate to a place id. In that case, we resort to sending a bounding box.
    if (!placeId && bbox) {
        searchOptions = {
            bbox: bboxToUrlParam(bbox),
        };
    }

    // Let's default to an online search if place or bbox still are not present.
    if (isOnline || (!placeId && !bbox)) {
        searchOptions = {
            online_events_only: true,
        };
    }
    return searchAllEvents({
        interfaceName: 'homepage',
        slots,
        event_search: {
            page_size: 30,
            image: true,
            ...searchOptions,
            ...eventSearch,
        },
        'expand.destination_event': DEFAULT_DESTINATION_EVENT_EXPANSIONS,
        browse_surface: 'homepage',
    });
};

export async function retry<T>(
    fn: () => Promise<T>,
    {
        retriesLeft = 3,
        interval = 200,
        exponential = true,
    }: { retriesLeft?: number; interval?: number; exponential?: boolean } = {},
): Promise<T> {
    try {
        const val = await fn();
        return val;
    } catch (error) {
        if (retriesLeft) {
            await new Promise((r) => setTimeout(r, interval));
            return retry(fn, {
                retriesLeft: retriesLeft - 1,
                interval: exponential ? interval * 2 : interval,
                exponential,
            });
        } else throw new Error(`Max retries reached for function ${fn.name}`);
    }
}
