import { useQuery } from '@tanstack/react-query';
import { recommendedInterests } from './apis';

export const useRecommendedInterestsQuery = () => {
    return useQuery(['recommendedInterests'], async () => {
        const resp = await recommendedInterests();
        const formattedInterests =
            resp?.my_suggested_interests_recommendation?.map?.(
                (interest: any) => ({
                    id: interest.tag,
                    displayName: interest.display_name,
                    isSelected: false,
                }),
            );
        return formattedInterests;
    });
};
