import React, { useContext } from 'react';

import classNames from 'classnames';

import { CONTROL_POSITIONS } from './constants';
import { SegmentedCarouselContext } from './context';
import styles from './SegmentedCarousel.module.scss';
import { SegmentedCarouselControls } from './SegmentedCarouselControls';

export interface SegmentedCarouselHeaderProps {
    children: React.ReactNode;
}

export const SegmentedCarouselHeader: React.FC<
    SegmentedCarouselHeaderProps
> = ({ children }: SegmentedCarouselHeaderProps): JSX.Element => {
    const {
        defaults,
        syncedPadding,
        controlPosition,
        headerGap,
        rootPaddingLeft,
        rootPaddingRight,
    } = useContext(SegmentedCarouselContext);
    const headerClasses = classNames(
        styles.header,
        'segmented-carousel__header',
    );
    const showControls = controlPosition === CONTROL_POSITIONS.TOP;

    return (
        <div
            data-testid="segmented-carousel-header"
            className={headerClasses}
            style={{
                padding: `${syncedPadding}px`,
                marginBottom:
                    typeof headerGap === 'number'
                        ? `${headerGap}px`
                        : `${defaults.headerGap}px`,
                marginLeft: rootPaddingLeft,
                marginRight: rootPaddingRight,
            }}
        >
            <div className={styles.headerFirstChildren}>{children}</div>
            {showControls ? <SegmentedCarouselControls /> : null}
        </div>
    );
};
