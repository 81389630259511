import { GenericLazyString } from '@eventbrite/i18n';
import React from 'react';
import CONSTANTS from '../../common/default.constants';

import './Tag.scss';

export interface TagProps {
    /**
     * Display Text for the Tag
     */
    displayText: string | GenericLazyString;
    /**
     * URL to which the Tag should link to
     */
    linkUrl: string;
    /**
     * Background Color of the Tag
     * Default: Grey [light]/ui-100
     */
    backgroundColor?: string;
    /**
     * Background Color of the Tag on hover
     * Default: Grey [light]/ui-200
     */
    hoverColor?: string;
    /**
     * Icon to be displayed beside Tag label
     * Default: Arrow-up-right (↗)
     */
    icon?: JSX.Element | React.ReactNode;
}

/**
 * Tag Component used to display a label with a link attached to it.
 * It supports custom background color and icon.
 */
export const Tag: React.FunctionComponent<TagProps> = ({
    displayText,
    linkUrl,
    backgroundColor = CONSTANTS.DEFAULT_TAG_BACKGROUND_COLOR,
    hoverColor = CONSTANTS.DEFAULT_TAG_HOVER_COLOR,
    icon = CONSTANTS.DEFAULT_ICON,
}) => {
    return (
        <div
            className="infra-tag"
            style={{ backgroundColor }}
            onMouseEnter={(e) =>
                (e.currentTarget.style.backgroundColor = hoverColor)
            }
            onMouseLeave={(e) =>
                (e.currentTarget.style.backgroundColor = backgroundColor)
            }
        >
            <a href={linkUrl} className="infra-tag__link">
                {displayText}
            </a>
            <a
                href={linkUrl}
                aria-label={`Explore ${displayText}`}
                className="infra-tag__icon"
            >
                {icon}
            </a>
        </div>
    );
};
