import { $FixMe } from '@eventbrite/ts-utils';
import times from 'lodash/times';
import React from 'react';
import { EVENT_TYPE, LoadingCards } from '../../../../components/LoadingCards';

const LoadingBucket: $FixMe = () =>
    times(4, (index) => (
        <div
            className="eds-g-cell eds-g-cell--has-overflow eds-g-cell-1-1 eds-g-cell-sw-6-12 eds-g-cell-mn-4-12 eds-g-cell-lg-3-12 feed__card-cell"
            key={`loading-card-${index}`}
        >
            <div className="eds-show-down-sm">
                {index === 0 ? (
                    <LoadingCards
                        type={EVENT_TYPE}
                        style="grid"
                        cardCount={1}
                    />
                ) : (
                    <LoadingCards
                        type={EVENT_TYPE}
                        style="list-square"
                        cardCount={1}
                    />
                )}
            </div>
            <div className="eds-show-up-sw">
                <LoadingCards type={EVENT_TYPE} style="grid" cardCount={1} />
            </div>
        </div>
    ));

export default LoadingBucket;
