import flatten from 'lodash/flatten';
import {
    GA_HOME_FEED_CATEGORY,
    GA_SHOW_ORGANIZERS_ACTION,
    GA_VIEW_ORGANIZER_PROFILE_ACTION,
} from '../../../../constants/analytics';
import { SET_ORGANIZERS, VIEW_ORGANIZER } from './actionTypes';

export const setOrganizers = (data: object) => ({
    type: SET_ORGANIZERS,
    payload: data,
    meta: {
        analytics: {
            action: GA_SHOW_ORGANIZERS_ACTION,
            label: flatten(Object.values(data)).length,
            category: GA_HOME_FEED_CATEGORY,
        },
    },
});

export const viewOrganizerProfile =
    (organizerId: string, label: string) => (dispatch: Function) =>
        dispatch({
            type: VIEW_ORGANIZER,
            meta: {
                analytics: {
                    action: GA_VIEW_ORGANIZER_PROFILE_ACTION,
                    label: label || organizerId,
                    category: GA_HOME_FEED_CATEGORY,
                },
            },
        });

export const ADD_ORGANIZER_USER_FOLLOW = 'ADD_ORGANIZER_USER_FOLLOW';

export const addOrganizersUsersFollow = (payload: object) => ({
    type: ADD_ORGANIZER_USER_FOLLOW,
    payload,
});

export const SET_ORGANIZERS_USER_FOLLOW = 'SET_ORGANIZERS_USER_FOLLOW';
