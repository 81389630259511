import { eventsDuck } from '@eventbrite/redux-destination';
import { transformUtil } from '@eventbrite/transformation-utils';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import { arrayOf, Schema } from 'normalizr';
import { ENTITY_TYPE_MAP, EVENT_CARD_TYPE } from '../../../constants';
import { Bucket, EntityContext } from '../../../types';

const {
    utils: { formatTransformedEventsWithCollection },
} = eventsDuck;

export const getTransformedContent = (key: string, response: any) => {
    /* Due to how the normalizr library is handling this instantiation
    server side, necessary to do an additional interpolation step. */
    const content = new Schema(`${key}`);

    return transformUtil({
        response,
        schema: {
            [key]: arrayOf(content),
        },
    });
};

export const transformBucketsContent = (buckets: Bucket[]) => {
    let entities = {};

    let entityContext: EntityContext | EntityContext[] = [];

    if (!isEmpty(buckets)) {
        entityContext = buckets.map((bucket) => {
            const typeKey = ENTITY_TYPE_MAP[bucket.type];

            const { result, entities: parsedEntities } = getTransformedContent(
                typeKey,
                bucket,
            );

            let newEntities = parsedEntities[typeKey];

            // check Type value. If event call the formatter. Can't do it for articles etc
            if (bucket.type === EVENT_CARD_TYPE) {
                newEntities = formatTransformedEventsWithCollection(
                    newEntities,
                    result[typeKey],
                );
            }

            entities = { ...entities, ...newEntities };

            return {
                ...result,
                [typeKey]: uniq(result[typeKey]),
            };
        });
    }

    return { entities, entityContext };
};

export const transformSearchContent = (response: any) => {
    const type = ENTITY_TYPE_MAP[EVENT_CARD_TYPE];

    if (type in response) {
        const {
            result: { results: ids },
            entities: { results: entities },
        } = getTransformedContent('results', response[type]);

        const newEntities = formatTransformedEventsWithCollection(
            entities,
            ids,
        );

        return {
            ids,
            entities: newEntities,
        };
    }

    return {};
};
