import React, { useEffect, useState } from 'react';

export const HydratedDiv: React.VFC = () => {
    const [isHydrated, setIsHydrated] = useState(false);

    useEffect(() => {
        setIsHydrated(true);
    }, []);

    return (
        <div
            data-testid="hydrated-div"
            style={{ display: 'none' }}
            data-hydrated={isHydrated}
        />
    );
};
