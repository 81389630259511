import { Location } from '@eventbrite/discover-utils';
import { Divider } from '@eventbrite/eds-divider';
import { Layout } from '@eventbrite/eds-layout';
import { logEvent } from '@eventbrite/statsig';
import classNames from 'classnames';
import find from 'lodash/find';
import React, { Component } from 'react';
import { DateFilter } from '../../../../constants/dates';
import { HEAP_TAB_CLICK } from '../../constants/analytics';
import TabItem from './TabItem';
import './tabs.scss';
import { getPanelId, getTabId } from './util';

interface TabContentProps {
    focusedTab?: string;
    items: {
        value: number;
        displayName?: React.ReactNode;
        content?: JSX.Element;
        isSelected?: boolean;
        tabKey?: string;
    }[];
}

const TabContent = ({ focusedTab, items }: TabContentProps) => {
    const _focusedTab = find(items, { tabKey: focusedTab });

    if (_focusedTab) {
        const { value, content } = _focusedTab;

        return (
            <div>
                <div
                    id={getPanelId(value)}
                    className={`eds-tabs__content eds-tabs__content-${focusedTab}`}
                    role="region"
                    aria-live="polite"
                    aria-labelledby={getTabId(value)}
                >
                    {content}
                </div>
            </div>
        );
    }

    return null;
};

interface TabsProps {
    /**
     * items: List of items to display in the tab list.
     * Accepts value, displayName, and content
     */
    items: {
        value: number;
        displayName?: React.ReactNode;
        content?: JSX.Element;
        isSelected?: boolean;
        tabKey?: string;
    }[];
    /**
     * onTabChange: Callback function to be invoked on tab change
     */
    onTabChange?: Function;
    /**
     * onTabLoad: Callback function to be invoked on tab load
     */
    onTabLoad?: Function;
    /**
     * onLocationChange: Callback function to be invoked on location change
     */
    onLocationChange?: Function;
    /**
     * initialSelectedTab: Initial selected tabKey
     */
    initialSelectedTab?: string;
    /**
     * location: location object
     */
    location?: Location;
    dateFilter?: DateFilter;
}

interface TabsState {
    focusedTab?: string;
}
class Tabs extends Component<TabsProps, TabsState> {
    constructor(props: Readonly<TabsProps>) {
        super(props);

        this.state = {
            focusedTab: props.initialSelectedTab,
        };
    }

    componentDidMount() {
        const { initialSelectedTab, onTabLoad } = this.props;
        onTabLoad?.(initialSelectedTab);
    }

    componentDidUpdate(prevProps: Readonly<TabsProps>) {
        const { location: prevLocation } = prevProps;
        const {
            location,
            onLocationChange: onLocationChange,
            initialSelectedTab,
        } = this.props;
        const hasSwitchedLocations =
            prevLocation?.slug !== location?.slug ||
            prevLocation?.latitude !== location?.latitude;

        if (hasSwitchedLocations) {
            const _handleLocationChange = () => {
                if (onLocationChange && location) {
                    onLocationChange(initialSelectedTab);
                }
            };

            this.setState(
                {
                    focusedTab: this.props.initialSelectedTab,
                },
                _handleLocationChange,
            );
        }
    }

    _handleSelected(tabKey: string) {
        const { onTabChange } = this.props;
        const { focusedTab } = this.state;

        this.setState({ focusedTab: tabKey });

        if (onTabChange && focusedTab !== tabKey) {
            onTabChange(tabKey);
        }

        logEvent(HEAP_TAB_CLICK, null, { tab: tabKey });
    }

    _getTabItems() {
        const { items } = this.props;
        const { focusedTab } = this.state;

        const tabItems = items.map(({ value, displayName, tabKey }) => (
            <TabItem
                key={tabKey}
                value={value}
                onSelected={this._handleSelected.bind(this, tabKey || '')}
                displayName={displayName}
                isSelected={tabKey === focusedTab}
            />
        ));

        return tabItems;
    }

    render() {
        const { items } = this.props;
        const { focusedTab } = this.state;
        const tabItems = this._getTabItems();
        const listClassNames = classNames('eds-tabs__navigation-list', {
            'eds-tabs__navigation-list--logged-in': items && items.length > 7,
        });
        const navClassnames = classNames(
            'eds-tabs__navigation eds-l-pad-vert-3 eds-l-mar-bot-3 eds-tab__navigation--animate-in',
        );

        return (
            <div className="eds-tabs">
                <Layout hasHorizontalGutters={true} maxWidth="large">
                    <nav className={navClassnames}>
                        <ul className={listClassNames}>
                            {tabItems}
                            <Divider />
                        </ul>
                    </nav>
                </Layout>
                <TabContent focusedTab={focusedTab} items={items} />
            </div>
        );
    }
}

export default Tabs;
