import {
    PromotedActionsMenuEventCard,
    PromotedActionsMenuEventCardProps,
} from '@eventbrite/ads-event-card-extra';
import { FormattedEvent, ShareUTMOptions } from '@eventbrite/event-renderer';
import { logEvent } from '@eventbrite/statsig';
import React from 'react';
import { useList } from 'react-use';
import DiscoverVerticalEventCard from '../../../../components/DiscoverEventCard/DiscoverVerticalEventCard';
import { InterestsSelectorContainer } from '../../containers/InterestsSelectorContainer';
import { removeEvent } from './api';
import './ConsumerCards.scss';
import { ConsumerCardsActions } from './ConsumerCardsActions';

type ConsumerCardsProps = Omit<
    EventCardGroupProps,
    'events' | 'containerClasses' | 'shareOptions'
> & {
    headerTitle?: JSX.Element;
    bucketLayoutConfig?: { [key: string]: any };
    eventGroups?: EventGroup[];
    enableInterestsSelector?: boolean;
    isForYouTabSelected?: boolean;
};

interface EventGroup {
    events: FormattedEvent[];
    containerClasses: string;
}

function ConsumerCards({
    // TODO: update api to handle passing custom tracking labels
    headerTitle,
    bucketLayoutConfig,
    isAuthenticated,
    enableInterestsSelector,
    eventGroups = [],
    ...props
}: ConsumerCardsProps) {
    const bucketClassNames =
        (bucketLayoutConfig && bucketLayoutConfig.bucketClassNames) || '';

    return (
        <div className={bucketClassNames}>
            {headerTitle}
            <div className="feed-events-bucket__content__cards-container">
                {eventGroups.map(({ events, containerClasses }) => (
                    <EventCardGroup
                        key={events[0]?.id}
                        events={events}
                        containerClasses={containerClasses}
                        isAuthenticated={isAuthenticated}
                        {...props}
                    />
                ))}
            </div>

            {enableInterestsSelector && (
                <InterestsSelectorContainer
                    key="interests-selector"
                    isAuthenticated={isAuthenticated}
                />
            )}
        </div>
    );
}

export type EventCardGroupProps = EventGroup &
    Pick<PromotedActionsMenuEventCardProps, 'getTrackingContext'> & {
        bucketLabel?: string;
        isFreeTab?: boolean;
        statsigLocationString?: string;
        affCode?: string;
        locale: string;
        isAuthenticated?: boolean;
    };

const utmOptions: ShareUTMOptions = {
    'utm-campaign': 'social',
    'utm-content': 'attendeeshare',
    'utm-medium': 'discovery',
    'utm-term': 'listing',
    'utm-source': 'cp',
};

const shareOptions = { utmOptions };

function EventCardGroup({
    events: rawEvents,
    statsigLocationString,
    containerClasses,
    locale,
    isFreeTab,
    affCode = 'ebhometext',
    bucketLabel,
    isAuthenticated,
    getTrackingContext,
}: EventCardGroupProps) {
    const { events, hideEvent } = useConsumerCardEvents(rawEvents);
    const { onRemoveEventTrack, onShareItemClickTrack } =
        useConsumerCardTracking(statsigLocationString);

    return (
        <>
            {events.map((event) => {
                const organicEventActions = isAuthenticated && (
                    <ConsumerCardsActions
                        event={event}
                        onRemoveEvent={() => {
                            hideEvent(event.id);
                            removeEvent(event.id);
                            onRemoveEventTrack();
                        }}
                    />
                );

                const promotedEventActions = (
                    <PromotedActionsMenuEventCard
                        event={event}
                        onRemoveEvent={hideEvent}
                        getTrackingContext={getTrackingContext}
                    />
                );

                return (
                    <div className={`${containerClasses}`} key={event.id}>
                        <DiscoverVerticalEventCard
                            locale={locale || 'en_US'}
                            statsigLocationString={statsigLocationString}
                            isAuthenticated={isAuthenticated}
                            event={event}
                            isLazyImage
                            hidePriceDescription={isFreeTab}
                            affCode={affCode}
                            data-testid={`home-event-card-${event.id}`}
                            bucketLabel={bucketLabel}
                            onShare={onShareItemClickTrack}
                            shareOptions={shareOptions}
                            moreActions={
                                event.isPromoted
                                    ? promotedEventActions
                                    : organicEventActions
                            }
                        />
                    </div>
                );
            })}
        </>
    );
}

function useConsumerCardEvents(rawEvents: FormattedEvent[]) {
    // TODO: For some reason, in EB-UI react-use does not have types available
    const [events, { filter }]: [FormattedEvent[], { filter: Function }] =
        useList(rawEvents);

    const hideEvent = (eventId: string) => {
        filter((event: FormattedEvent) => event.id !== eventId);
    };

    return { events, hideEvent };
}

function useConsumerCardTracking(statsigLocationString: string | undefined) {
    const onRemoveEventTrack = () => {
        statsigLocationString &&
            logEvent(`${statsigLocationString} - Click - Remove Event`);
    };

    const onShareItemClickTrack = () => {
        statsigLocationString &&
            logEvent(`${statsigLocationString} - Click - Share Event`);
    };

    return {
        onRemoveEventTrack,
        onShareItemClickTrack,
    };
}

export default ConsumerCards;
