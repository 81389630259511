import * as React from 'react';

const CircleFillSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="circle-fill_svg__eds-icon--circle-fill_svg"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <path
            id="circle-fill_svg__eds-icon--circle-fill_base"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2"
        />
    </svg>
);

CircleFillSvg.displayName = 'CircleFillSvg';
export default CircleFillSvg;
