import React from 'react';
import './IconCategory.scss';
import { WrapperProps } from './types';

export const IconCategoryWrapper = <T,>(
    props: WrapperProps<T>,
): JSX.Element => {
    const { cards, children, noScrollMobile } = props;
    return (
        <div
            className={`iconCategoryWrapper ${
                noScrollMobile ? 'noScroll' : ''
            }`}
        >
            {cards.map((cards, index) => children(cards, index))}
        </div>
    );
};
