import { SimpleCarousel } from '@eventbrite/collection-carousel';
import { track } from '@eventbrite/datalayer-library';
import { FormattedEvent } from '@eventbrite/event-renderer';
import { gettext } from '@eventbrite/i18n';
import { LoadingSkeleton } from '@eventbrite/loading-skeleton';
import { logEvent } from '@eventbrite/statsig';
import React from 'react';
import { connect } from 'react-redux';
import DiscoverVerticalEventCard from '../../../../components/DiscoverEventCard/DiscoverVerticalEventCard';
import { ConnectedProps, DiscoverState } from '../../../../types/index';
import {
    HOMEPAGE_SEASONAL_CTR_CLICK,
    HOMEPAGE_SEASONAL_FEATURED,
} from '../../constants/analytics';
import { SEASONAL_CAROUSEL_DATA } from '../../constants/category';
import { useCategoryEventsQuery } from '../../hooks/events/events';
import './SeasonalCarousel.scss';
import { EventLoadingState } from './SeasonalCarouselLoading';

export enum NavDirections {
    Left = 'left',
    Right = 'right',
}
const renderLoadingSkeleton = (isMobile: boolean) => {
    return (
        <div className="loader">
            <LoadingSkeleton height={isMobile ? '400px' : '426px'} />
        </div>
    );
};

const renderEventLoadingState = () => {
    return (
        <div className="eventLoader">
            <EventLoadingState numberOfEvents={5} />
        </div>
    );
};

interface SeasonalCarouselProps extends ReduxProps {
    season: string;
}

export const SeasonalCarousel = ({
    isAuthenticated,
    shareOptions,
    locale,
    location,
    season,
    isMobile = false,
}: SeasonalCarouselProps) => {
    const [pageSize, setPageSize] = React.useState(1);
    const [pageIndex, setPageIndex] = React.useState(1);
    const { title, titleCSS, heapId, affCode, queryParams } =
        SEASONAL_CAROUSEL_DATA[season];
    queryParams.page = pageIndex;
    const { data, isLoading } = useCategoryEventsQuery({
        queryOverload: queryParams,
        refetchOnMount: false,
        location: location,
        enabled: true,
    });
    const moduleIndex = isMobile ? 40 : 10;
    React.useEffect(() => {
        if (location && location.currentPlace) {
            track({
                eventName: 'SeasonalCarouselView',
                eventData: {
                    searchLocation: location.currentPlace,
                },
            });
        }
    }, [location]);

    if (isLoading && pageIndex === 1) {
        return renderLoadingSkeleton(isMobile);
    } else if (isLoading) {
        return renderEventLoadingState();
    }

    const events: FormattedEvent[] = data?.events || [];

    if (events.length == 0) {
        return null;
    }

    const pageCount = data?.pagination?.page_count || 1;

    const handleNavigationClick = (direction: string) => {
        if (direction === 'left' && (pageSize - 1) % moduleIndex == 0) {
            setPageSize(1);
            setPageIndex(pageIndex - 1);
        } else if (direction === 'right' && pageSize % moduleIndex == 0) {
            setPageSize(1);
            setPageIndex(pageIndex + 1);
        } else {
            direction === 'left'
                ? setPageSize(pageSize - 1)
                : setPageSize(pageSize + 1);
        }
        logEvent(HOMEPAGE_SEASONAL_CTR_CLICK);
    };

    return (
        <div className="seasonal-carousel" data-heap-id={heapId}>
            <section className="seasonal-carousel-container">
                <SimpleCarousel
                    headerTitle={
                        <div className="title-subtitle-container">
                            <h2 className={`${titleCSS}`}>
                                {gettext('%(title)s ', { title })}
                            </h2>
                        </div>
                    }
                    slidesVisible={{ desktop: 4, tablet: 3 }}
                    onNavigationClick={handleNavigationClick}
                    lazilyRenderOffScreenElements={true}
                    fullyEnableNavigation={true}
                    isFirstPage={pageIndex === 1}
                    isLastPage={pageIndex === pageCount}
                >
                    {events.map((event) => (
                        <div
                            className="seasonal-carousel-event-card"
                            data-heap-id={`${heapId}-click-event`}
                            key={event.id}
                        >
                            <DiscoverVerticalEventCard
                                locale={locale || 'en_US'}
                                isLazyImage={true}
                                isAuthenticated={isAuthenticated || false}
                                event={event}
                                affCode={affCode}
                                statsigLocationString={
                                    HOMEPAGE_SEASONAL_FEATURED
                                }
                                data-testid={`home-event-card-${event.id}`}
                                shareOptions={shareOptions}
                            />
                        </div>
                    ))}
                </SimpleCarousel>
            </section>
        </div>
    );
};

const _mapStateToProps = ({
    app,
    user,
    location,
    shareOptions,
}: DiscoverState) => {
    return {
        isMobile: app.isMobile,
        isAuthenticated: user.isAuthenticated,
        location: location,
        shareOptions: shareOptions,
        locale: app.locale,
    };
};

type ReduxProps = ConnectedProps<typeof _mapStateToProps>;
export default connect(_mapStateToProps)(SeasonalCarousel);
