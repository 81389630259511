import {
    DestinationEvent,
    FormattedEvent,
    transformDestinationEvent,
} from '@eventbrite/event-renderer';
import { sdkRequest } from '@eventbrite/http';
import { Branding } from '../../../types';

const POPULAR_EVENTS_BUCKET_KEY = 'popular_events';

type PopularEventsResponseBody = {
    description: string;
    branding: Branding;
    buckets?: [PopularEventsBucket];
    more_buckets: boolean;
    search_id: string;
};

type PopularEventsBucket = {
    key_min: string;
    name: string;
    see_more: {
        url: string;
        params: {
            dates: string[];
            palces: string[];
        };
    };
    key: string;
    type: string;
    template: string;
    events?: DestinationEvent[];
};

const EMPTY_RESPONSE = Promise.resolve([]);

/**
 * Fetch just the `popular_events` bucket for the given Place ID.
 *
 * @param placeId the place id for which to fetch popular events for
 */
export const getPopularCityEvents = async (
    placeId: string,
    signal?: AbortSignal,
): Promise<FormattedEvent[]> => {
    try {
        if (!placeId) {
            return EMPTY_RESPONSE;
        }

        const requestBody = {
            place_id: placeId,
            online_events_only: false,
            buckets_list: ['popular_events'],
            omit_event_description: true,
            'expand.destination_event': [
                'event_sales_status',
                'image',
                'primary_venue',
                'saves',
                'series',
                'ticket_availability',
                'primary_organizer',
            ],
        };

        const responseBody: PopularEventsResponseBody = await sdkRequest(
            '/api/v3/destination/city-browse/',
            {
                method: 'POST',
                signal,
                body: JSON.stringify(requestBody),
            },
        );

        const popularEvents = (responseBody?.buckets || []).find(
            (bucket) => bucket.key === POPULAR_EVENTS_BUCKET_KEY,
        )?.events as DestinationEvent[];

        if (popularEvents) {
            const formattedEvents = popularEvents.map((event) =>
                transformDestinationEvent(event),
            );
            return Promise.resolve(formattedEvents);
        }
        return EMPTY_RESPONSE;
    } catch (e) {
        return EMPTY_RESPONSE;
    }
};
