import { $FixMe } from '@eventbrite/ts-utils';
import isEmpty from 'lodash/isEmpty';
import { FULLBLEED_HEADER_FALLBACK } from '../../../../constants/seasonal';
import { getSeasonalHeaderContent } from '../../utils';
import { HEADER_BACKGROUNDS } from './constants';

const getBoundedIndex = (imageIndex: number) =>
    imageIndex % HEADER_BACKGROUNDS.length;

/**
 * @returns [Object: {
 *  images: [Array],
 *  attribution: [String]},
 *  Optional >
 *   shouldShowCustomHeader: [Boolean],
 *   customContentTitle: [String],
 *   customContentCtaLabel: [String],
 *   customContentCtaLink: [String],
 *   customContentCtaPrefix: [String],
 * ]
 */
export const getHeaderContent = ({
    seasonalContent,
    tld,
    locationSlug,
    fixedImageIndex = 0,
    featureFlags,
}: $FixMe = {}) => {
    let response;

    // Right now there is no non-English content available
    response = getSeasonalHeaderContent(
        seasonalContent,
        tld,
        locationSlug,
        featureFlags,
    );

    if (isEmpty(response)) {
        const imageIndex = fixedImageIndex;

        if (imageIndex >= 0) {
            const boundedImageIndex = getBoundedIndex(imageIndex);

            response = HEADER_BACKGROUNDS[boundedImageIndex];
        }
    }

    return response;
};

export const transformHeaderContent = (headerContent: any) => {
    if (!headerContent) {
        return FULLBLEED_HEADER_FALLBACK;
    }
    return {
        ...headerContent,
        customContentCtaLink: headerContent?.collectionLink + '/online/events',
        customLinkLabel: headerContent?.linkLabel,
        images: headerContent?.headerImages,
        shouldShowCustomContent: headerContent?.forceSpecialStyles,
    };
};
