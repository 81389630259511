import { Icon } from '@eventbrite/eds-icon';
import {
    ArrowRightChunky,
    CircleFill,
    TicketChunky,
} from '@eventbrite/eds-iconography';
import { isMediumNarrowDown } from '@eventbrite/eds-utils';
import { gettext } from '@eventbrite/i18n';
import PropTypes from 'prop-types';
import React, { MouseEventHandler, useContext } from 'react';
import { LiveEventContext } from '../context/Event';
import './AttendeeTicketNotificationHeader.scss';
import Countdown from './Countdown';
import NotificationHeader from './NotificationHeader';

const LiveEventHeaderContent = () => (
    <span className="eds-text-color--primary-brand--hover">
        {gettext('This event is live')}
    </span>
);

const UpcomingEventHeaderContent = ({
    eventStart,
    hasDigitalContent,
}: {
    eventStart?: string;
    hasDigitalContent: boolean;
}) => {
    const digitalContentText = gettext('Going live in');
    const defaultText = gettext('Event starts in');

    return (
        <span className="eds-text-color--grey-800">
            {hasDigitalContent ? digitalContentText : defaultText}{' '}
            <Countdown eventStart={eventStart || ''} />
        </span>
    );
};

const HEADER_CONTENT_MAP = {
    live_online: {
        iconContainerClass:
            'notification-header__icon--small notification-header__icon--online-live',
        iconProps: {
            type: <CircleFill />,
            size: 'small',
            color: 'ui-orange',
        },
        HeaderContent: LiveEventHeaderContent,
    },
    live_physical: {
        iconContainerClass: 'notification-header__icon--medium',
        iconProps: {
            type: <TicketChunky />,
            size: 'medium',
            color: 'ui-orange',
        },
        HeaderContent: LiveEventHeaderContent,
    },
    upcoming: {
        iconContainerClass: 'notification-header__icon--medium',
        iconProps: {
            type: <TicketChunky />,
            color: 'grey-800',
            size: 'medium',
        },
        HeaderContent: UpcomingEventHeaderContent,
    },
};

const AttendeeTicketNotificationHeader = (props: {
    hasDigitalContent: boolean;
}) => {
    const isEventLive = useContext(LiveEventContext);
    const headerContentKey = isEventLive
        ? props.hasDigitalContent
            ? 'live_online'
            : 'live_physical'
        : 'upcoming';

    const { iconContainerClass, iconProps, HeaderContent } =
        HEADER_CONTENT_MAP[headerContentKey];

    return (
        <NotificationHeader
            containerClasses={
                isMediumNarrowDown() ? 'eds-text-bl' : 'eds-text-hs'
            }
            renderIcon={(baseIconClass: string) => {
                return (
                    <span className={`${baseIconClass} ${iconContainerClass}`}>
                        <Icon {...iconProps} />
                    </span>
                );
            }}
        >
            <HeaderContent {...props} />
        </NotificationHeader>
    );
};

const AttendeeTicketNotificationContent = (props: {
    eventName: string;
    ctaLinkUrl?: string;
    onLinkClick?: MouseEventHandler<HTMLAnchorElement>;
    hasDigitalContent: boolean;
}) => (
    <>
        <AttendeeTicketNotificationHeader {...props} />
        <div className="eds-text-bs eds-l-pad-top-2 eds-text-color--grey-700 event-name-text">
            {props.eventName}
        </div>
        <div className="eds-l-pad-top-4">
            <a
                className="eds-text-weight--heavy eds-text-bs"
                href={props.ctaLinkUrl}
                onClick={props.onLinkClick}
                target="_blank"
                rel="noopener noreferrer"
            >
                {props.hasDigitalContent
                    ? gettext('Join now')
                    : gettext('View tickets')}{' '}
                <Icon
                    type={<ArrowRightChunky />}
                    size="xsmall"
                    color="ui-blue"
                />
            </a>
        </div>
    </>
);

AttendeeTicketNotificationContent.propTypes = {
    eventName: PropTypes.string,
    ctaLinkUrl: PropTypes.string,
    onLinkClick: PropTypes.func,
    hasDigitalContent: PropTypes.bool,
    eventStart: PropTypes.string,
};

export default AttendeeTicketNotificationContent;
