import { SimpleCarousel } from '@eventbrite/collection-carousel';
import { track } from '@eventbrite/datalayer-library';
import {
    DEFAULT_DESTINATION_EXPANSIONS,
    FormattedEvent,
} from '@eventbrite/event-renderer';
import { gettext } from '@eventbrite/i18n';
import { LoadingSkeleton } from '@eventbrite/loading-skeleton';
import { Typography } from '@eventbrite/marmalade';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { connect } from 'react-redux';
import { searchOrganicEvents } from '../../../../api/searchOrganicEvents';
import DiscoverVerticalEventCard from '../../../../components/DiscoverEventCard/DiscoverVerticalEventCard';
import { ConnectedProps, DiscoverState } from '../../../../types/index';
import TOP_10_CITIES from '../../../../__fixtures__/top10Cities.json';
import { transformResponse } from '../../../categoryBrowse/hooks/queries/events';
import { HOMEPAGE_LIGHTHOUSE_EVENTS } from '../../constants/analytics';
import { LIGHTHOUSE_ORGANIZERS } from '../../constants/category';
import './LighthouseCreatorEventsContainer.scss';

const TitleDescription = React.memo(() => (
    <div className="title-subtitle-container">
        <h2>{gettext('Fresh finds')}</h2>
        <Typography variant="body-lg" as="h3" color="#3a3247">
            {gettext(
                'Check out these top picks from some of our best and brightest organizers near you.',
            )}
        </Typography>
    </div>
));

export const LighthouseCreatorEvents = ({
    isAuthenticated,
    isMobile,
    location,
    shareOptions,
    locale,
}: ReduxProps) => {
    React.useEffect(() => {
        if (location && location.currentPlace) {
            track({
                eventName: 'HomepageLighthouseCreatorEventsView',
                eventData: {
                    searchLocation: location.currentPlace,
                },
            });
        }
    }, [location]);

    const currentPlaceId = location?.placeId ? location?.placeId : '';

    // Collection query for lighthouse creator events
    const collection = useQuery(
        ['homepage', location],
        () =>
            searchOrganicEvents({
                event_search: {
                    price: 'paid',
                    places: [currentPlaceId],
                    dates: ['current_future'],
                    page_size: 50,
                    include_mature_events: true,
                    include_manual_events: true,
                    aggs: {},
                    organizers_or: LIGHTHOUSE_ORGANIZERS[currentPlaceId],
                    sort: 'date',
                    available: true,
                    image: true,
                },
                browse_surface: 'homepage',
                'expand.destination_event': DEFAULT_DESTINATION_EXPANSIONS,
            }),
        {
            select: transformResponse,
            refetchOnMount: false,
            enabled: true,
        },
    );

    if (!(location.placeId && location.placeId in TOP_10_CITIES)) return null;

    let isTablet = false,
        width = 0;
    if (typeof window !== 'undefined') {
        width = window?.innerWidth;
        isTablet = width >= 660 && width <= 1080;
    }
    if (collection.isLoading) {
        return (
            <div>
                <LoadingSkeleton
                    height={isMobile ? '541px' : isTablet ? '498px' : '408px'}
                />
            </div>
        );
    }

    if (!collection.data || collection.data?.events.length == 0) return null;

    const events: FormattedEvent[] =
        collection.data?.events.length != 0 ? collection.data?.events : [];

    const showNavigation = isTablet ? events.length > 2 : events.length > 3;

    return (
        <div className="lighthouse-creator-events">
            <div className="lighthouse-creator-events-container">
                <TitleDescription />
                <div className="simple-carousel-parent">
                    <SimpleCarousel
                        useSideNavigation={showNavigation}
                        slidesVisible={{ desktop: 3, tablet: 2 }}
                    >
                        {events.map((event) => (
                            <div
                                className="lighthouse-creator-events-event-card"
                                data-heap-id="lighthouse-creator-event-card-click"
                                key={event.id}
                            >
                                <DiscoverVerticalEventCard
                                    event={event}
                                    shareOptions={shareOptions}
                                    isAuthenticated={isAuthenticated}
                                    affCode="ehomefeatured"
                                    statsigLocationString={
                                        HOMEPAGE_LIGHTHOUSE_EVENTS
                                    }
                                    locale={locale}
                                    isLazyImage
                                />
                            </div>
                        ))}
                    </SimpleCarousel>
                </div>
            </div>
        </div>
    );
};

const _mapStateToProps = ({
    app,
    user,
    location,
    shareOptions,
}: DiscoverState) => {
    return {
        isMobile: app.isMobile,
        isAuthenticated: user.isAuthenticated,
        location: location,
        shareOptions: shareOptions,
        locale: app.locale,
    };
};

type ReduxProps = ConnectedProps<typeof _mapStateToProps>;
export default connect(_mapStateToProps)(LighthouseCreatorEvents);
