import { SentryErrorBoundary } from '@eventbrite/error-boundary';
import { gettext } from '@eventbrite/i18n';
import React from 'react';
import InterestsSelector from './InterestsSelector';

interface InterestsSelectorProps {
    isAuthenticated?: boolean;
}
const InterestsSelectorContainer: React.FunctionComponent<
    InterestsSelectorProps
> = (props) => {
    const { isAuthenticated } = props;
    return (
        <SentryErrorBoundary fallback={<>{gettext('An error has occurred')}</>}>
            <InterestsSelector isAuthenticated={isAuthenticated} />
        </SentryErrorBoundary>
    );
};

export default InterestsSelectorContainer;
