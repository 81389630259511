import { Button } from '@eventbrite/eds-button';
import { gettext } from '@eventbrite/i18n';
import classNames from 'classnames';
import React, { Component } from 'react';
import { getPanelId, getTabId } from './util';

interface TabItemProps {
    value: string | number;
    displayName?: string | React.ReactNode;
    onSelected?: Function;
    isSelected?: boolean;
}

export default class TabItem extends Component<TabItemProps> {
    state = {
        isSelected: false,
    };

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(props: { isSelected?: boolean }) {
        /**
         * Okay, this seems overkill but fixes an issue on the first load
         * of the component. If the selected prop changed quickly in the
         * state of the parent, it was not completely reflected here.
         * Adding it to the state keeps this component in particular updated
         * all time.
         */
        this.setState({
            isSelected: props.isSelected,
        });
    }

    render() {
        const { value, displayName, onSelected } = this.props;
        const { isSelected } = this.state;

        const tabItemClasses = classNames(
            'eds-tabs__item',
            'eds-l-pad-hor-2',
            'eds-l-md-pad-hor-3',
            'eds-l-mw-pad-hor-4',
            'eds-l-ln-pad-hor-4',
            'eds-l-lg-pad-hor-4',
            'eds-l-lw-pad-hor-4',
            {
                'eds-tabs__item--selected': isSelected,

                // When displayName is not set we want to hide it
                // or else there will be a gap
                'eds-hide': !displayName,
            },
        );

        const tabItemLinkClasses = classNames('eds-tabs__item-link');

        let controlledPanel;

        if (isSelected) {
            controlledPanel = getPanelId(value);
        }

        return (
            <li
                id={getTabId(value)}
                className={tabItemClasses}
                aria-controls={controlledPanel}
            >
                <Button
                    style="none"
                    __containerClassName={tabItemLinkClasses}
                    onClick={onSelected}
                    data-spec={`tab-link--${value}`}
                    data-automation={`tab-link--${value}`}
                    aria-label={
                        isSelected
                            ? gettext(
                                  'Filter by %(displayName)s events.  Selected category.',
                                  { displayName },
                              )
                            : gettext('Filter by %(displayName)s events.', {
                                  displayName,
                              })
                    }
                >
                    <span className="eds-text-weight--heavy">
                        {displayName}
                    </span>
                </Button>
            </li>
        );
    }
}
