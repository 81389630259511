import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { ValueOf } from 'type-fest';
import { MAX_WIDTHS, PEAK_SIZES } from './constants';
import './Layout.scss';

const Header: FunctionComponent = ({ children }) =>
    children ? (
        <header className="eds-layout__header" data-spec="eds-layout__header">
            {children}
        </header>
    ) : null;

const Body: FunctionComponent = ({ children }) => (
    <div className="eds-layout__body" data-spec="eds-layout__body">
        {children}
    </div>
);

export interface LayoutProps {
    /**
     * (optional) placeholder of content that will exist above the `children`
     */
    header?: React.ReactNode;

    /**
     * (optional) adds a maximum width constrain to the main page contents
     * supports 'large' (1272px)
     */
    maxWidth?: ValueOf<typeof MAX_WIDTHS>;

    /**
     * (optional) The amount the `children` should overlap the `header`
     * supports 'small', 'medium' or 'large' peak sizes
     */
    peakSize?: ValueOf<typeof PEAK_SIZES>;

    /**
     * (optional) Turns on horizontal gutters
     * Small Narrow (360px) - 16px
     * Small (480px) - 16px
     * Small Wide (660px) - 16px
     * Medium Narrow (792px) - 24px
     * Medium (960px) - 24px
     * Medium Wide (1080px) - 24px
     * Large Narrow (1152px) - 48px
     * Large (1272px) - 48px
     * Large Wide (1608px) - 72px
     */
    hasHorizontalGutters?: boolean;
}

const Layout: FunctionComponent<LayoutProps> = ({
    children,
    header,
    peakSize,
    maxWidth,
    hasHorizontalGutters = false,
}) => {
    const className = classNames('eds-layout', {
        [`eds-layout--has-${peakSize}-peak`]: peakSize,
        [`eds-layout--has-${maxWidth}-max-width`]: maxWidth,
        'eds-layout--has-horizontal-gutters': hasHorizontalGutters,
    });

    return (
        <section className={className} data-spec="eds-layout">
            <Header>{header}</Header>
            <Body>{children}</Body>
        </section>
    );
};

export default Layout;
