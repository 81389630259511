import { createContext } from 'react';

import { DefaultsProps, initDefaults } from './defaults';

interface SegmentedCarouselContextShape {
    /**
     * Initial defaults for the carousel
     */
    defaults: DefaultsProps;
    /**
     * auto-calculated width of the root container,
     * based on its parent container
     */
    rootWidth: number;
    /**
     * Internal padding (separate from the root padding) for all containers
     * within the root container: Header, Footer, Track, Segments.
     * The purpose of this padding is to provide uniformity across all
     * containers with one padding value.
     */
    syncedPadding: number;
    /**
     * If 'true', the carousel slides to next set of visible segments.
     * If 'false', the carousel slides one segment at a time, while
     * keeping the correct number of visible segments
     */
    shouldPaginate: boolean;
    /**
     * Location of the controls. By default, controls
     * will be in the footer on containers that are 320px or less.
     */
    controlPosition: 'top' | 'bottom' | 'hidden';
    /**
     * Space between header and carousel track
     */
    headerGap: number;
    /**
     * Space between footer and carousel track
     */
    footerGap: number;
    /**
     * Number of visible segments per slide.
     * Default is based on container width:
     * 320px = 1
     * 640px = 2
     * 960px = 3
     * 1280px = 4
     */
    visibleSegments: number;
    /**
     * Left padding between root wrapper and inner containers
     */
    rootPaddingLeft: number;
    /**
     * Right padding between root wrapper and inner containers
     */
    rootPaddingRight: number;
    /**
     * Stop segments from overflowing the inner container
     */
    hideOverflow: boolean;

    // useSegmentedCarouselController props:
    /**
     * Prop of useSegmentedCarouselController.
     * Slide direction
     */
    slideTo: 'next' | 'previous' | '';
    /**
     * Prop of useSegmentedCarouselController.
     * Sets the slide direction
     */
    setSlideTo: (state: 'next' | 'previous' | '') => void;
    /**
     * Prop of useSegmentedCarouselController.
     * Resets the carousel
     */
    shouldUpdateCarousel: boolean;
    /**
     * Prop of useSegmentedCarouselController.
     * Sets the resetting of the carousel
     */
    setShouldUpdateCarousel: (state: boolean) => void;
    /**
     * Prop of useSegmentedCarouselController.
     * Current set of segments
     */
    currentPage: number;
    /**
     * Prop of useSegmentedCarouselController.
     * Sets the current set of segments
     */
    setCurrentPage: (state: number) => void;
    /**
     * Prop of useSegmentedCarouselController.
     * Checks if first set of segments
     */
    isFirstPage: boolean;
    /**
     * Prop of useSegmentedCarouselController.
     * Sets if first set of segments
     */
    setIsFirstPage: (state: boolean) => void;
    /**
     * Prop of useSegmentedCarouselController.
     * Checks if last set of segments
     */
    isLastPage: boolean;
    /**
     * Prop of useSegmentedCarouselController.
     * Sets if last set of segments
     */
    setIsLastPage: (state: boolean) => void;
    /**
     * Sets an initial segment width for the
     * SegmentedCarouselTrack. This allows the
     * carousel to try and server-side render
     * despite not having a window
     */
    initialSegmentWidth?: number;
    /**
     * Sets an initial page width for the
     * SegmentedCarouselTrack. This allows the
     * carousel to try and server-side render
     * despite not having a window
     */
    initialPageWidth?: number;
}

/**
 * Create context to allow elements to be placed inbetween compound components
 */
export const SegmentedCarouselContext =
    createContext<SegmentedCarouselContextShape>({
        defaults: initDefaults,
        rootWidth: 0,
        syncedPadding: initDefaults.syncedPadding,
        shouldPaginate: initDefaults.shouldPaginate,
        controlPosition: initDefaults.controlPosition,
        headerGap: initDefaults.headerGap,
        footerGap: initDefaults.footerGap,
        visibleSegments: initDefaults.visibleSegments,
        rootPaddingLeft: initDefaults.rootPaddingLeft,
        rootPaddingRight: initDefaults.rootPaddingRight,
        hideOverflow: initDefaults.hideOverflow,

        // useSegmentedCarouselController props:
        slideTo: '',
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
        setSlideTo: (state: 'next' | 'previous' | '') => {},
        shouldUpdateCarousel: false,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
        setShouldUpdateCarousel: (state: boolean) => {},
        currentPage: 0,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
        setCurrentPage: (state: number) => {},
        isFirstPage: true,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
        setIsFirstPage: (state: boolean) => {},
        isLastPage: false,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
        setIsLastPage: (state: boolean) => {},
    });
