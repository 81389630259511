import { locationDuck } from '@eventbrite/redux-destination';
import concat from 'lodash/concat';
import { combineReducers } from 'redux';
import { SET_PROFILES_LOADING } from '../app/actions';
import { ADD_ORGANIZER_USER_FOLLOW } from './actions';
import {
    POP_ORGANIZER,
    SET_ORGANIZERS,
    SET_ORGANIZER_CATEGORIES,
} from './actionTypes';
import { ORGANIZER_POPULAR_CATEGORIES } from './constants';

const {
    actionTypes: { UPDATE_LOCATION },
} = locationDuck;

const areProfilesLoading = (
    state = false,
    { type, payload }: { type: string; payload?: any },
) => {
    let newState = state;

    if (type === SET_PROFILES_LOADING) {
        newState = payload;
    }

    return newState;
};

const entities = (
    state: any = {},
    {
        type,
        payload,
    }: { type: string; payload?: { categoryId: string; organizerId: string } },
) => {
    let nextState = state;

    if (type === POP_ORGANIZER) {
        nextState = {
            ...nextState,
            [payload?.categoryId || '']: nextState[
                payload?.categoryId || ''
            ].filter(
                ({ organizer }: { organizer: { id: string } }) =>
                    organizer.id !== payload?.organizerId,
            ),
        };

        if (nextState[payload?.categoryId || ''].length === 0) {
            delete nextState[payload?.categoryId || ''];
        }
    }

    if (type === SET_ORGANIZERS) {
        nextState = payload;
    }

    return nextState;
};

const organizersExcluded = (
    state: (string | undefined)[] = [],
    { type, payload }: { type: string; payload?: { organizerId: string } },
) => {
    let organizersExcluded = state;

    if (type === POP_ORGANIZER) {
        organizersExcluded = concat(organizersExcluded, payload?.organizerId);
    }

    return organizersExcluded;
};

const remainingCategories = (
    state = ORGANIZER_POPULAR_CATEGORIES,
    { type, payload }: { type: string; payload?: string[] },
) => {
    let nextState: string[] | undefined = state;

    if (type === SET_ORGANIZER_CATEGORIES) {
        nextState = payload;
    }

    if (type === UPDATE_LOCATION) {
        nextState = ORGANIZER_POPULAR_CATEGORIES;
    }

    return nextState;
};

const organizersUserFollow = (
    state: any[] | undefined = [],
    { type, payload }: { type: string; payload?: any[] },
) => {
    let nextState: any[] | undefined = state;

    if (type === ADD_ORGANIZER_USER_FOLLOW) {
        nextState = payload;
    }

    return nextState;
};

export default combineReducers({
    entities,
    areProfilesLoading,
    organizersExcluded,
    organizersUserFollow,
    remainingCategories,
});
