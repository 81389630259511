import { withOverlayControls } from '@eventbrite/eds-structure';
import { gettext } from '@eventbrite/i18n';
import {
    getLocationSuggestions,
    LocationAutocomplete,
} from '@eventbrite/location-autocomplete';
import memoize from 'lodash/memoize';
import { connect } from 'react-redux';
import {
    getUserCurrentLocation as getUserCurrentLocationAction,
    locationChange as locationChangeAction,
    searchLocationSuggestions as searchLocationSuggestionsAction,
} from '../../redux/location';

const getLocationSuggestionsMemoized = memoize(getLocationSuggestions);

interface HomeLocationAutoCompleteContainerProps {
    location: {
        isOnline?: boolean;
        currentPlace?: string;
        currentPlaceParent?: string;
        isUsingCurrentLocation?: boolean;
        locationDenied?: boolean;
        isWaitingForLocation?: boolean;
        latitude?: number;
        longitude?: number;
        placeId?: string;
    };
}

const _mapStateToProps = (state: HomeLocationAutoCompleteContainerProps) => ({
    currentPlace: state.location.isOnline
        ? gettext('Online events').toString()
        : state.location.currentPlace,
    placeholder: state.location.isOnline
        ? gettext('Online events')
        : state.location.currentPlace || gettext('Choose a location'),
    currentPlaceParent: state.location.currentPlaceParent,
    isUsingCurrentLocation: state.location.isUsingCurrentLocation,
    isUseCurrentLocationEnabled: !state.location.locationDenied,
    isOnline: state.location.isOnline,
    isWaitingForLocation: state.location.isWaitingForLocation,
    locationSuggestions: getLocationSuggestionsMemoized(
        { ...state.location, suggestions: [] },
        !state.location.locationDenied,
    ),
    latitude: state.location.latitude,
    longitude: state.location.longitude,
    placeId: state.location.placeId,
});

const _mapDispatchToProps = {
    handleLocationSuggestionChange: searchLocationSuggestionsAction,
    handleLocationSelect: locationChangeAction,
    handleUseCurrentLocation: getUserCurrentLocationAction,
};

export default withOverlayControls<any>(
    connect(_mapStateToProps, _mapDispatchToProps)(LocationAutocomplete),
);
