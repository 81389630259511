import indexOf from 'lodash/indexOf';
import { combineReducers } from 'redux';
import { CITY_BROWSE_CALLS_WITH_LOADING } from '../events/cityBrowseActions';
import { SEARCH_CALLS_WITH_LOADING } from '../events/searchActions';
import {
    FE_CALLS_WITH_LOADING,
    SET_ORGANIZER_ARTICLES_LOADING,
    SET_PROFILES_LOADING,
} from './actions';

const actionsWithLoadingPayload = [
    ...CITY_BROWSE_CALLS_WITH_LOADING,
    ...SEARCH_CALLS_WITH_LOADING,
    ...FE_CALLS_WITH_LOADING,
    SET_PROFILES_LOADING,
    SET_ORGANIZER_ARTICLES_LOADING,
];

/* Set loading state for endpoint calls according to the call status.
 * Create an array of all possible calls for the endpoints we have.
 * Example of this array: ['CITY_BROWSE_CALL_SUCCESS', 'CITY_BROWSE_CALL_FAILED','CITY_BROWSE_CALL_ONGOING']
 * When one of those actions happen, we set the payload we receive directly that has the shape of
 * `{isActionLoading: value}`
 */
const loadingState = (
    state = {},
    { type, payload = {} }: { type: string; payload?: any },
) => {
    let nextState = state;

    if (indexOf(actionsWithLoadingPayload, type) >= 0) {
        nextState = {
            ...nextState,
            ...payload,
        };
    }

    return nextState;
};

export default combineReducers({
    loadingState,
    domain: (state = {}) => state,
    tld: (state = '') => state,
    locale: (state = '') => state,
    experiments: (state = {}) => state,
    featureFlags: (state = {}) => state,
    isMobile: (state = {}) => state,
    isBotRequest: (state = false) => state,
    country_popular_cities: (state = {}) => state,
    top_destinations_for_tld: (state = {}) => state,
    uses_language_subdirectory: (state = false) => state,
});
