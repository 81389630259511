import React from 'react';
import CountdownComponent, { zeroPad } from 'react-countdown';

const RenderCountdown = ({
    hours,
    minutes,
    seconds,
}: {
    hours: number;
    minutes: number;
    seconds: number;
}) => {
    return (
        <span>
            {hours}:{zeroPad(minutes)}:{zeroPad(seconds)}
        </span>
    );
};

const Countdown = (props: { eventStart: string }) => {
    return (
        <CountdownComponent
            date={props.eventStart}
            renderer={RenderCountdown}
        />
    );
};

export default Countdown;
