import { Layout } from '@eventbrite/eds-layout';
import React from 'react';
import { PopularCities, TopDestinations } from '../../../../types';
import PopularCitiesContainer from '../PopularCitiesContainer';
import { ThingsToDoShelfContainer } from '../ThingsToDoShelfContainer';
import TopDestinationsContainer from '../TopDestinations';
import './HomeBottomShelfContainer.scss';

export interface HomeBottomShelfContainerProps {
    ebDomain: string;
    localizedCountryName: string;
    languageSubdirectory: string;
    country_popular_cities?: PopularCities;
    topDestinations?: TopDestinations;
    enableTopDestinationsHomepage?: Boolean;
    enablePopularCitiesHomepage?: Boolean;
    launchThingsToDoPage?: Boolean;
    enableThingsToDoPhase2?: Boolean;
}

const shouldDisplayPopularCities = (props: HomeBottomShelfContainerProps) => {
    const {
        country_popular_cities,
        enablePopularCitiesHomepage,
        launchThingsToDoPage,
    } = props;

    return (
        enablePopularCitiesHomepage &&
        launchThingsToDoPage &&
        country_popular_cities &&
        country_popular_cities.length > 0
    );
};

const shouldDisplayTopDestinations = (props: HomeBottomShelfContainerProps) => {
    const { enableTopDestinationsHomepage } = props;
    return enableTopDestinationsHomepage;
};

export const HomeBottomShelfContainer: React.FunctionComponent<
    HomeBottomShelfContainerProps
> = (props) => {
    const {
        country_popular_cities,
        enableThingsToDoPhase2,
        topDestinations,
        languageSubdirectory,
    } = props;

    return (
        <div className="home-bottom-shelf-container eds-bg-color--grey-100 eds-l-pad-top-20">
            <Layout hasHorizontalGutters={true} maxWidth="large">
                {shouldDisplayTopDestinations(props) && (
                    <TopDestinationsContainer
                        ebDomain={props.ebDomain}
                        localizedCountryName={props.localizedCountryName}
                        languageSubdirectory={languageSubdirectory}
                        topDestinations={topDestinations}
                    />
                )}
                {shouldDisplayPopularCities(props) && (
                    <PopularCitiesContainer
                        languageSubdirectory={languageSubdirectory}
                        cities={country_popular_cities}
                    />
                )}
                {enableThingsToDoPhase2 && <ThingsToDoShelfContainer />}
            </Layout>
        </div>
    );
};
