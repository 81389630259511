import { UPDATE_CONTENT } from '@eventbrite/discover-utils';
import {
    GA_CLICK_VIEW_ONLINE_COLLECTION_ACTION,
    GA_HOME_BANNER_CTA_CLICK,
    GA_HOME_CATEGORY,
    GA_HOME_FEED_CATEGORY,
    GA_TRANSITION_ONLINE_COLLECTION,
} from '../../../../constants/analytics';
import { EntityContext } from '../../../../types/index';
import { storeHomeFeedState } from '../../utils';

export const UPDATE_TAB_KEY = 'UPDATE_TAB_KEY';
export const updateTabKey =
    (payload: { tabKey: string; tabKeyLabel: string }) =>
    (dispatch: Function) => {
        storeHomeFeedState({
            tabKey: payload.tabKey,
            tabKeyLabel: payload.tabKeyLabel,
        });
        dispatch({
            type: UPDATE_TAB_KEY,
            payload,
        });
    };

export const updateContent = ({
    entities,
    entityContext,
}: {
    entities: { [key: string]: any };
    entityContext: EntityContext[];
}) => ({
    type: UPDATE_CONTENT,
    payload: {
        entities,
        entityContext,
    },
});

export const CLICK_CTA_SEASONAL = 'CLICK_CTA_SEASONAL';
const trackCTASeasonal = ({
    loggedIn = true,
    customContentCtaLink,
}: {
    loggedIn: boolean;
    customContentCtaLink: string;
    categoryPrefixed: string;
}) => ({
    type: CLICK_CTA_SEASONAL,
    meta: {
        analytics: {
            category: loggedIn ? GA_HOME_FEED_CATEGORY : GA_HOME_CATEGORY,
            action: GA_HOME_BANNER_CTA_CLICK,
            label: customContentCtaLink,
            onWindowUnload: true,
        },
    },
});

export const clickCTASeasonal =
    () => (dispatch: Function, getState: Function) => {
        const {
            user: { isAuthenticated },
            header: { customContentCtaPrefix, customContentCtaLink } = {},
        }: {
            user: { isAuthenticated: boolean };
            header: {
                customContentCtaPrefix?: string;
                customContentCtaLink?: string;
            };
        } = getState();

        return dispatch(
            trackCTASeasonal({
                categoryPrefixed: customContentCtaPrefix || '',
                loggedIn: isAuthenticated,
                customContentCtaLink: customContentCtaLink || '',
            }),
        );
    };

export const CLICK_ONLINE_COLLECTION = 'CLICK_ONLINE_COLLECTION';
const trackOnClickOnlineCollection = ({
    loggedIn = true,
    collectionId = '',
}) => ({
    type: CLICK_ONLINE_COLLECTION,
    meta: {
        analytics: {
            category: loggedIn ? GA_HOME_FEED_CATEGORY : GA_HOME_CATEGORY,
            action: GA_CLICK_VIEW_ONLINE_COLLECTION_ACTION,
            label: collectionId,
            onWindowUnload: true,
        },
    },
});
export const clickOnOnlineCollection =
    ({ label }: { label: string }) =>
    (dispatch: Function, getState: Function) => {
        const {
            user: { isAuthenticated },
        } = getState();

        return dispatch(
            trackOnClickOnlineCollection({
                loggedIn: isAuthenticated,
                collectionId: label,
            }),
        );
    };

export const CLICK_COLLECTION_TRANSITION = 'CLICK_COLLECTION_TRANSITION';
const trackOnTransitionOnlineCollection = ({
    loggedIn = true,
    collectionId = '',
    index = 0,
}) => ({
    type: CLICK_COLLECTION_TRANSITION,
    meta: {
        analytics: {
            category: loggedIn ? GA_HOME_FEED_CATEGORY : GA_HOME_CATEGORY,
            action: GA_TRANSITION_ONLINE_COLLECTION,
            label: collectionId,
            value: index,
        },
    },
});

export const clickOnTransitionOnlineCollection =
    ({ label, value }: { label: string; value: number }) =>
    (dispatch: Function, getState: Function) => {
        const {
            user: { isAuthenticated },
        } = getState();

        return dispatch(
            trackOnTransitionOnlineCollection({
                loggedIn: isAuthenticated,
                collectionId: label,
                index: value,
            }),
        );
    };

export const UPDATE_CAROUSEL_CATEGORY_STATE = 'UPDATE_CAROUSEL_CATEGORY_STATE';
export const updateCarouselCategoryState = (
    categoryIndex: number,
    categoryState: boolean,
) => ({
    type: UPDATE_CAROUSEL_CATEGORY_STATE,
    payload: {
        categoryIndex: categoryIndex,
        categoryState: categoryState,
    },
});
