import { combineReducers } from 'redux';
import { UPDATE_SEARCH_LOCATION_SLUG } from './actions';

export const slug = (
    state = '',
    { type, payload }: { [key: string]: string },
) => {
    let nextState = state;

    if (type === UPDATE_SEARCH_LOCATION_SLUG && payload) {
        nextState = payload;
    }

    return nextState;
};

export const currentPlace = (
    state = '',
    { type, payload }: { type: string; payload?: { currentPlace?: string } },
) => {
    let nextState = state;

    if (type === UPDATE_SEARCH_LOCATION_SLUG && payload?.currentPlace) {
        nextState = payload.currentPlace;
    }

    return nextState;
};

export const currentPlaceParent = (
    state = '',
    {
        type,
        payload,
    }: { type: string; payload?: { currentPlaceParent?: string } },
) => {
    let nextState = state;

    if (type === UPDATE_SEARCH_LOCATION_SLUG && payload?.currentPlaceParent) {
        nextState = payload.currentPlaceParent;
    }

    return nextState;
};

export const city = (
    state = '',
    { type, payload }: { type: string; payload?: { city?: string } },
) => {
    let nextState = state;

    if (type === UPDATE_SEARCH_LOCATION_SLUG && payload?.city) {
        nextState = payload.city;
    }

    return nextState;
};

export const region = (
    state = '',
    { type, payload }: { type: string; payload?: { region?: string } },
) => {
    let nextState = state;

    if (type === UPDATE_SEARCH_LOCATION_SLUG && payload?.region) {
        nextState = payload.region;
    }

    return nextState;
};

export default combineReducers({
    slug,
    region,
    city,
    currentPlace,
    currentPlaceParent,
    // Below reducers are currently not used, this is just here to silence an error
    placeId: () => null,
    latitude: () => null,
    longitude: () => null,
    placeType: () => null,
    topSuggestions: () => null,
});
