import { bboxShape } from '@eventbrite/discover-utils';
import { HAS_WINDOW } from '@eventbrite/feature-detection';
import { getBrowserLocation } from '@eventbrite/location-autocomplete';
import { setNavigatorLastLocation } from '@eventbrite/personalization';
import {
    locationDuck,
    parseLocationResponse,
    setLocationData,
    transformGeoPlaceObject,
} from '@eventbrite/redux-destination';
import { keysCamelToSnake } from '@eventbrite/transformation-utils';
import { $FixMe } from '@eventbrite/ts-utils';
import { fetchThingsToDoShelfData } from '../../../../redux/actions/thingsToDoShelf';
import { updateHeaderImage } from '../header/actions';
import { updateSearchLocationSlug } from '../search/actions';

const {
    actionTypes: { UPDATE_LOCATION_SUGGESTIONS },
    actions: {
        setWaitingForLocation,
        showLocationDeniedNotification,
        updateLocation,
    },
    api: {
        getPlaceFromCoordinatesBase: getPlaceFromCoordinatesApi,
        searchLocationSuggestions: searchLocationSuggestionsApi,
    },
    constants: { LOCATION_DENIED_CODE },
} = locationDuck;

const updateLocationSuggestions = (suggestions: $FixMe) => ({
    type: UPDATE_LOCATION_SUGGESTIONS,
    payload: suggestions,
});

export const LOCATION_DENIED = 'LOCATION_DENIED';

const updateLocationDenied = (payload: boolean) => ({
    type: LOCATION_DENIED,
    payload,
});

export const searchLocationSuggestions =
    (query: string) => (dispatch: Function, getState: Function) => {
        if (!query) {
            return dispatch(updateLocationSuggestions([]));
        }

        const {
            app: { featureFlags },
        } = getState();

        return searchLocationSuggestionsApi(query)
            .then((response: any) =>
                dispatch(
                    updateLocationSuggestions(
                        parseLocationResponse(response, featureFlags),
                    ),
                ),
            )
            .catch(() => {
                //fail silently
            });
    };

interface locationChangeProps {
    bbox?: bboxShape;
    currentPlace?: string;
    currentPlaceParent?: string;
    country?: string;
    isOnline?: boolean;
    isUsingCurrentLocation?: boolean;
    latitude?: number;
    longitude?: number;
    placeId?: string;
    placeType?: string;
    slug?: string;
}

export const locationChange =
    ({
        slug,
        placeId,
        latitude,
        longitude,
        bbox,
        placeType,
        currentPlace,
        currentPlaceParent,
        country,
        isOnline,
    }: locationChangeProps) =>
    (dispatch: Function) => {
        const locationData = {
            slug,
            placeId,
            latitude,
            longitude,
            bbox,
            placeType,
            currentPlace,
            currentPlaceParent,
            country,
            isOnline,
        };

        let recentValue;

        /* When the user selects "online" from the Recent suggestions the placeId is null and the slug is online,
            but the isOnline is false. Because of that we should change it to reflect the correct filter */
        if (!placeId && slug === 'online') {
            locationData.isOnline = true;
        } else {
            // Make sure to explicitly set isOnline to false
            // for physical location searches, since events should
            // not be both online and have a physical location, at least for now.
            locationData.isOnline = false;
        }

        if (HAS_WINDOW && !locationData.isOnline) {
            recentValue = placeId || `${latitude}-${longitude}`;
        }

        setNavigatorLastLocation({
            currentPlace,
            currentPlaceParent,
            country,
            content: currentPlace,
            secondaryContent: currentPlaceParent,
            value: `recent-${recentValue}`,
            latitude,
            longitude,
            bbox,
            placeType,
            placeId,
            slug,
            isHistorySuggestion: true,
            isOnline,
        });

        // See https://docs.google.com/document/d/1Vg-kyxM34gw71vw_5dLcfzFRhzM3scQ3FKtYUlWXLSA/edit?usp=sharing
        setLocationData(keysCamelToSnake(locationData));

        dispatch(updateLocation(locationData));

        /**
         * On different cities we may want to show a different header like seasonal content
         * or specific collections with a CTA.
         */
        dispatch(updateHeaderImage());
        dispatch(fetchThingsToDoShelfData());

        /**
         * We do not directly call for `fetchEventsForTab`. Updating the content of the tab is handled
         * by `feed/src/containers/BrowseContainer.js:_handleTabLoad`
         */
    };

export const locationChangeWithoutSearch =
    ({ slug }: { slug: string }) =>
    (dispatch: Function) => {
        dispatch(updateSearchLocationSlug(slug));
    };

export const getLocationPermission = () => {
    let promise;

    if (HAS_WINDOW && window.navigator && window.navigator.permissions) {
        promise = navigator.permissions
            .query({ name: 'geolocation' })
            .then((result) => result.state);
    } else {
        promise = new Promise((_, reject) =>
            reject('No geolocation permissions object'),
        );
    }

    return promise;
};

const parseResponseFromCoordinatesApi =
    ({ place }: { place: { currentPlace: string } }) =>
    (dispatch: Function) => {
        if (place) {
            return dispatch(
                locationChange({
                    ...place,
                    isUsingCurrentLocation: true,
                }),
            );
        }

        return dispatch(setWaitingForLocation(false));
    };

/*
 * Action which enacts the browser get current location api and once that promise is resolved,
 * gets a place object from the browser, then runs a search against that data.
 */
export const getUserCurrentLocation = () => (dispatch: Function) => {
    dispatch(setWaitingForLocation(true));

    return getBrowserLocation()
        .then(
            ({
                coords,
            }: {
                coords: { latitude: number; longitude: number };
            }) => {
                const { latitude, longitude } = coords;

                getPlaceFromCoordinatesApi({ latitude, longitude })
                    .then(({ place }: { place: string }) => {
                        if (Object.keys(place).length === 0) {
                            // Throw an arbitrary error to fallback to bounding box location
                            throw new Error('Unresolved place object returned');
                        }

                        const _place = transformGeoPlaceObject(place);

                        dispatch(
                            parseResponseFromCoordinatesApi({ place: _place }),
                        );
                    })
                    .catch(() => {
                        // if we can't map the user to a city, we just resort to a rough
                        // bounding box around the current location.
                        const place = {
                            currentPlace: 'Your Location',
                            currentPlaceParent: '',
                            latitude,
                            longitude,
                            isOnline: false,
                            bbox: {
                                nw: {
                                    lng: longitude - 0.4,
                                    lat: latitude + 0.3,
                                },
                                sw: {
                                    lng: longitude - 0.4,
                                    lat: latitude - 0.3,
                                },
                                ne: {
                                    lng: longitude + 0.4,
                                    lat: latitude + 0.3,
                                },
                                se: {
                                    lng: longitude + 0.4,
                                    lat: latitude - 0.3,
                                },
                            },
                        };

                        dispatch(parseResponseFromCoordinatesApi({ place }));
                    });
            },
        )
        .catch(({ code }: { code: string }) => {
            if (code === LOCATION_DENIED_CODE) {
                dispatch(setWaitingForLocation(false));

                dispatch(updateLocationDenied(true));
                dispatch(showLocationDeniedNotification());
            }
        });
};
