import { Button } from '@eventbrite/eds-button';
import { Icon } from '@eventbrite/eds-icon';
import { Link } from '@eventbrite/eds-link';
import { GenericLazyString } from '@eventbrite/i18n';
import React, { useEffect, useState } from 'react';
import './StateMessage.scss';

interface StateMessageProps {
    title?: string | GenericLazyString;
    description?: string | GenericLazyString;
    iconType?: object | JSX.Element;
    iconSize?: string;
    primaryLink?: string;
    primaryText?: string | GenericLazyString;
    buttonStyle?: string;
}

const StateMessage: React.FunctionComponent<StateMessageProps> = ({
    title,
    description,
    iconType,
    iconSize,
    primaryLink,
    primaryText,
    buttonStyle,
}) => {
    const [isInBrowser, setIsInBrowser] = useState(false);

    useEffect(() => setIsInBrowser(true), []);

    // Don't render this page on the server, wait until it's in browser
    return (isInBrowser && (
        <section className="feed-state-msg eds-l-pad-vert-6 eds-align--center-vertical">
            <Icon type={iconType} size={iconSize} />
            <header className="eds-text-bl eds-l-mar-top-6">
                <h3>{title}</h3>
            </header>
            <p className="eds-text-bm eds-text--center eds-text-color--ui-600 eds-l-mar-top-2 eds-l-mar-bot-6">
                {description}
            </p>
            {buttonStyle && primaryLink && (
                <Link to={primaryLink}>
                    <Button style={buttonStyle}>{primaryText}</Button>
                </Link>
            )}
            {!buttonStyle && primaryLink && (
                <Link to={primaryLink}>
                    <p className="eds-text-weight--heavy">{primaryText}</p>
                </Link>
            )}
        </section>
    )) as JSX.Element;
};

export default StateMessage;
