import React from 'react';
import { ArrowUpRight } from '../components/Arrow/ArrowUpRight';

/**
 * All Constant and Default values
 */
export default {
    /**
     * Grey [light]/ui-100
     */
    DEFAULT_COMPONENT_BACKGROUND_COLOR: '#f8f7fa',
    /**
     * White
     */
    DEFAULT_TAG_BACKGROUND_COLOR: '#FFFFFF',
    /**
     * Grey [light]/ui-200
     */
    DEFAULT_TAG_HOVER_COLOR: '#eeedf2',
    /**
     * Arrow-Up-Right ↗
     */
    DEFAULT_ICON: <ArrowUpRight />,
    /**
     * Minimum Desktop Viewport Size
     */
    DESKTOP_VIEWPORT: 769,
    /**
     * Number of Tags in a row for Desktop Viewport
     */
    DESKTOP_COLUMNS_LIMIT: 6,
    /**
     * Number of Tags in a row for Mobile/Tablet Viewport
     */
    TABLET_COLUMNS_LIMIT: 4,
};
