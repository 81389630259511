import React from 'react';
import './IconCategory.scss';
import { CardContent } from './types';

export const IconCategoryCard: React.FunctionComponent<CardContent> = ({
    title,
    icon,
}) => {
    return (
        <div className={`iconCategoryCard`}>
            <div className="iconCategoryCardImageWrapper">{icon}</div>
            <p className="iconCategoryCardTitle eds-text-weight--heavy">
                {title}
            </p>
        </div>
    );
};
