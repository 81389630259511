import { trackEventFromState } from '@eventbrite/site-analytics';
import { GA_HOME_FEED_CATEGORY } from '../../../../constants/analytics';

// General loading, used for big page reloads like calling /FE
export const FE_LOADING_KEY = 'isFeLoading';
export const SET_FE_LOADING = 'SET_FE_LOADING';
export const setFeLoading = (isLoading: boolean) => ({
    type: SET_FE_LOADING,
    payload: {
        [FE_LOADING_KEY]: isLoading,
    },
});

export const FE_CALL_SUCCESS = 'FE_CALL_SUCCESS';
export const setFeCallAsSuccess = () => ({
    type: FE_CALL_SUCCESS,
    payload: {
        [FE_LOADING_KEY]: false,
    },
});

export const FE_CALL_ONGOING = 'FE_CALL_ONGOING';
export const setFeCallAsOngoing = () => ({
    type: FE_CALL_ONGOING,
    payload: {
        [FE_LOADING_KEY]: true,
    },
});

export const FE_CALL_FAILED = 'FE_CALL_FAILED';
export const setFeCallAsFailed = () => ({
    type: FE_CALL_FAILED,
    payload: {
        [FE_LOADING_KEY]: false,
    },
});

export const FE_CALLS_WITH_LOADING = [
    SET_FE_LOADING,
    FE_CALL_SUCCESS,
    FE_CALL_ONGOING,
    FE_CALL_FAILED,
];

export const PROFILES_LOADING_KEY = 'isProfilesLoading';
export const SET_PROFILES_LOADING = 'SET_PROFILES_LOADING';
export const setProfilesLoading = (isLoading: boolean) => ({
    type: SET_PROFILES_LOADING,
    payload: {
        [PROFILES_LOADING_KEY]: isLoading,
    },
});

export const ORGANIZER_ARTICLES_LOADING_KEY = 'isOrganizerArticlesLoading';
export const SET_ORGANIZER_ARTICLES_LOADING = 'SET_ORGANIZER_ARTICLES_LOADING';
export const setOrganizerArticlesLoading = (isLoading: boolean) => ({
    type: SET_ORGANIZER_ARTICLES_LOADING,
    payload: {
        [ORGANIZER_ARTICLES_LOADING_KEY]: isLoading,
    },
});

// This doesn't seems to be working
export const trackFeedEvent =
    (trackingProps = {}) =>
    (_dispatch: Function, getState: Function) => {
        trackEventFromState(getState(), {
            category: GA_HOME_FEED_CATEGORY,
            ...trackingProps,
        });
    };
