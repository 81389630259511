import {
    TLD_AUSTRALIA,
    TLD_CANADA,
    TLD_DEUTSCHLAND,
    TLD_GLOBALLY,
    TLD_UNITED_KINGDOM,
    TLD_UNITED_STATES,
} from '../../../../constants/constants';
import { TopDestinations } from '../../../../types';

export type topDestination = {
    displayName: string;
    locationSlug: string;
    imageUrl: string;
};

export const TOP_DESTINATIONS_DOMAIN = [
    TLD_CANADA,
    TLD_DEUTSCHLAND,
    TLD_AUSTRALIA,
    TLD_UNITED_KINGDOM,
    TLD_GLOBALLY,
    TLD_UNITED_STATES,
];
export const TOP_DESTINATIONS: Record<string, TopDestinations> = {
    [TLD_UNITED_STATES]: [
        {
            location_slug: 'ny--new-york',
            display_name: 'New York',
        },
        {
            location_slug: 'ca--los-angeles',
            display_name: 'Los Angeles',
        },
        {
            location_slug: 'il--chicago',
            display_name: 'Chicago',
        },
        {
            location_slug: 'dc--washington',
            display_name: 'Washington',
        },
        {
            location_slug: 'ga--atlanta',
            display_name: 'Atlanta',
        },
        {
            location_slug: 'tx--dallas',
            display_name: 'Dallas',
        },
        {
            location_slug: 'tx--houston',
            display_name: 'Houston',
        },
        {
            location_slug: 'ca--san-francisco',
            display_name: 'San Francisco',
        },
        {
            location_slug: 'fl--miami',
            display_name: 'Miami',
        },
        {
            location_slug: 'ma--boston',
            display_name: 'Boston',
        },
        {
            location_slug: 'nv--las-vegas',
            display_name: 'Las Vegas',
        },
        {
            location_slug: 'nc--charlotte',
            display_name: 'Charlotte',
        },
        {
            location_slug: 'fl--orlando',
            display_name: 'Orlando',
        },
        {
            location_slug: 'pa--philadelphia',
            display_name: 'Philadelphia',
        },
        {
            location_slug: 'ca--san-diego',
            display_name: 'San Diego',
        },
    ],
    [TLD_DEUTSCHLAND]: [
        {
            location_slug: 'germany--berlin',
            display_name: 'Berlin',
        },
        {
            location_slug: 'germany--frankfurt-am-main',
            display_name: 'Frankfurt Am Main',
        },
        {
            location_slug: 'germany--m%C3%BCnchen',
            display_name: 'München',
        },
        {
            location_slug: 'germany--hamburg',
            display_name: 'Hamburg',
        },
        {
            location_slug: 'germany--k%C3%B6ln',
            display_name: 'Köln',
        },
        {
            location_slug: 'germany--d%C3%BCsseldorf',
            display_name: 'Düsseldorf',
        },
        {
            location_slug: 'germany--nordrhein-westfalen',
            display_name: 'Nordrhein-Westfalen',
        },
        {
            location_slug: 'germany--stuttgart',
            display_name: 'Stuttgart',
        },
        {
            location_slug: 'netherlands--amsterdam',
            display_name: 'Amsterdam',
        },
        {
            location_slug: 'germany--leipzig',
            display_name: 'Leipzig',
        },
        {
            location_slug: 'united-kingdom--london',
            display_name: 'London',
        },
        {
            location_slug: 'germany--mannheim',
            display_name: 'Mannheim',
        },
        {
            location_slug: 'germany--dortmund',
            display_name: 'Dortmund',
        },
        {
            location_slug: 'germany--hannover',
            display_name: 'Hannover',
        },
        {
            location_slug: 'germany--n%C3%BCrnberg',
            display_name: 'Nürnberg',
        },
    ],
    [TLD_CANADA]: [
        {
            location_slug: 'canada--toronto',
            display_name: 'Toronto',
        },
        {
            location_slug: 'canada--vancouver',
            display_name: 'Vancouver',
        },
        {
            location_slug: 'canada--montreal',
            display_name: 'Montreal',
        },
        {
            location_slug: 'canada--ottawa',
            display_name: 'Ottawa',
        },
        {
            location_slug: 'canada--calgary',
            display_name: 'Calgary',
        },
        {
            location_slug: 'canada--edmonton',
            display_name: 'Edmonton',
        },
        {
            location_slug: 'canada--mississauga',
            display_name: 'Mississauga',
        },
        {
            location_slug: 'canada--ontario',
            display_name: 'Ontario',
        },
        {
            location_slug: 'canada--winnipeg',
            display_name: 'Winnipeg',
        },
        {
            location_slug: 'canada--victoria',
            display_name: 'Victoria',
        },
        {
            location_slug: 'canada--hamilton',
            display_name: 'Hamilton',
        },
        {
            location_slug: 'canada--halifax',
            display_name: 'Halifax',
        },
        {
            location_slug: 'canada--london',
            display_name: 'London',
        },
        {
            location_slug: 'canada--brampton',
            display_name: 'Brampton',
        },
        {
            location_slug: 'canada--surrey',
            display_name: 'Surrey',
        },
    ],
    [TLD_UNITED_KINGDOM]: [
        {
            location_slug: 'united-kingdom--london',
            display_name: 'London',
        },
        {
            location_slug: 'united-kingdom--manchester',
            display_name: 'Manchester',
        },
        {
            location_slug: 'united-kingdom--birmingham',
            display_name: 'Birmingham',
        },
        {
            location_slug: 'united-kingdom--bristol',
            display_name: 'Bristol',
        },
        {
            location_slug: 'united-kingdom--liverpool',
            display_name: 'Liverpool',
        },
        {
            location_slug: 'united-kingdom--glasgow',
            display_name: 'Glasgow',
        },
        {
            location_slug: 'united-kingdom--leeds',
            display_name: 'Leeds',
        },
        {
            location_slug: 'united-kingdom--edinburgh',
            display_name: 'Edinburgh',
        },
        {
            location_slug: 'united-kingdom--newcastle-upon-tyne',
            display_name: 'Newcastle Upon Tyne',
        },
        {
            location_slug: 'united-kingdom--belfast',
            display_name: 'Belfast',
        },
        {
            location_slug: 'united-kingdom--sheffield',
            display_name: 'Sheffield',
        },
        {
            location_slug: 'united-kingdom--nottingham',
            display_name: 'Nottingham',
        },
        {
            location_slug: 'united-kingdom--cardiff',
            display_name: 'Cardiff',
        },
        {
            location_slug: 'united-kingdom--brighton',
            display_name: 'Brighton',
        },
        {
            location_slug: 'united-kingdom--cambridge',
            display_name: 'Cambridge',
        },
    ],
    [TLD_AUSTRALIA]: [
        {
            location_slug: 'australia--melbourne',
            display_name: 'Melbourne',
        },
        {
            location_slug: 'australia--sydney',
            display_name: 'Sydney',
        },
        {
            location_slug: 'australia--brisbane-city',
            display_name: 'Brisbane City',
        },
        {
            location_slug: 'australia--adelaide',
            display_name: 'Adelaide',
        },
        {
            location_slug: 'australia--gold-coast',
            display_name: 'Gold Coast',
        },
        {
            location_slug: 'australia--new-south-wales',
            display_name: 'New South Wales',
        },
        {
            location_slug: 'australia--canberra',
            display_name: 'Canberra',
        },
        {
            location_slug: 'australia--victoria',
            display_name: 'Victoria',
        },
        {
            location_slug: 'australia--highgate',
            display_name: 'Highgate',
        },
        {
            location_slug: 'australia--perth',
            display_name: 'Perth',
        },
        {
            location_slug: 'australia--queensland',
            display_name: 'Queensland',
        },
        {
            location_slug: 'australia--hobart',
            display_name: 'Hobart',
        },
        {
            location_slug: 'australia--newcastle',
            display_name: 'Newcastle',
        },
        {
            location_slug: 'australia--geelong',
            display_name: 'Geelong',
        },
        {
            location_slug: 'australia--byron-bay',
            display_name: 'Byron Bay',
        },
    ],
    [TLD_GLOBALLY]: [
        {
            location_slug: 'ny--new-york',
            display_name: 'New York',
        },
        {
            location_slug: 'united-kingdom--london',
            display_name: 'London',
        },
        {
            location_slug: 'ca--los-angeles',
            display_name: 'Los Angeles',
        },
        {
            location_slug: 'il--chicago',
            display_name: 'Chicago',
        },
        {
            location_slug: 'canada--toronto',
            display_name: 'Toronto',
        },
        {
            location_slug: 'dc--washington',
            display_name: 'Washington',
        },
        {
            location_slug: 'ga--atlanta',
            display_name: 'Atlanta',
        },
        {
            location_slug: 'ca--san-francisco',
            display_name: 'San Francisco',
        },
        {
            location_slug: 'fl--miami',
            display_name: 'Miami',
        },
        {
            location_slug: 'tx--dallas',
            display_name: 'Dallas',
        },
        {
            location_slug: 'ireland--dublin',
            display_name: 'Dublin',
        },
        {
            location_slug: 'tx--houston',
            display_name: 'Houston',
        },
        {
            location_slug: 'nv--las-vegas',
            display_name: 'Las Vegas',
        },
        {
            location_slug: 'ma--boston',
            display_name: 'Boston',
        },
        {
            location_slug: 'australia--sydney',
            display_name: 'Sydney',
        },
    ],
};
