import { gettext } from '@eventbrite/i18n';
import { Tags } from '@eventbrite/tags';
import React from 'react';
import { PopularCities } from '../../../../types';

export const createTagObject = (
    languageSubdirectory: string,
    countryPopularCities?: PopularCities,
) => {
    return (
        countryPopularCities?.map((city) => {
            let url = `/ttd/${city.location_slug}/`;
            url = languageSubdirectory ? '/' + languageSubdirectory + url : url;

            return {
                displayText: gettext('Things to do in %(city)s', {
                    city: city.display_name,
                }),
                linkUrl: url,
            };
        }) || []
    );
};

export const PopularCitiesContainer = (props: {
    languageSubdirectory: string;
    cities?: PopularCities;
}) => {
    const { cities, languageSubdirectory } = props;

    const tagObjects = createTagObject(languageSubdirectory, cities);

    const copy = gettext('Popular cities');

    return (
        <div
            className="popular-cities-container"
            data-testid="popular-cities-container"
        >
            <h2 className="eds-text-color--grey-900 eds-text-hs">{copy}</h2>
            <Tags tags={tagObjects} />
        </div>
    );
};
