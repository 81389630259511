import { setWindowLocation } from '@eventbrite/http';
import { getCanonicalSearchUrl } from '@eventbrite/search-utils';
import { $FixMe } from '@eventbrite/ts-utils';
import trim from 'lodash/trim';
import {
    SUGGESTION_TYPE_HISTORY,
    SUGGESTION_TYPE_POPULAR,
} from '../../../../constants';
import {
    GA_HOME_CATEGORY,
    GA_HOME_FEED_CATEGORY,
    GA_RUN_SEARCH_ACTION,
    GA_RUN_SEARCH_HEADER_POPULAR,
    GA_RUN_SEARCH_HEADER_RECENT,
} from '../../../../constants/analytics';
import { UPDATE_SEARCH_SUGGESTIONS } from '../../../../constants/constants';
import { parseSuggestions } from '../../utils';
import { searchAutocompleteSuggestions as searchAutocompleteSuggestionsApi } from './api';

const SEARCH_HEADER_ACTIONS: { [key: string]: string } = {
    [SUGGESTION_TYPE_HISTORY]: GA_RUN_SEARCH_HEADER_RECENT,
    [SUGGESTION_TYPE_POPULAR]: GA_RUN_SEARCH_HEADER_POPULAR,
};

const trackHeaderSearch = ({ term }: { term: string }) => ({
    type: 'RUN_SEARCH',
    meta: {
        analytics: {
            category: 'header',
            onWindowUnload: true,
            action: GA_RUN_SEARCH_ACTION,
            label: term,
        },
    },
});

const trackSearchHeader = ({
    query,
    locationSlug,
    actionType,
    loggedIn = true,
}: {
    query: string;
    locationSlug?: string;
    actionType: string;
    loggedIn?: boolean;
}) => ({
    type: 'RUN_SEARCH_HEADER',
    meta: {
        analytics: {
            category: loggedIn ? GA_HOME_FEED_CATEGORY : GA_HOME_CATEGORY,
            action: GA_RUN_SEARCH_ACTION,
            onWindowUnload: true,
            label: `${query}`,
            dimensions: {
                locationOnPage: 'Header',
                nameOfButton: SEARCH_HEADER_ACTIONS[actionType],
                urlParams: JSON.stringify({ location_slug: locationSlug }),
            },
        },
    },
});

export const UPDATE_SEARCH_LOCATION_SLUG = 'UPDATE_SEARCH_LOCATION_SLUG';

export const updateSearchLocationSlug = (slug: string) => ({
    type: UPDATE_SEARCH_LOCATION_SLUG,
    payload: slug,
});

export const runSearch =
    (term: string) => (dispatch: Function, getState: Function) => {
        const state = getState();
        const {
            location: { slug, bbox, placeId },
        } = state;
        const locationString = trim(slug) || 'local';
        const optionalParams = placeId ? {} : { bbox };

        const url = getCanonicalSearchUrl({
            slug: locationString,
            q: term,
            ...optionalParams,
        });

        dispatch(
            trackHeaderSearch({
                term,
            }),
        );

        setWindowLocation(url);
    };

const updateSearchAutocompleteSuggestions = (suggestions: $FixMe) => ({
    type: UPDATE_SEARCH_SUGGESTIONS,
    payload: suggestions,
});

export const searchAutocompleteSuggestions =
    (query: string) => (dispatch: Function, getState: Function) => {
        if (!query) {
            return dispatch(updateSearchAutocompleteSuggestions({}));
        }

        const {
            location: { placeId, isOnline },
        } = getState();

        return searchAutocompleteSuggestionsApi({
            placeId,
            onlineEventsOnly: isOnline,
            query,
        })
            .then((response: any) =>
                dispatch(
                    updateSearchAutocompleteSuggestions(
                        parseSuggestions(response),
                    ),
                ),
            )
            .catch(() => {
                //Fail silently as this is prefilling the autocomplete dropdown
                //and run behind the scenes.
            });
    };

export const searchFilterAction =
    (searchFilter: {
        query: string;
        locationSlug?: string;
        actionType: string;
    }) =>
    (dispatch: Function, getState: Function) => {
        const { query, locationSlug, actionType } = searchFilter;

        const {
            user: { isAuthenticated },
            location: { bbox, placeId },
        } = getState();

        const locationString = trim(locationSlug) || 'local';
        const optionalParams = placeId ? {} : { bbox };

        const url = getCanonicalSearchUrl({
            slug: locationString,
            q: query,
            ...optionalParams,
        });

        dispatch(
            trackSearchHeader({
                query,
                locationSlug,
                actionType,
                loggedIn: isAuthenticated,
            }),
        );

        setWindowLocation(url);
    };
