import {
    DestinationEvent,
    transformDestinationEvent,
} from '@eventbrite/event-renderer';
import { deepKeysToSnake } from '@eventbrite/transformation-utils';
import flatten from 'lodash/flatten';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import take from 'lodash/take';
import takeRight from 'lodash/takeRight';
import {
    ENTITY_TYPE_MAP,
    EVENT_CARD_TYPE,
    PROFILE_CARD_TYPE,
} from '../../../../constants';
import {
    BUCKETS_NAMES,
    LIKED_EVENTS,
    ORGANIZERS_TO_FOLLOW,
    PRIMARY_BUCKET,
    SECONDARY_BUCKET,
} from '../../../../constants/constants';
import { FOR_YOU } from '../../../../constants/tabConfig';
import { ContentShape, OrganizerProfile } from '../../../../types/index';

export const getBucketsContent = ({
    entityContext,
    entities,
}: ContentShape) => {
    // buckets contains all dynamic buckets that are responsible for requesting their own content
    const buckets = [
        {
            key: ORGANIZERS_TO_FOLLOW,
            type: PROFILE_CARD_TYPE,
            bucketContent: {
                name: '',
                description:
                    'Follow the most popular local organizers and get notified when they create events.',
            },
        },
    ];

    // eventsBuckets contains all buckets recieved from the backend
    const eventBuckets = entityContext?.map(({ type, ...bucketContent }) => {
        let result: any = {
            type,
            ...bucketContent,
        };

        if (type === 'profile') {
            const bucketKey = 'profiles';
            const bucketsContent = bucketContent[bucketKey]?.map(
                (id?: string) => entities[id || ''],
            );

            const profiles = bucketsContent?.map(
                (profile: OrganizerProfile) => ({
                    id: profile.id,
                    name: profile.name,
                    url: profile.url,
                    followedByYou: profile.followedByYou,
                    profilePicture: profile?.image?.url,
                    numUpcomingEvents: profile.numUpcomingEvents,
                    numFollowers: profile.numFollowers,
                }),
            );

            result = {
                ...result,
                profiles,
            };
        }

        if (type === EVENT_CARD_TYPE) {
            const { name: bucketName, key: bucketKey } = bucketContent;
            const entityKey = ENTITY_TYPE_MAP[type || ''];

            if (!includes(BUCKETS_NAMES, bucketKey)) {
                // eslint-disable-next-line no-console
                console.warn('Missing Affiliate Code', {
                    bucketName,
                    bucketKey,
                });
            }

            if (bucketContent[entityKey]) {
                const bucketsContent = bucketContent[entityKey].map(
                    (id: string) => entities[id],
                );
                // FIXME: buckets has some internal transforms that make the API data camelCase
                // We revert that transformation to be consistent with the rest of the app
                // We should remove all this transformers in the future, since their overly complex
                const events = bucketsContent
                    .map(deepKeysToSnake)
                    .map(transformDestinationEvent);

                result = {
                    ...result,
                    events,
                };
            }
        }

        return result;
    });

    return flatten([buckets, eventBuckets]);
};

const NUMBER_OF_EVENTS_BEFORE_CUT = 8;

export const getFlatBucketContent = (
    tabKey: string,
    flatBucket?: {
        results: DestinationEvent[];
    },
) => {
    const events = flatBucket?.results || [];
    const primaryBucketKey = tabKey === FOR_YOU ? LIKED_EVENTS : PRIMARY_BUCKET;

    let eventBuckets: any = [];

    if (!isEmpty(events)) {
        const primaryBucketEvents = take(
            events,
            NUMBER_OF_EVENTS_BEFORE_CUT,
        ) as DestinationEvent[];

        const primaryBucketParsedEvents = primaryBucketEvents.map(
            transformDestinationEvent,
        );

        eventBuckets = [
            {
                events: primaryBucketParsedEvents,
                key: primaryBucketKey,
                name: 'bucketName',
                template: 'card_small_8',
                type: 'event',
            },
        ];

        if (
            events.length >= NUMBER_OF_EVENTS_BEFORE_CUT &&
            tabKey !== FOR_YOU
        ) {
            const secondaryBucketStartingPoint =
                events.length - NUMBER_OF_EVENTS_BEFORE_CUT;

            const secondaryBucketEvents = take(
                takeRight(events, secondaryBucketStartingPoint),
                17,
            ) as DestinationEvent[];

            const secondaryBucketParsedEvents = secondaryBucketEvents.map(
                transformDestinationEvent,
            );

            eventBuckets.push({
                events: secondaryBucketParsedEvents,
                key: SECONDARY_BUCKET,
                name: 'bucketName',
                template: 'card_small_8',
                type: 'event',
            });
        }
    }

    return eventBuckets;
};
