export const BREAKPOINT_SIZES = {
    SM: 'sm',
    MD: 'md',
    LG: 'LG',
    XL: 'XL',
};

export const CONTROL_POSITIONS = {
    TOP: 'top',
    BOTTOM: 'bottom',
    HIDDEN: 'hidden',
} as const;

export const SLIDE_DIRECTIONS = {
    NEXT: 'next',
    PREVIOUS: 'previous',
} as const;
