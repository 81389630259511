import { bboxShape } from '@eventbrite/discover-utils';
import { locationDuck } from '@eventbrite/redux-destination';
import { combineReducers } from 'redux';

const {
    actionTypes: { UPDATE_LOCATION },
    reducersByKey: {
        suggestions,
        slug,
        placeId,
        latitude,
        longitude,
        currentPlace,
        currentPlaceParent,
        country,
        placeType,
        isWaitingForLocation,
        isUsingCurrentLocation,
        locationDenied,
        isOnline,
    },
} = locationDuck;

export const bbox = (
    state: bboxShape | null = null,
    {
        type,
        payload,
    }: { type: string; payload?: { bbox: bboxShape; isOnline: boolean } },
) => {
    let nextState = state;

    if (type === UPDATE_LOCATION && (payload?.bbox || payload?.isOnline)) {
        nextState = payload.bbox;
    }

    if (type === UPDATE_LOCATION && !payload?.bbox) {
        nextState = null;
    }

    return nextState;
};

export default combineReducers({
    suggestions,
    slug,
    placeId,
    latitude,
    longitude,
    bbox,
    currentPlace,
    currentPlaceParent,
    country,
    placeType,
    isWaitingForLocation,
    isUsingCurrentLocation,
    locationDenied,
    isOnline,
    topSuggestions: (state = []) => state,
});
