import React from 'react';
import './NotificationHeader.scss';

const NotificationHeader = (props: {
    renderIcon?: (arg0: string) => void;
    containerClasses?: string;
    children?: JSX.Element;
}) => {
    return (
        <header className={props.containerClasses}>
            {props.renderIcon && props.renderIcon('notification-header__icon')}
            {props.children}
        </header>
    );
};

export default NotificationHeader;
