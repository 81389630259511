import { gettext } from '@eventbrite/i18n';
import loadable from '@loadable/component';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { DiscoverState } from '../../../../types';
import { ThingsToDoShelfState } from '../../../../types/ThingsToDoShelf';
import './ThingsToDoShelfContainer.scss';

const ThingsToDoShelf = loadable(
    () => import('../../../../components/ThingsToDoShelf'),
    {
        resolveComponent: (component: { ThingsToDoShelf: React.ReactNode }) =>
            component.ThingsToDoShelf,
    },
);

type ThingsToDoShelfSectionProps = ThingsToDoShelfState;

const ThingsToDoShelfSection = ({
    tags,
    header,
    locale,
    usesLanguageSubdirectory,
}: ThingsToDoShelfSectionProps) => {
    const localePath = usesLanguageSubdirectory && locale ? `/${locale}` : '';
    const shelfHeader = {
        title: gettext('Things to do around %(geo)s', {
            geo: header.placeName,
        }),
        url: `${localePath}${header.url}`,
    };

    const shelfTags = tags.map((tag) => {
        return {
            displayText: gettext('Things to do in %(geo)s', {
                geo: tag.placeName,
            }),

            linkUrl: `${tag.linkUrl.split('/ttd/')[0]}${localePath}/ttd/${
                tag.linkUrl.split('/ttd/')[1]
            }`,
        };
    });

    const showThingsToDoShelf = shelfTags.length > 0;

    return (
        <>
            {showThingsToDoShelf && (
                <ThingsToDoShelf
                    tags={shelfTags}
                    header={shelfHeader}
                    shelfClassName="homepage-ttd-shelf"
                />
            )}
        </>
    );
};

export const _mapStateToProps = (state: DiscoverState) => {
    return {
        tags: state.thingsToDoShelf.tags,
        header: state.thingsToDoShelf.header,
        locale: state.app.locale,
        usesLanguageSubdirectory:
            state.env.localeInfo.uses_language_subdirectory,
    };
};

export const ThingsToDoShelfContainer: FunctionComponent = connect(
    _mapStateToProps,
)(ThingsToDoShelfSection);
