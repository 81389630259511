export const GRID_TYPE = 'grid';
export const LIST_TYPE = 'list';
export const ONLINE_EVENTS = 'Online Events';

export const DESKTOP_DEFAULT_LAYOUT_CONFIG = {
    max: 16,
    bucketClassNames: 'feed-events-bucket__content',
    layout: {
        default: {
            containerClasses: [
                'eds-g-cell eds-g-cell--has-overflow eds-g-cell-6-12 eds-g-cell-mn-4-12 eds-g-cell-lg-3-12 feed__card-cell',
            ],
        },
    },
};

export const MOBILE_DEFAULT_LAYOUT_CONFIG = {
    max: 16,
    bucketClassNames: 'feed-events-bucket__content',
    layout: {
        default: {
            containerClasses: [
                'eds-g-cell eds-g-cell--has-overflow eds-g-cell-1-1 feed__card-cell',
            ],
        },
    },
};

export const LAYOUT_WIDTH_MAP = {
    odd: 792,
    even: 1272,
};
