export const isLocalStorageSupported = () => {
    if (typeof window !== 'undefined') {
        const testKey = 'test';
        try {
            const storage = window.localStorage;
            storage.setItem(testKey, '1');
            storage.removeItem(testKey);
            return true;
        } catch (error) {
            return false;
        }
    }

    return false;
};
