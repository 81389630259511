import { History, Location } from 'history';
import React from 'react';
import { match } from 'react-router-dom';
import { LocationProvider, RouteProvider, SearchProvider } from '../../context';

interface HomePageProvidersProps {
    match?: match;
    location?: Location;
    history?: History;
}

class HomePageProviders extends React.Component<HomePageProvidersProps> {
    render() {
        const { match, history, location } = this.props;

        return (
            <RouteProvider match={match} location={location} history={history}>
                <LocationProvider locationSlug="">
                    <SearchProvider
                        initialQuery={{
                            tags: [''],
                        }}
                    >
                        {this.props.children}
                    </SearchProvider>
                </LocationProvider>
            </RouteProvider>
        );
    }
}

export default HomePageProviders;
