import { FormattedEvent } from '@eventbrite/event-renderer';
import { $FixMe } from '@eventbrite/ts-utils';
import classNames from 'classnames';
import each from 'lodash/each';
import filter from 'lodash/filter';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import has from 'lodash/has';
import includes from 'lodash/includes';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';
import keys from 'lodash/keys';
import map from 'lodash/map';
import merge from 'lodash/merge';
import omit from 'lodash/omit';
import size from 'lodash/size';
import slice from 'lodash/slice';
import some from 'lodash/some';
import sortBy from 'lodash/sortBy';
import { BucketConfig } from '../../../types/index';

//TODO evaluate cases with events and bucket content assigned to $FixMe
// existing tests provide known bad data, mismatched types or coerce/mutate
// objects in a manner inconsistent with what TS allows for

const insertItem = (array: any[], index: number, item: any) => [
    // part of the array before the specified index
    ...array.slice(0, index),
    // inserted item
    item,
    // part of the array after the specified index
    ...array.slice(index),
];

const findPreviousBucketKey = (buckets: any, bucketConfig: BucketConfig) => {
    let existingKeys: any[] = [];

    if (bucketConfig && has(bucketConfig, 'orderAfter')) {
        const bucketKeys = map(buckets, 'key');

        /**
         * Intersection creates an array from two other arrays.
         * The key point is that the final order will be relative to the first
         * array we pass, so it makes sense here to use the orderAfter from the
         * config to ensure the final order is the one we choose.
         */
        existingKeys = intersection(bucketConfig.orderAfter, bucketKeys);
    }

    return existingKeys.length > 0 ? existingKeys[0] : existingKeys;
};

export const getBucketConfig = ({
    bucketConfig,
    defaultConfig,
}: {
    bucketConfig: BucketConfig;
    defaultConfig: $FixMe;
}) => merge({}, defaultConfig, bucketConfig);

export const getEventsLayout = ({
    events,
    bucketConfig,
}: {
    events: FormattedEvent[];
    bucketConfig: BucketConfig;
}) => {
    const { layout, max, propsOverwrite } = bucketConfig;

    let eventsToDisplay = slice(events, 0, max);
    const { containerClasses } = get(
        layout,
        size(eventsToDisplay),
        get(layout, 'default'),
    );

    if (!isEmpty(propsOverwrite)) {
        // eslint-disable-next-line no-shadow
        eventsToDisplay = map(eventsToDisplay, (event, index) => {
            const overwrite = get(
                propsOverwrite,
                index + 1,
                get(propsOverwrite, 'default'),
            );

            return {
                ...event,
                ...overwrite,
            };
        });
    }

    let eventsGroups = [];

    if (size(containerClasses) === 1) {
        eventsGroups = [
            {
                events: eventsToDisplay,
                containerClasses: classNames(containerClasses[0]),
            },
        ];
    } else {
        let lastIndex = 0;

        eventsGroups = map(containerClasses, (value) => {
            const amountEvents = +Object.keys(value)[0];
            const containerClass = value[amountEvents];

            const data = {
                events: slice(
                    eventsToDisplay,
                    lastIndex,
                    lastIndex + amountEvents,
                ),
                containerClasses: classNames(containerClass),
            };

            lastIndex += amountEvents;

            return data;
        });
    }

    return eventsGroups;
};

export const getBuckets = ({
    feedConfig,
    buckets,
    featureFlags,
}: {
    feedConfig: $FixMe;
    buckets: $FixMe;
    featureFlags?: $FixMe;
}) => {
    // eslint-disable-next-line complexity
    const allBuckets = buckets.reduce(
        (memo: any, { key, ...bucketProps }: { key: number }) => {
            let bucketConfig: BucketConfig = feedConfig[key || ''];

            let shouldRenderBucket = true;

            let hasOrderAfter = false;

            // if the bucket doesn't have key or config
            if (isEmpty(key) || isEmpty(bucketConfig)) {
                shouldRenderBucket = false;
            }

            // check FF
            if (bucketConfig && bucketConfig.featureFlagName) {
                shouldRenderBucket =
                    featureFlags?.[bucketConfig.featureFlagName] || false;
            }

            // check hide property
            if (bucketConfig && bucketConfig.hide) {
                shouldRenderBucket = false;
            }

            // overwrite bucketConfig
            if (
                bucketConfig &&
                has(bucketConfig, 'overwrite.featureFlagName')
            ) {
                const shouldOverwriteBucketProperties =
                    featureFlags?.[bucketConfig.overwrite.featureFlagName];

                if (
                    shouldOverwriteBucketProperties &&
                    has(bucketConfig, 'overwrite.props')
                ) {
                    bucketConfig = {
                        ...bucketConfig.overwrite.props,
                    };
                }
            }

            let bucketProperties = {
                ...bucketProps,
            };

            // insert orderAfter property
            const previousBucketKey = findPreviousBucketKey(
                buckets,
                bucketConfig,
            );

            const shouldAddOrderAfterProperty = !isEmpty(previousBucketKey);

            if (shouldAddOrderAfterProperty) {
                bucketProperties = {
                    ...bucketProperties,
                    orderAfter: previousBucketKey,
                };
                hasOrderAfter = true;
            }

            // insert order property
            const shouldAddOrderProperty =
                bucketConfig && has(bucketConfig, 'order') && !hasOrderAfter;

            if (shouldAddOrderProperty) {
                bucketProperties = {
                    ...bucketProperties,
                    order: bucketConfig.order,
                };
            }

            // overwrite bucket properties
            if (bucketConfig && has(bucketConfig, 'propsOverwrite')) {
                bucketProperties = {
                    ...bucketProperties,
                    ...bucketConfig.propsOverwrite,
                };
            }

            if (shouldRenderBucket) {
                return [
                    ...memo,
                    {
                        key,
                        ...bucketProperties,
                        bucketConfig,
                    },
                ];
            }

            return memo;
        },
        [],
    );

    // get all the buckets that have an order set and sort them
    let bucketWithOrder = filter(allBuckets, (bucket) => has(bucket, 'order'));

    bucketWithOrder = sortBy(bucketWithOrder, 'order');

    // get all the buckets that have an orderAfter set
    const bucketWithOrderAfter = filter(allBuckets, (bucket) =>
        has(bucket, 'orderAfter'),
    );

    // get the rest of the buckets
    let bucketWithoutOrder = filter(
        allBuckets,
        (bucket) => !has(bucket, 'order') && !has(bucket, 'orderAfter'),
    );

    // insert ordered buckets into unordered buckets
    each(bucketWithOrder, ({ order, ...bucketProps }) => {
        bucketWithoutOrder = insertItem(bucketWithoutOrder, order, {
            ...bucketProps,
        });
    });

    // insert buckets that have an orderAfter property
    each(bucketWithOrderAfter, ({ orderAfter, ...bucketProps }) => {
        const prevBucketOrder = findIndex(bucketWithoutOrder, {
            key: orderAfter,
        });

        bucketWithoutOrder = insertItem(
            bucketWithoutOrder,
            prevBucketOrder + 1,
            { ...bucketProps },
        );
    });

    return map(bucketWithoutOrder, (bucket) =>
        omit(bucket, ['order', 'orderAfter']),
    );
};

const getEmptyBucket = ({
    bucketType,
    bucketKey,
}: {
    bucketType: string;
    bucketKey: string;
}) => {
    if (bucketType === 'event') {
        return {
            type: 'event',
            key: bucketKey,
            events: [],
        };
    }

    return {};
};

export const buildBuckets = ({
    events,
    buckets,
    feedConfig,
    isCityBrowseLoading,
}: {
    events: $FixMe;
    buckets: $FixMe;
    feedConfig: $FixMe;
    isCityBrowseLoading?: boolean;
}) => {
    let eventsBucketsMerged = events;

    if (!isCityBrowseLoading) {
        eventsBucketsMerged = [...buckets, ...events];
    }

    // Get just the buckets that we need for the current tab
    const currentBucketKeys = keys(feedConfig);

    let currentBuckets = filter(eventsBucketsMerged, (bucket) =>
        includes(currentBucketKeys, bucket.key),
    );

    // Get a fictional bucket for every bucket that should have an empty state and it's not present on currentBuckets
    const emptyBucketsKeys = filter(
        currentBucketKeys,
        (bucketKey) =>
            some(
                feedConfig[bucketKey].emptyState,
                (es) => es.validation.shouldShowEmptyState,
            ) && !some(currentBuckets, { key: bucketKey }),
    );

    const currentEmptyBuckets = emptyBucketsKeys.map((key) =>
        getEmptyBucket({ bucketType: 'event', bucketKey: key }),
    );

    currentBuckets = [...currentBuckets, ...currentEmptyBuckets];

    return currentBuckets;
};
