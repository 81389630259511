const IMAGE_INTRINSIC_SIZES: { [key: string]: number } = {
    desktop: 1920,
};

export const makeImageSetString = (imageSet?: { [key: string]: any }) => {
    const defaultImage = imageSet?.mobile;
    const imageSetKeys = Object.keys(imageSet || {});

    const setString = imageSetKeys.reduce((acc, key) => {
        const fileName = imageSet?.[key];
        const width = IMAGE_INTRINSIC_SIZES[key];

        if (!width) {
            return acc;
        }

        return (acc += `${fileName} ${width}w,`);
    }, '');

    return {
        default: defaultImage,
        srcSet: setString.slice(0, setString.length - 1),
    };
};
