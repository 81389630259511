import { gettext } from '@eventbrite/i18n';

export const HEADER_BACKGROUNDS = [
    {
        images: {
            mobile: 'https://cdn.evbstatic.com/s3-build/perm_001/e02b88/django/images/homefeed/music-campaing-1-480-mobile.jpg',
            tablet: 'https://cdn.evbstatic.com/s3-build/perm_001/1570cc/django/images/homefeed/music-campaing-1-792-tablet.jpg',
            desktop:
                'https://cdn.evbstatic.com/s3-build/perm_001/bc5566/django/images/homefeed/music-campaing-1-1080-desktop.jpg',
            twok: 'https://cdn.evbstatic.com/s3-build/perm_001/b0cb35/django/images/homefeed/music-campaing-1-2600-large.jpg',
        },
        // Translators: {Artist} at {Venue}
        attribution: gettext('Superorganism at Great American Music Hall'),
    },
    {
        images: {
            mobile: 'https://cdn.evbstatic.com/s3-build/perm_001/697158/django/images/homefeed/music-campaing-2-480-mobile.jpg',
            tablet: 'https://cdn.evbstatic.com/s3-build/perm_001/622db6/django/images/homefeed/music-campaing-2-792-tablet.jpg',
            desktop:
                'https://cdn.evbstatic.com/s3-build/perm_001/c20048/django/images/homefeed/music-campaing-2-1080-desktop.jpg',
            twok: 'https://cdn.evbstatic.com/s3-build/perm_001/068221/django/images/homefeed/music-campaing-2-2600-large.jpg',
        },
        // Translators: {Artist} at {Venue}
        attribution: gettext('Superorganism at Great American Music Hall'),
    },
    {
        images: {
            mobile: 'https://cdn.evbstatic.com/s3-build/perm_001/745a9d/django/images/homefeed/music-campaing-3-480-mobile.jpg',
            tablet: 'https://cdn.evbstatic.com/s3-build/perm_001/ed52bc/django/images/homefeed/music-campaing-3-792-tablet.jpg',
            desktop:
                'https://cdn.evbstatic.com/s3-build/perm_001/81e52e/django/images/homefeed/music-campaing-3-1080-desktop.jpg',
            twok: 'https://cdn.evbstatic.com/s3-build/perm_001/6cc698/django/images/homefeed/music-campaing-3-2600-large.jpg',
        },
        // Translators: {Artist} at {Venue}
        attribution: gettext('Simpson at Great American Music Hall'),
    },
    {
        images: {
            mobile: 'https://cdn.evbstatic.com/s3-build/perm_001/8c11ab/django/images/homefeed/music-campaing-4-480-mobile.jpg',
            tablet: 'https://cdn.evbstatic.com/s3-build/perm_001/b56dcc/django/images/homefeed/music-campaing-4-792-tablet.jpg',
            desktop:
                'https://cdn.evbstatic.com/s3-build/perm_001/ee7095/django/images/homefeed/music-campaing-4-1080-desktop.jpg',
            twok: 'https://cdn.evbstatic.com/s3-build/perm_001/955f9b/django/images/homefeed/music-campaing-4-2600-large.jpg',
        },
        // Translators: {Artist} at {Venue}
        attribution: gettext('Helado Negro at The Chapel'),
    },
    {
        images: {
            mobile: 'https://cdn.evbstatic.com/s3-build/perm_001/78a760/django/images/homefeed/music-campaing-5-480-mobile.jpg',
            tablet: 'https://cdn.evbstatic.com/s3-build/perm_001/31294d/django/images/homefeed/music-campaing-5-792-tablet.jpg',
            desktop:
                'https://cdn.evbstatic.com/s3-build/perm_001/3640f4/django/images/homefeed/music-campaing-5-1080-desktop.jpg',
            twok: 'https://cdn.evbstatic.com/s3-build/perm_001/402b2d/django/images/homefeed/music-campaing-5-2600-large.jpg',
        },
        // Translators: {Artist} at {Venue}
        attribution: gettext('Finish Ticket at The Rickshaw Stop'),
    },
    {
        images: {
            mobile: 'https://cdn.evbstatic.com/s3-build/perm_001/5db47b/django/images/homefeed/music-campaing-6-480-mobile.jpg',
            tablet: 'https://cdn.evbstatic.com/s3-build/perm_001/a8d40d/django/images/homefeed/music-campaing-6-792-tablet.jpg',
            desktop:
                'https://cdn.evbstatic.com/s3-build/perm_001/c3af36/django/images/homefeed/music-campaing-6-1080-desktop.jpg',
            twok: 'https://cdn.evbstatic.com/s3-build/perm_001/d0969f/django/images/homefeed/music-campaing-6-2600-large.jpg',
        },
        // Translators: {Artist} at {Venue}
        attribution: gettext('Finish Ticket at The Rickshaw Stop'),
    },
];
