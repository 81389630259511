import * as React from 'react';

const PlaceholderSvg1Svg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 400 200" {...props}>
        <g>
            <path
                d="M0 100v100h33.493c32.653 0 33.498-.009 33.695-.378.181-.337 4.416-5.731 7.006-8.922 2.982-3.674 7.863-9.622 9.091-11.078.778-.923 1.638-1.958 1.911-2.3s1.803-2.142 3.4-3.999c1.597-1.858 3.078-3.613 3.292-3.9.213-.288.798-.979 1.3-1.536.501-.557 1.677-1.9 2.612-2.985s3.725-4.226 6.2-6.98 4.904-5.46 5.398-6.015c9.281-10.414 28.494-29.488 38.102-37.826.77-.668 2.705-2.366 4.3-3.773 1.595-1.408 3.215-2.793 3.6-3.079.385-.285 1.195-.964 1.8-1.507s1.471-1.265 1.924-1.605c.453-.339 1.818-1.427 3.033-2.417s3.082-2.475 4.148-3.3 2.276-1.77 2.69-2.1c.816-.651 4.615-3.483 5.783-4.312.397-.282 1.307-.933 2.022-1.447.715-.513 3.447-2.379 6.071-4.147 2.624-1.767 5.009-3.38 5.3-3.584 7.767-5.445 27.459-15.841 37.129-19.6.88-.342 1.78-.707 2-.811.22-.103.895-.373 1.5-.599s1.28-.492 1.5-.591 1.75-.65 3.4-1.224c10.443-3.632 18.909-5.903 27.9-7.483 1.045-.183 2.575-.454 3.4-.601s2.31-.367 3.3-.489 2.52-.311 3.4-.421c.88-.109 3.334-.328 5.454-.485 2.119-.158 3.937-.339 4.039-.402s4.488-.114 9.746-.114c17.454.001 28.281 1.113 43.68 4.487 2.055.45 6.335 1.658 9.239 2.608a1182.9 1182.9 0 003.942 1.282 52.677 52.677 0 014.5 1.728c.33.148 1.23.516 2 .817s2.525 1.086 3.9 1.743 2.995 1.416 3.6 1.685c2.332 1.039 7.255 4.09 12.684 7.86 9.535 6.621 21.162 19.983 26.361 30.295.47.933.923 1.698 1.005 1.701.083.002.15-23.441.15-52.096V0H0v100m259.4 69.926c-10.261.916-20.037 6.145-32.9 17.598-3.333 2.967-9.751 9.364-11.469 11.431l-.868 1.045H286.048l-.105-4.45c-.286-12.111-3.781-19.218-11.332-23.044-3.869-1.961-10.17-3.029-15.211-2.58"
                fill="#fbfcfc"
            />
            <path
                d="M283.6 50.027c-7.287.189-15.912 1.011-21.8 2.077-.935.169-2.42.435-3.3.591-6.612 1.168-14.43 3.189-22.4 5.79-2.048.668-7.335 2.515-7.8 2.724-.22.099-.895.365-1.5.591s-1.28.496-1.5.599c-.22.104-1.12.469-2 .811-9.67 3.759-29.362 14.155-37.129 19.6-.291.204-2.676 1.817-5.3 3.584-2.624 1.768-5.356 3.634-6.071 4.147-.715.514-1.625 1.165-2.022 1.447-1.168.829-4.967 3.661-5.783 4.312-.414.33-1.624 1.275-2.69 2.1s-2.933 2.31-4.148 3.3-2.58 2.078-3.033 2.417c-.453.34-1.319 1.062-1.924 1.605s-1.415 1.222-1.8 1.507c-.385.286-2.005 1.671-3.6 3.079a940.176 940.176 0 01-4.3 3.773c-9.608 8.338-28.821 27.412-38.102 37.826-.494.555-2.923 3.261-5.398 6.015s-5.265 5.895-6.2 6.98-2.111 2.428-2.612 2.985c-.502.557-1.087 1.248-1.3 1.536-.214.287-1.695 2.042-3.292 3.9-1.597 1.857-3.127 3.657-3.4 3.999s-1.133 1.377-1.911 2.3c-1.228 1.456-6.109 7.404-9.091 11.078-2.59 3.191-6.825 8.585-7.006 8.922-.198.37 1.256.378 73.375.378h73.577l1.076-1.25c2.078-2.415 7.919-8.224 11.284-11.223 15.402-13.727 27.209-18.967 39.3-17.439 13.66 1.725 19.765 9.442 20.143 25.462l.105 4.45H400v-47.883c0-26.336-.071-47.914-.157-47.95-.087-.037-.553-.832-1.036-1.767-5.476-10.591-16.811-23.595-26.323-30.2-5.429-3.77-10.352-6.821-12.684-7.86-.605-.269-2.225-1.028-3.6-1.685s-3.13-1.442-3.9-1.743-1.67-.669-2-.817a52.677 52.677 0 00-4.5-1.728 1182.9 1182.9 0 01-3.942-1.282c-2.904-.95-7.184-2.158-9.239-2.608-16.522-3.62-30.853-4.921-49.019-4.45"
                fill="#ececf3"
            />
        </g>
    </svg>
);

PlaceholderSvg1Svg.displayName = 'PlaceholderSvg1Svg';
export default PlaceholderSvg1Svg;
