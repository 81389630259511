import { SimpleCarousel } from '@eventbrite/collection-carousel';
import { gettext } from '@eventbrite/i18n';
import React from 'react';
import { TopDestinations } from '../../../../types';
import TopDestinationCard from '../../components/TopDestinationCard';
import useTopDestinations from '../../hooks/TopDestinations';
import './TopDestinations.scss';

const TopDestinationsHeader = ({
    countryDisplayName,
}: {
    countryDisplayName: string | null;
}) => (
    <h2 className="eds-text-color--grey-900 eds-text-hs">
        {/* Translators: the full sentence will be like "Top destinations in Germany"*/}
        {countryDisplayName
            ? gettext('Top destinations in %(countryDisplayName)s', {
                  countryDisplayName,
              })
            : gettext('Top destinations')}
    </h2>
);

export const TopDestinationsContainer = (props: {
    ebDomain: string;
    localizedCountryName: string;
    languageSubdirectory: string;
    topDestinations?: TopDestinations;
}) => {
    const [countryDisplayName, topDestinations] = useTopDestinations(
        props.ebDomain,
        props.localizedCountryName,
        props.topDestinations,
    );

    return (
        <div className="top-destinations-container">
            <SimpleCarousel
                slidesVisible={{ desktop: 4, tablet: 2 }}
                headerTitle={
                    <TopDestinationsHeader
                        countryDisplayName={countryDisplayName}
                    />
                }
            >
                {topDestinations?.map(
                    ({ locationSlug, displayName, imageUrl }, index) => {
                        return (
                            <TopDestinationCard
                                key={`${locationSlug}-${index}`}
                                locationSlug={locationSlug}
                                displayName={displayName}
                                imageUrl={imageUrl}
                                languageSubdirectory={
                                    props.languageSubdirectory
                                }
                                imageLoading={'lazy'}
                            />
                        );
                    },
                )}
            </SimpleCarousel>
        </div>
    );
};
