import { searchPromotedEvents } from '@eventbrite/ads';

type SeachPromotedEventsForYouPayload = {
    location: LocationInfo;
    subInterface: string;
    numberOfEvents: number;
};

export async function searchPromotedEventsForYou({
    location,
    subInterface,
    numberOfEvents,
}: SeachPromotedEventsForYouPayload) {
    const adSlots =
        subInterface === 'holistic_inperson_events'
            ? Math.ceil(numberOfEvents / 4)
            : 2;

    return searchPromotedEvents({
        slots: adSlots,
        page: 1,
        interface: 'homepage',
        sub_interface: subInterface,
        search: {
            dates: ['current_future'],
            place: transformLocationToAdsPayload(location),
        },
    });
}

type LocationInfo = {
    placeId?: string | null;
    latitude?: number;
    longitude?: number;
    isOnline?: boolean;
};

export function transformLocationToAdsPayload({
    placeId,
    latitude,
    longitude,
    isOnline,
}: LocationInfo) {
    if (isOnline) {
        return {
            is_online: true,
        };
    }

    if (!isOnline && latitude && longitude) {
        return {
            geo_point: {
                lat: latitude,
                lon: longitude,
                radius: 50,
            },
            is_online: false,
        };
    }

    return {
        is_online: false,
        place_id: placeId ?? undefined,
    };
}
