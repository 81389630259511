import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getAdditionalProps } from '@eventbrite/eds-utils';
import './link.scss';
import * as constants from './constants';

/**
 * Basic Link tag to wrap hyperlinks
 * @augments {React.PureComponent<{style?: 'neutral' | 'inverse' | string, to: string}>}
 */
export default class Link extends PureComponent {
    static propTypes = {
        /**
         * Link href
         */
        to: PropTypes.string.isRequired,
        /**
         * Link supports children
         */
        children: PropTypes.node,
        /**
         * Style for this link, can choose between
         * neutral, and inverse
         */
        style: PropTypes.oneOf(constants.STYLE_LIST_PROP),
    };

    /**
     * Returns a string of classnames
     * @private
     * @returns {string}
     */
    _getClassNames() {
        const { style } = this.props;

        const classes = {
            [constants.STYLE_CLASSES[style]]: style,
        };

        return classNames('eds-link', classes);
    }

    render() {
        const { to, children } = this.props;

        const extraProps = getAdditionalProps(this);

        return (
            <a
                data-spec="eds-link"
                {...extraProps}
                href={to}
                className={this._getClassNames()}
            >
                {children}
            </a>
        );
    }
}
