import { gettext } from '@eventbrite/i18n';
import { Typography } from '@eventbrite/marmalade';
import React from 'react';
import './TopDestinationCard.scss';

type TopDestinationCardProps = {
    displayName: string;
    imageUrl: string;
    locationSlug: string;
    languageSubdirectory: string;
    imageLoading: 'lazy' | 'eager';
};

export const TopDestinationCard = (props: TopDestinationCardProps) => {
    const {
        displayName,
        imageUrl,
        locationSlug,
        languageSubdirectory,
        imageLoading = 'lazy',
    } = props;

    let linkHref = `/d/${locationSlug}/events/`;
    linkHref = languageSubdirectory
        ? '/' + languageSubdirectory + linkHref
        : linkHref;

    return (
        <div className="top-destination-card">
            <a
                href={linkHref}
                aria-labelledby={gettext('Browse events for %(displayName)s', {
                    displayName,
                }).toString()}
                color="neutral-100"
            >
                <img
                    className="top-destination-card__image"
                    src={imageUrl}
                    loading={imageLoading}
                    alt={gettext(
                        'An image of %(displayName)s super imposed with the text "%(displayName)s"',
                        { displayName },
                    ).toString()}
                />
                <div className="top-destination-card__overlay">
                    <Typography
                        color="neutral-100"
                        variant="heading-md"
                        align="left"
                    >
                        {gettext('%(displayName)s', { displayName })}
                    </Typography>
                </div>
                <div className="top-destination-card__gradiant" />
                <div className="top-destination-card__orange-bar eds-bg-color--ui-orange"></div>
            </a>
        </div>
    );
};
