import { BREAKPOINT_SIZES } from './constants';

/**
 * Custom defaults for breakpoints based on carousel's container and not browser window
 */

export interface DefaultsProps {
    size: string;
    visibleSegments: number;
    rootPaddingTop: number;
    rootPaddingRight: number;
    rootPaddingBottom: number;
    rootPaddingLeft: number;
    headerGap: number;
    footerGap: number;
    trackColumns: string;
    segmentWidth: number;
    syncedPadding: number;
    hideOverflow: boolean;
    shouldPaginate: boolean;
    controlPosition: 'top' | 'bottom' | 'hidden';
}

export const initDefaults: DefaultsProps = {
    size: BREAKPOINT_SIZES.SM,
    visibleSegments: 1,
    rootPaddingTop: 16,
    rootPaddingRight: 16,
    rootPaddingBottom: 16,
    rootPaddingLeft: 16,
    headerGap: 16,
    footerGap: 16,
    trackColumns: 'repeat(1, 1fr)',
    segmentWidth: 328,
    syncedPadding: 8,
    hideOverflow: false,
    shouldPaginate: true,
    controlPosition: 'bottom',
};

export function getRootBreakpointDefaults(rootWidth: number): DefaultsProps {
    if (rootWidth <= 639) {
        return {
            ...initDefaults,
        };
    }
    if (rootWidth > 639 && rootWidth <= 959) {
        return {
            size: BREAKPOINT_SIZES.MD,
            visibleSegments: 2,
            rootPaddingTop: 24,
            rootPaddingRight: 48,
            rootPaddingBottom: 24,
            rootPaddingLeft: 48,
            headerGap: 16,
            footerGap: 16,
            trackColumns: 'repeat(2, 1fr)',
            segmentWidth: 328,
            syncedPadding: 8,
            hideOverflow: false,
            shouldPaginate: true,
            controlPosition: 'top',
        };
    }
    if (rootWidth > 959 && rootWidth < 1200) {
        return {
            size: BREAKPOINT_SIZES.LG,
            visibleSegments: 3,
            rootPaddingTop: 32,
            rootPaddingRight: 48,
            rootPaddingBottom: 32,
            rootPaddingLeft: 48,
            headerGap: 32,
            footerGap: 32,
            trackColumns: 'repeat(3, 1fr)',
            segmentWidth: 328,
            syncedPadding: 8,
            hideOverflow: false,
            shouldPaginate: true,
            controlPosition: 'top',
        };
    }

    if (rootWidth >= 1200) {
        return {
            size: BREAKPOINT_SIZES.XL,
            visibleSegments: 4,
            rootPaddingTop: 40,
            rootPaddingRight: 72,
            rootPaddingBottom: 40,
            rootPaddingLeft: 72,
            headerGap: 32,
            footerGap: 32,
            trackColumns: 'repeat(4, 1fr)',
            segmentWidth: 328,
            syncedPadding: 8,
            hideOverflow: false,
            shouldPaginate: true,
            controlPosition: 'top',
        };
    }

    return initDefaults;
}
