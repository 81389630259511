import { HAS_WINDOW } from '@eventbrite/feature-detection';
import { ALL_TAB, FOR_YOU, SEASONAL_TAB } from '../../../constants/tabConfig';

const SESSION_STORAGE_HOMEFEED_KEY = 'EBHomeFeedState';
const SESSION_STORAGE_ORGANIZER_TO_FOLLOW = 'OrganizerToFollow';

/**
 * Stores in sessionStorage the info require to restore the state of home-feed
 * @param {string} tabKey
 * @param {string} tabKeyLabel
 */
export const storeHomeFeedState = ({
    tabKey,
    tabKeyLabel,
}: {
    [key: string]: string;
}) => {
    if (HAS_WINDOW) {
        try {
            sessionStorage.setItem(
                SESSION_STORAGE_HOMEFEED_KEY,
                JSON.stringify({
                    tabKey,
                    tabKeyLabel,
                }),
            );
        } catch (error) {
            // in production, we silently fail and drop the history feature
            if (process.env.NODE_ENV === 'development') {
                throw error;
            }
        }
    }
};

/**
 * Returns the state of home-feed.
 * Returns:
 * {tabKey (string)}
 */
export const retrieveHomeFeedState = ({
    seasonalContent,
    isAuthenticated,
}: any = {}) => {
    let response = {
        tabKey: isAuthenticated ? FOR_YOU : ALL_TAB,
        tabKeyLabel: isAuthenticated ? FOR_YOU : ALL_TAB,
    };

    if (HAS_WINDOW) {
        let homeFeedState;

        try {
            homeFeedState = sessionStorage.getItem(
                SESSION_STORAGE_HOMEFEED_KEY,
            );
        } catch (error) {
            // in production, we silently fail and drop the history feature
            if (process.env.NODE_ENV === 'development') {
                throw error;
            }
        }

        if (homeFeedState) {
            homeFeedState = JSON.parse(homeFeedState);
            const shouldShowSeasonalTab =
                homeFeedState.tabKey === SEASONAL_TAB &&
                seasonalContent.byDate &&
                seasonalContent.byDate.key;
            const shouldReturnPreviousTab =
                homeFeedState.tabKey !== SEASONAL_TAB || shouldShowSeasonalTab;

            if (shouldReturnPreviousTab) {
                response = homeFeedState;
            }
        }
    }

    return response;
};

/**
 * Stores in sessionStorage the organizer id that the user will follow once he's logged in
 * @param {string} organizerToFollow
 */
export const storeOrganizerToFollow = ({
    organizerToFollow,
}: {
    organizerToFollow: string;
}) => {
    if (HAS_WINDOW) {
        try {
            sessionStorage.setItem(
                SESSION_STORAGE_ORGANIZER_TO_FOLLOW,
                organizerToFollow,
            );
        } catch (error) {
            // in production, we silently fail and drop the history feature
            if (process.env.NODE_ENV === 'development') {
                throw error;
            }
        }
    }
};

export const retrieveOrgToFollow = () => {
    let orgToFollow;

    if (HAS_WINDOW) {
        try {
            orgToFollow = sessionStorage.getItem('OrganizerToFollow');
        } catch (error) {
            // in production, we silently fail and drop the history feature
            if (process.env.NODE_ENV === 'development') {
                throw error;
            }
        }
    }
    return orgToFollow;
};

export const removeOrgToFollow = () => {
    try {
        sessionStorage.removeItem('OrganizerToFollow');
    } catch (error) {
        // in production, we silently fail and drop the history feature
        if (process.env.NODE_ENV === 'development') {
            throw error;
        }
    }
};
