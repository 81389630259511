import momentTimezone from 'moment-timezone';

//mometimezone caches this value once called once.
//safe to call multiple times with no worries
//https://momentjs.com/timezone/docs/#/using-timezones/guessing-user-timezone/
const getUserTimezone = () => momentTimezone.tz.guess();

export const getLocalizedTime = (utcTimeStamp: any) => {
    const userTimeZone = getUserTimezone();
    const localizedStartTime = momentTimezone
        .utc(utcTimeStamp)
        .tz(userTimeZone);

    return localizedStartTime;
};

export const getCurrentLocalTime = () => {
    const userTimeZone = getUserTimezone();
    return momentTimezone.utc().tz(userTimeZone);
};

export const checkIfEventLive = (localizedStartTimeString: any) => {
    const currentTime = getCurrentLocalTime();
    const localizedDateObject = momentTimezone.utc(localizedStartTimeString);

    return localizedDateObject.valueOf() < currentTime.valueOf();
};

export const getTimeUntilStart = (
    localizedEventString: string,
    timeFormat = 'minutes',
) => {
    const currentTime = getCurrentLocalTime();
    const localizedDateObject = momentTimezone.utc(localizedEventString);

    return currentTime.diff(localizedDateObject, timeFormat) * -1;
};
