/*
 * The payload of these actions intend to set the isLoading flag needed for each of the actions.
 * Adding more payload here will affect the `loadingState` reducer on `feed/src/redux/app/reducer.js`
 * and will generate an incorrect state set to the store.
 */
export const CITY_BROWSE_LOADING_KEY = 'isCityBrowseLoading';
export const SET_CITY_BROWSE_LOADING = 'SET_CITY_BROWSE_LOADING';

export const setCityBrowseLoading = (isLoading: boolean) => ({
    type: SET_CITY_BROWSE_LOADING,
    payload: {
        [CITY_BROWSE_LOADING_KEY]: isLoading,
    },
});

export const CITY_BROWSE_CALL_SUCCESS = 'CITY_BROWSE_CALL_SUCCESS';
export const setCityBrowseCallAsSuccess = () => ({
    type: CITY_BROWSE_CALL_SUCCESS,
    payload: {
        [CITY_BROWSE_LOADING_KEY]: false,
    },
});

export const CITY_BROWSE_CALL_ONGOING = 'CITY_BROWSE_CALL_ONGOING';
export const setCityBrowseCallAsOngoing = () => ({
    type: CITY_BROWSE_CALL_ONGOING,
    payload: {
        [CITY_BROWSE_LOADING_KEY]: true,
    },
});

export const CITY_BROWSE_CALL_FAILED = 'CITY_BROWSE_CALL_FAILED';
export const setCityBrowseCallAsFailed = () => ({
    type: CITY_BROWSE_CALL_FAILED,
    payload: {
        [CITY_BROWSE_LOADING_KEY]: false,
    },
});

// All the calls that are define here and will be used in feed/src/redux/app/reducer.js to set loading states.
export const CITY_BROWSE_CALLS_WITH_LOADING = [
    SET_CITY_BROWSE_LOADING,
    CITY_BROWSE_CALL_SUCCESS,
    CITY_BROWSE_CALL_ONGOING,
    CITY_BROWSE_CALL_FAILED,
];
