import { Location } from '@eventbrite/discover-utils';
import React, { Component } from 'react';
import { DateFilter } from '../../../../constants/dates';
import withStickyBehavior from '../../hoc/withStickyBehavior';
import './StickyTabs.scss';
import Tabs from './Tabs';

const NABVAR_HEIGHT = 72;

interface StickyTabsProps {
    items: {
        value: number;
        displayName?: React.ReactNode;
        content?: JSX.Element;
        isSelected?: boolean;
    }[];
    onTabChange?: Function;
    onTabLoad?: Function;
    onLocationChange?: Function;
    initialSelectedTab?: string;
    location: Location;
    dateFilter?: DateFilter;
}

class StickyTabs extends Component<StickyTabsProps> {
    render() {
        const {
            items,
            onTabChange,
            onTabLoad,
            onLocationChange,
            initialSelectedTab,
            location,
            dateFilter,
        } = this.props;

        return (
            <Tabs
                items={items}
                onTabChange={onTabChange}
                onTabLoad={onTabLoad}
                onLocationChange={onLocationChange}
                initialSelectedTab={initialSelectedTab}
                location={location}
                dateFilter={dateFilter}
            />
        );
    }
}

export default withStickyBehavior(StickyTabs, { topAdjust: NABVAR_HEIGHT });
