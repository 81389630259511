import { GenericLazyString } from '@eventbrite/i18n';
import React from 'react';
import { useDebouncedCallback } from 'use-debounce';
import CONSTANTS from './common/default.constants';
import { Tag } from './components/Tag';
import { getTagRows } from './utils/util.functions';

import './Tags.scss';

/**
 * Tag specific properties
 */
export interface TagObject {
    /**
     * Display Text for the Tag
     */
    displayText: string | GenericLazyString;
    /**
     * URL to which the Tag should link to
     */
    linkUrl: string;
}

export interface TagsProps {
    /**
     * List of object containing tag specific properties (displayText, linkUrl)
     * eg. tags = [{ displayValue: 'name', linkUrl: '/' }]
     */
    tags: Array<TagObject>;
    /**
     * BackgroundColor of Tags component
     * Default: Grey [light]/ui-100
     */
    backgroundColor?: string;
    /**
     * BackgroundColor of individual tag
     * Default: White
     */
    tagColor?: string;
    /**
     * BackgroundColor of a Tag on Hover
     * Default: Grey [light]/ui-200
     */
    tagHoverColor?: string;
    /**
     * Icon to be shown beside Tag Label
     * Default: Arrow-up-right (↗)
     */
    icon?: JSX.Element | React.ReactNode;
    /**
     * Custom CSS Classname to be applied to Tags div component
     */
    className?: string;
}

/**
 * Tags Component used to display a list of Tags
 * It supports custom background color, tag color and custom icon.
 */
export const Tags: React.FunctionComponent<TagsProps> = ({
    tags,
    backgroundColor = CONSTANTS.DEFAULT_COMPONENT_BACKGROUND_COLOR,
    tagColor = CONSTANTS.DEFAULT_TAG_BACKGROUND_COLOR,
    tagHoverColor = CONSTANTS.DEFAULT_TAG_HOVER_COLOR,
    icon = CONSTANTS.DEFAULT_ICON,
    className = '',
}) => {
    const [windowWidth, setWindowWidth] = React.useState<number>(0);

    const resizeHandler = useDebouncedCallback(
        () => setWindowWidth(window.innerWidth),
        200,
    );

    React.useEffect(() => {
        /**
         * This useEffect sets a event listener for window size to handle the responsive UI for Tags component
         * With Resize of window the number of tags in a row change
         */
        setWindowWidth(window.innerWidth);
        window.addEventListener('resize', resizeHandler);

        /* useEffect cleaup */
        /* istanbul ignore next */
        return () => window.removeEventListener('resize', resizeHandler);
    }, [resizeHandler]);

    /**
     * Stores the input tags in the form of rows
     */
    const tagRows: Array<TagObject[]> = React.useMemo<
        Array<TagObject[]>
    >(() => {
        return getTagRows(tags, windowWidth);
    }, [tags, windowWidth]);

    return (
        <div className={`infra-tags ${className}`} style={{ backgroundColor }}>
            {tagRows.map((row, index) => (
                <div key={index} className="infra-tags__row">
                    {row.map((tag: TagObject) => (
                        <div key={tag.linkUrl}>
                            <Tag
                                displayText={tag.displayText}
                                linkUrl={tag.linkUrl}
                                backgroundColor={tagColor}
                                hoverColor={tagHoverColor}
                                icon={icon}
                            />
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};
