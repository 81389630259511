import React from 'react';

/**
 * Chunky Arrow Up Right (↗)
 * @returns ↗ SVG
 */
export const ArrowUpRight: React.FunctionComponent = () => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.2907 3.61849V3.61719H6.2168V4.93584H10.0845L3.57227 11.3512L4.51877 12.2836L11.1224 5.7782V9.74552H12.461V3.61849H12.2907Z"
            fill="#6F7287"
        />
    </svg>
);
