import { UPDATE_CONTENT, type EventSearch } from '@eventbrite/discover-utils';
import { FormattedEvent } from '@eventbrite/event-renderer';
import { locationDuck } from '@eventbrite/redux-destination';
import { $FixMe } from '@eventbrite/ts-utils';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import uniq from 'lodash/uniq';
import { combineReducers } from 'redux';
import {
    LIKED_EVENTS,
    UPDATE_FEATURED_EVENTS,
} from '../../../../constants/constants';
import { FOR_YOU } from '../../../../constants/tabConfig';
import { EntityContext } from '../../../../types/index';
import { HOME_PAGE_CATEGORY_CAROUSEL } from '../../constants/category';
import { getSeasonalContent } from '../../utils';
import {
    UPDATE_EVENT_SAVED_BY_YOU,
    UPDATE_LIKES_EVENT_BUCKET,
    UPDATE_TAB_CONTENT,
} from '../events/actions';
import { UPDATE_CAROUSEL_CATEGORY_STATE, UPDATE_TAB_KEY } from './actions';

const {
    actionTypes: { UPDATE_LOCATION },
} = locationDuck;

// eslint-disable-next-line
const entityContext = (
    state: any[] = [],
    {
        type,
        payload,
    }: {
        type: string;
        payload?: {
            eventId: string;
            savedByYou: string[];
            eventIds: string[];
            entityContext: EntityContext[];
        };
    },
) => {
    let nextState = state;

    if (type === UPDATE_EVENT_SAVED_BY_YOU) {
        const { eventId, savedByYou }: any = payload;

        nextState = map(nextState, (bucket) => {
            // special case for liked_events bucket
            if (bucket.key === LIKED_EVENTS) {
                if (savedByYou) {
                    return {
                        ...bucket,
                        events: uniq([eventId, ...bucket.events]),
                    };
                }
                return {
                    ...bucket,
                    // eslint-disable-next-line no-shadow
                    events: filter(bucket.events, (event) => event !== eventId),
                };
            }

            return bucket;
        });
    }

    if (type === UPDATE_LIKES_EVENT_BUCKET) {
        const { eventIds }: any = payload;

        nextState = map(nextState, (bucket) => {
            // special case for liked_events bucket
            if (bucket.key === LIKED_EVENTS) {
                return {
                    ...bucket,
                    events: uniq([...bucket.events, ...eventIds]),
                };
            }

            return bucket;
        });
    }

    if (type === UPDATE_CONTENT) {
        // remove all the buckets but liked_events bucket
        const likedEventsBucket = filter(nextState, { key: LIKED_EVENTS });

        nextState = payload
            ? [...likedEventsBucket, ...payload.entityContext]
            : [...likedEventsBucket];
    }

    return nextState;
};

// eslint-disable-next-line
const entities = (
    state: any = {},
    {
        type,
        payload,
    }: {
        type: string;
        payload?: {
            events: FormattedEvent[];
            eventId: string;
            savedByYou: string[];
            entities: $FixMe;
        };
    },
) => {
    let nextState = state;

    if (type === UPDATE_EVENT_SAVED_BY_YOU) {
        const { eventId, savedByYou }: any = payload;

        if (nextState[eventId]) {
            nextState = {
                ...nextState,
                [eventId]: {
                    ...nextState[eventId],
                    saves: {
                        savedByYou,
                    },
                },
            };
        }
    }

    if (type === UPDATE_LIKES_EVENT_BUCKET) {
        const { events }: any = payload;

        nextState = {
            ...nextState,
            ...events,
        };
    }

    if (type === UPDATE_CONTENT) {
        nextState = payload
            ? {
                  ...nextState,
                  ...payload.entities,
              }
            : { ...nextState };
    }

    return nextState;
};

const flatBucket = (
    state: any[] = [],
    {
        type,
        payload,
    }: {
        type: string;
        payload?: {
            events: FormattedEvent[];
            eventSearch: EventSearch;
            follow: string[];
            eventId: string;
            tabKey: string;
            savedByYou: string;
        };
    },
) => {
    let nextState: any = state;

    if (type === UPDATE_TAB_CONTENT) {
        const results = payload?.events;
        const eventSearch = payload?.eventSearch;
        const follow = payload?.follow;

        nextState = {
            results,
            eventSearch,
            follow,
        };
    }

    if (type === UPDATE_EVENT_SAVED_BY_YOU) {
        const { results } = nextState;
        const { eventId, tabKey, savedByYou }: any = payload;

        if (!isEmpty(results)) {
            const _results = results.map((event: { id: string }) => {
                let _savedByYou = {};

                if (event.id === eventId) {
                    _savedByYou = {
                        saves: {
                            saved_by_you: savedByYou,
                        },
                    };
                }
                return {
                    ...event,
                    ..._savedByYou,
                };
            });

            let filteredEvents = _results;

            if (tabKey && tabKey === FOR_YOU) {
                filteredEvents = _results.filter(
                    (event: { id: string }) =>
                        !savedByYou && event.id !== eventId,
                );
            }

            nextState = {
                ...nextState,
                results: filteredEvents,
            };
        }
    }

    return nextState;
};

const featuredResults = (
    state: any[] = [],
    {
        type,
        payload,
    }: {
        type: string;
        payload?: {
            events: FormattedEvent[];
        };
    },
) => {
    let nextState: any = state;

    if (type === UPDATE_FEATURED_EVENTS) {
        const results = payload?.events;
        nextState = results;
    }
    return nextState;
};

const browseState = (
    state = {},
    {
        type,
        payload,
    }: { type: string; payload?: { tabKey: string; tabKeyLabel: string } },
) => {
    let nextState = state;

    if (type === UPDATE_TAB_KEY) {
        nextState = {
            tabKey: payload?.tabKey,
            tabKeyLabel: payload?.tabKeyLabel,
        };
    }

    return nextState;
};

const seasonal = (
    state = {},
    { type, payload }: { type: string; payload?: { placeId: string } },
) => {
    let nextState = state;

    if (type === UPDATE_LOCATION) {
        nextState = getSeasonalContent({ placeId: payload?.placeId });
    }

    return nextState;
};

const carouselCategoryState = (
    state = {
        carouselState: new Array(HOME_PAGE_CATEGORY_CAROUSEL.length).fill(true),
        isCarouselCategory: true,
    },
    {
        type,
        payload,
    }: {
        type: string;
        payload?: { categoryIndex: number; categoryState: boolean };
    },
) => {
    let nextState = state;
    if (type === UPDATE_CAROUSEL_CATEGORY_STATE && payload) {
        const { categoryIndex, categoryState } = payload;
        const updatedCategoryState = [...nextState.carouselState];
        updatedCategoryState[categoryIndex] = categoryState;
        nextState = {
            ...nextState,
            carouselState: updatedCategoryState,
            isCarouselCategory: updatedCategoryState.some(
                (value) => value === true,
            ),
        };
    }
    return nextState;
};

export default combineReducers({
    entityContext,
    entities,
    flatBucket,
    browseState,
    seasonal,
    featuredResults,
    carouselCategoryState,
});
