import React, { useContext } from 'react';

import classNames from 'classnames';

import { ArrowLeftIcon, ArrowRightIcon, Button } from '@eventbrite/marmalade';

import { SLIDE_DIRECTIONS } from './constants';
import { SegmentedCarouselContext } from './context';
import styles from './SegmentedCarousel.module.scss';

export const SegmentedCarouselControls: React.FC = (): JSX.Element => {
    const { setSlideTo, syncedPadding, isFirstPage, isLastPage } = useContext(
        SegmentedCarouselContext,
    );

    const controlsWrapperClasses = classNames(
        styles.controls,

        'segmented-carousel__controls__wrapper',
        {
            'first-page': isFirstPage,
            'last-page': isLastPage,
        },
    );
    const controlsPreviousClasses = classNames(
        styles.controlsPrevious,
        'segmented-carousel__controls__previous-btn segmented-carousel__controls__btns',
        {
            [styles.disabledControl]: isFirstPage,
        },
    );
    const controlsNextClasses = classNames(
        styles.controlsNext,
        'segmented-carousel__controls__next-btn segmented-carousel__controls__btns',
        {
            [styles.disabledControl]: isLastPage,
        },
    );

    // handles tabbing
    const handleKeyUp = ({
        e,
        direction,
    }: {
        e: React.KeyboardEvent<HTMLButtonElement>;
        direction: 'next' | 'previous';
    }) => {
        if (e.target instanceof Element && e.key === 'Enter') {
            e.preventDefault();
            setSlideTo?.(direction);
        }
    };

    return (
        <div
            data-testid="segmented-carousel-controls"
            className={controlsWrapperClasses}
            style={{
                paddingLeft: syncedPadding,
            }}
        >
            <Button
                data-testid="segmented-carousel-controls-prev-btn"
                aria-label="Show previous"
                className={controlsPreviousClasses}
                onClick={() => setSlideTo?.(SLIDE_DIRECTIONS.PREVIOUS)}
                onKeyUp={(e) =>
                    handleKeyUp({ e, direction: SLIDE_DIRECTIONS.PREVIOUS })
                }
                disabled={isFirstPage}
            >
                <ArrowLeftIcon size="medium" />
            </Button>
            <Button
                data-testid="segmented-carousel-controls-next-btn"
                aria-label="Show next"
                className={controlsNextClasses}
                onClick={() => setSlideTo?.(SLIDE_DIRECTIONS.NEXT)}
                onKeyUp={(e) =>
                    handleKeyUp({ e, direction: SLIDE_DIRECTIONS.NEXT })
                }
                disabled={isLastPage}
            >
                <ArrowRightIcon size="medium" />
            </Button>
        </div>
    );
};
