import {
    CHARITY_EVENTBRITE_CATEGORY,
    COMMUNITY_EVENTBRITE_CATEGORY,
    FILM_EVENTBRITE_CATEGORY,
    FOOD_AND_DRINK_EVENTBRITE_CATEGORY,
    HEALTH_EVENTBRITE_CATEGORY,
    MUSIC_EVENTBRITE_CATEGORY,
    PERFORMING_EVENTBRITE_CATEGORY,
    SPORTS_EVENTBRITE_CATEGORY,
} from '@eventbrite/categories';

export const ORGANIZER_POPULAR_CATEGORIES = [
    MUSIC_EVENTBRITE_CATEGORY,
    FOOD_AND_DRINK_EVENTBRITE_CATEGORY,
    FILM_EVENTBRITE_CATEGORY,
    SPORTS_EVENTBRITE_CATEGORY,
    HEALTH_EVENTBRITE_CATEGORY,
    CHARITY_EVENTBRITE_CATEGORY,
    PERFORMING_EVENTBRITE_CATEGORY,
    COMMUNITY_EVENTBRITE_CATEGORY,
];
