import { HAS_WINDOW } from '@eventbrite/feature-detection';

function windowHasIdleCallback() {
    return !!window.requestIdleCallback;
}

function windowHasCancelIdleCallback() {
    return !!window.cancelIdleCallback;
}

/**
 * Simple wrapper around the browser provided `requestIdleCallback` that will fallback to
 * `setTimeout` in instances where it is not defined.
 *
 * **NOTE**: Requires `window` to function so only use if window is defined.
 *
 * More information on [MDN Documentation](https://developer.mozilla.org/en-US/docs/Web/API/Window/requestIdleCallback)
 *
 * @param fn Callback to execute
 * @param options IdleRequestOptions
 * @returns
 */
export function requestIdleCallbackWithPolyfill(
    fn: IdleRequestCallback,
    options: IdleRequestOptions = {},
): number | undefined {
    if (HAS_WINDOW) {
        if (!windowHasIdleCallback()) {
            const timeoutId = window.setTimeout(fn, options.timeout || 0);
            return timeoutId;
        }

        const idleCallbackId = window.requestIdleCallback(fn, options);
        return idleCallbackId;
    } else {
        console.warn(
            'Attempted to use requestIdleCallback in an environment where window is not defined.',
        );
    }

    return undefined;
}

export function cancelIdleCallbackWithPolyfill(timeoutId: number): void {
    if (HAS_WINDOW) {
        if (windowHasCancelIdleCallback()) {
            window.cancelIdleCallback(timeoutId);
        } else {
            window.clearTimeout(timeoutId);
        }
    }
}
