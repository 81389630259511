export const SEARCH_LOADING_KEY = 'isSearchLoading';
export const SEARCH_TIMEOUT_KEY = 'isSearchCallTimeout';
export const SET_SEARCH_LOADING = 'SET_SEARCH_LOADING';
export const setSearchLoading = (isLoading: boolean) => ({
    type: SET_SEARCH_LOADING,
    payload: {
        [SEARCH_LOADING_KEY]: isLoading,
    },
});

export const SEARCH_CALL_SUCCESS = 'SEARCH_CALL_SUCCESS';
export const setSearchCallAsSuccess = () => ({
    type: SEARCH_CALL_SUCCESS,
    payload: {
        [SEARCH_LOADING_KEY]: false,
        [SEARCH_TIMEOUT_KEY]: false,
    },
});

export const SEARCH_CALL_ONGOING = 'SEARCH_CALL_ONGOING';
export const setSearchCallAsOngoing = () => ({
    type: SEARCH_CALL_ONGOING,
    payload: {
        [SEARCH_LOADING_KEY]: true,
        [SEARCH_TIMEOUT_KEY]: false,
    },
});

export const SEARCH_CALL_FAILED = 'SEARCH_CALL_FAILED';
export const setSearchCallAsFailed = () => ({
    type: SEARCH_CALL_FAILED,
    payload: {
        [SEARCH_LOADING_KEY]: false,
        [SEARCH_TIMEOUT_KEY]: false,
    },
});

export const SEARCH_CALL_TIMEOUT = 'SEARCH_CALL_TIMEOUT';
export const setSearchCallAsTimeout = () => ({
    type: SEARCH_CALL_TIMEOUT,
    payload: {
        [SEARCH_LOADING_KEY]: false,
        [SEARCH_TIMEOUT_KEY]: true,
    },
});

export const SEARCH_CALLS_WITH_LOADING = [
    SET_SEARCH_LOADING,
    SEARCH_CALL_SUCCESS,
    SEARCH_CALL_ONGOING,
    SEARCH_CALL_FAILED,
    SEARCH_CALL_TIMEOUT,
];
